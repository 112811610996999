import {
    LOAD_PAS_DATA,
    SET_PAS_STATUS,
    PASListAction,
    PASListState
} from './types';

const initialState: PASListState = {
    status: "idle",
    error: undefined,
    data: [],
  };

const partsAndServicesReducer = (state = initialState, action: PASListAction): PASListState => {
    switch (action.type) {
        case  SET_PAS_STATUS:
            return { ...state, status: action.payload.status };
          case LOAD_PAS_DATA:
            return { ...state, data: action.payload.data, status: "idle" };
        default:
            return state;
    }
}

export default partsAndServicesReducer;

import {
  SET_GENERAL_JOB_STATUS,
  LOAD_GENERAL_JOB_DATA,
  GeneralJobState,
  GeneralJobAction
} from "./types";

const initialState: GeneralJobState = {
  status: "idle",
  error: undefined,
  data: {
    "id": "",
    "title" : "",
    "content": "",
    "dateCreated": new Date(),
    "dateUpdated": new Date()
  }
}

const generalJobReducer = (state = initialState, action: GeneralJobAction): GeneralJobState => {
  switch (action.type) {
    case SET_GENERAL_JOB_STATUS:
      return { ...state, status: action.payload.status }
    case LOAD_GENERAL_JOB_DATA:
      return { ...state, data: action.payload.data, status: "idle" }
    default:
      return state;
  }
}

export default generalJobReducer;
import { Stack, Typography, Divider } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import RedLink from "../../components/lib/RedLink";

const ModelNotFound = () => {
  return (
    <>
      <Stack
        sx={{
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: 2, md: 10 },
        }}
      >
        <Stack flex={1} gap={2}>
          <Typography component="h2" fontWeight="600" fontSize={38}>
            Model Not Found!
          </Typography>
          <Divider />
          <RedLink to="/models">
            <ChevronLeft fontSize="small" />
            Go Back
          </RedLink>
        </Stack>
      </Stack>
    </>
  );
};

export default ModelNotFound;

import { Box, Stack } from "@mui/material";
import VisionImage from "../../assets/images/about-us/vision.png";
import { Image, Text, TextHeader } from "./ui";
import SectionContainer from "../../components/layout/SectionContainer";

interface OurVisionProps {
  media: string;
  title: string;
  content: string;
}

function OurVision(props: OurVisionProps) {
  const { media, title, content } = props;

  return (
    <SectionContainer sx={{ py: { xs: 5, md: 7 } }}>
      <Box
        display='grid'
        gap={{ xs: 4, md: 8 }}
        gridTemplateColumns={{ xs: "1fr", md: "repeat(2, 1fr)" }}
      >
        <Stack justifyContent='center' gap={2} order={{ xs: 2, md: 1 }}>
          <TextHeader>
            <span>{title}</span>
          </TextHeader>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Stack>
        <Box order={{ xs: 1, md: 2 }}>
          <Image src={media} alt='Honda Cars Batangas vision' />
        </Box>
      </Box>
    </SectionContainer>
  );
}

export default OurVision;

import { convertToPlainText } from "./convertHtmlToPlainText";
import { filterDataByTitle, filterDataByTitleOrContent } from "./filterData";
import useAllModule from "./useAllModule";

export default function useSearchResults(searchKeyword: string) {
  const { isLoading, careers, news, promo, services, models, variants, testimonies, partsAndAccessories } =
    useAllModule();

  const searchValue = searchKeyword.toLowerCase().trim();

  const careerData = convertToPlainText(careers, "content");
  const newsAndEventsData = convertToPlainText(news, "content");
  const promosData = convertToPlainText(promo, "content");
  const variantData = convertToPlainText(variants, "content");
  const filteredCareers = filterDataByTitleOrContent(careerData, searchValue);
  const filteredNews = filterDataByTitleOrContent(newsAndEventsData, searchValue);
  const filteredServices = filterDataByTitleOrContent(services, searchValue);
  const filteredPromos = filterDataByTitleOrContent(promosData, searchValue);
  const filteredModels = filterDataByTitle(models, searchValue);
  const filteredVariants = filterDataByTitleOrContent(variantData, searchValue);
  const filteredTestimonies =
    testimonies && testimonies.length > 0
      ? filterDataByTitleOrContent(testimonies, searchValue)
      : [];
  const filteredPartsAndAccessories = filterDataByTitleOrContent(partsAndAccessories, searchValue);

  const searchResults = [
    ...filteredCareers,
    ...filteredNews,
    ...filteredPromos,
    ...filteredServices,
    ...filteredModels,
    ...filteredVariants,
    ...filteredTestimonies,
    ...filteredPartsAndAccessories
  ];

  return {
    isLoading,
    searchResults,
  };
}

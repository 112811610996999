import { Typography } from "@mui/material";

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <Typography component='h2' fontWeight='700' fontSize={{ xs: 28, md: 38 }}>
      {title}
    </Typography>
  );
};

export default SectionTitle;

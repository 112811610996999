import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { Status } from "../system/types";    
import {
    IHeader,
    LOAD_HEADER_DATA,
    SET_HEADER_STATUS,
    HeaderAction,
    
} from './types'   
import { AxiosResponse } from "axios";

// Sync Actions
const setHeaderStatus = (status: Status): HeaderAction => ({
    type: SET_HEADER_STATUS,
    payload: { status },
  });
  
  const loadHeaderData = (data: IHeader[]): HeaderAction => ({
    type: LOAD_HEADER_DATA,
    payload: { data },
  });

  export const fetchHeaders = (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(setHeaderStatus("fetching"));
  
      const response:AxiosResponse<any> = await apiClient.request({
        url: `${apiEndpoints.headers}?active=true&limit=3`,
      });

      dispatch(loadHeaderData(response.data.headers));
    } catch (err: any) {
      dispatch(setHeaderStatus("error"));
      errorHandler(err, dispatch);
    }
  };

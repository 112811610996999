import { useDispatch } from "react-redux";
import { setInquiryStatus } from "../../../store/inquiry/actions";
import Stack from "@mui/material/Stack";
import useInquiryForm from "../../../hooks/useInquiryForm";
import SuccessModal from "../../../components/common/SuccessModal";
import ErrorModal from "../../../components/common/ErrorModal";
import WebDialog from "../../../components/common/WebDialog";
import WebInput from "../../../components/common/WebInput";
import WebPrimaryButton from "../../../components/common/WebPrimaryButton";

interface QuickLinkFormProps {
  title: string;
  onClose: () => void;
  open: boolean;
}

const QuickLinkForm = (props: QuickLinkFormProps) => {
  const { onClose, open, title } = props;

  const { register, errors, submitHandler, submitStatus, serverError } = useInquiryForm(title);
  const dispatch = useDispatch();
  const hasInquiryInText = title.toLowerCase().includes("inquiry");

  const handleClose = () => {
    dispatch(setInquiryStatus("idle"));
    onClose();
  };

  const successMessage = `Your inquiry has been submitted!`;

  if (submitStatus === "success") {
    return <SuccessModal open message={successMessage} callback={handleClose} />;
  }

  if (submitStatus === "error") {
    return <ErrorModal open message={serverError} callback={handleClose} />;
  }

  return (
    <WebDialog
      open={open}
      title={title}
      subTitle='Message us for more details'
      onClose={handleClose}
    >
      <Stack component='form' gap={2.2} px={{ xs: 1, md: 3 }} mb={2} onSubmit={submitHandler}>
        <Stack sx={{ flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
          <WebInput
            fullWidth
            placeholder='First Name'
            {...register("firstName")}
            error={!!errors?.firstName}
            helperText={errors?.firstName?.message}
          />
          <WebInput
            fullWidth
            placeholder='Last Name'
            {...register("lastName")}
            error={!!errors?.lastName}
            helperText={errors?.lastName?.message}
          />
        </Stack>
        <WebInput
          type='email'
          placeholder='Email'
          {...register("email")}
          error={!!errors?.email}
          helperText={errors?.email?.message}
        />
        <WebInput
          placeholder='Phone number'
          {...register("phoneNumber")}
          error={!!errors?.phoneNumber}
          helperText={errors?.phoneNumber?.message}
        />
        <WebInput
          multiline
          rows={4}
          placeholder='Comment or Message'
          {...register("comment")}
          error={!!errors?.comment}
          helperText={errors?.comment?.message}
        />
        <WebPrimaryButton type='submit' loading={submitStatus === "submitting"}>
          Submit
        </WebPrimaryButton>
      </Stack>
    </WebDialog>
  );
};

export default QuickLinkForm;

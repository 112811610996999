import type { AppState } from "../index";
import { orderBy } from "lodash";
import moment from "moment"

export const formatPromoDate = (startDate: Date, endDate: Date) => `Valid from ${moment(startDate).format("MMM DD")} to ${moment(endDate).format("MMM DD yyyy ")}`

export const selectPromoState = (state: AppState) => state.promo;

export const selectPromoData = (state: AppState) => {
  const sortBy = state.promo.sortBy.split(":");

  const [property, order] = sortBy;

  return orderBy(state.promo.data, [property], [order as "asc" | "desc"]);
};

export const selectPromoById = (id: string) => (state: AppState) =>
  state.promo.data.find((i) => i.id === id);

export const selectLatestPromo = (state: AppState) => {
  const orderedPromos = orderBy(state.promo.data, ["startDate"], ["desc"]) 

  return orderedPromos[0]
}

export const selectPromosForSearch = (state: AppState) => 
  state.promo.allData.map((i) => { 
    return  { 
      id: i.id, 
      module: "Promos", 
      title: i.title,
      content: i.content,
      link: `/promos/${i.id}` 
    }
  });
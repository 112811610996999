import { Stack } from "@mui/material";
import SectionContainer from "../../../components/layout/SectionContainer";
import { appColors } from "../../../theme";
import TestimonialsCarousel from "../testimonials/TestimonialCarousel";

import TestimonialFormBanner from "./TestimonialFormBanner";

const TestimonialSection = () => {
  return (
    <Stack
      mb='300px'
      // minHeight='65vh' // can be changed
      position='relative'
    >
      <SectionContainer bg={appColors.softGray}>
        <TestimonialsCarousel />
      </SectionContainer>
      <TestimonialFormBanner />
    </Stack>
  );
};

export default TestimonialSection;

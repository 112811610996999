import { Stack, Typography } from "@mui/material";
import { ModelColorSelection } from "../../../../store/models/types";
import ModelColorButton from "./ModelColorButton";

interface ModelColorSelectProps {
  modelColors: ModelColorSelection[];
  currentColorIndex: number;
  onChange: (colorIndex: number) => void;
}

const ModelColorSelect = ({ modelColors, currentColorIndex, onChange }: ModelColorSelectProps) => {
  return (
    <Stack alignItems='center' gap={2}>
      <Typography fontSize={{ xs: 18, md: 24 }} fontWeight='600' textAlign='center'>
        Choose color
      </Typography>
      <Stack direction='row' alignItems='center' gap={2}>
        {modelColors.map((color, colorIdx) => (
          <ModelColorButton
            key={color.colorName}
            bgUrl={color.colorPreviewUrl}
            onClick={() => onChange(colorIdx)}
            isActive={colorIdx === currentColorIndex}
          />
        ))}
      </Stack>
      <Typography fontSize={{ xs: 18, md: 24 }} fontWeight='600' textAlign='center'>
        {modelColors[currentColorIndex].colorName}
      </Typography>
    </Stack>
  );
};

export default ModelColorSelect;

import {
  LOAD_VIRTUAL_SHOWROOM_DATA,
  SET_VIRTUAL_SHOWROOM_STATUS,
  VirtualShowroomAction,
  VirtualShowroomState,
} from "./types";

const initialState: VirtualShowroomState = {
  status: "idle",
  error: undefined,
  data: [],
};

const virtualShowroomReducer = (
  state = initialState,
  action: VirtualShowroomAction
): VirtualShowroomState => {
  switch (action.type) {
    case SET_VIRTUAL_SHOWROOM_STATUS:
      return { ...state, status: action.payload.status };
    case LOAD_VIRTUAL_SHOWROOM_DATA:
      return { ...state, data: action.payload.data, status: "idle" };
    default:
      return state;
  }
};

export default virtualShowroomReducer;

import { SxProps, styled } from "@mui/system";
import { appColors } from "../../theme/index";
import Stack from "@mui/material/Stack";

export const BannerContainer = styled(Stack)<{ image: string }>(({ image, theme }) => ({
  backgroundImage: `url("${image}")`, 
  backgroundRepeat: 'no-repeat', 
  backgroundPosition: 'center',
  backgroundSize: 'cover'
}));

export const taglineContainerStyle: SxProps = {
  height: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: {
    xs: "0px 30px",
    sm: "0px 60px",
    md: "0px 150px"
  }
};

export const taglineStyle: SxProps = {
  color: appColors.white, 
  fontWeight: "900", 
  fontSize: { xs: "27px", sm: "40px" }, 
  textAlign: "center"
};
import { Helmet } from 'react-helmet';

interface MetaProps {
  title: string;
  description?: string;
  dontAppendTitle?: boolean;
}

const Meta = ({
  title = '',
  description = 'The Official Website Honda Cars Batangas',
  dontAppendTitle = false,
}: MetaProps) => {
  const titleStr = dontAppendTitle ? title : `Honda Cars Batangas - ${title}`;
  return (
    <Helmet>
      <title>{titleStr}</title>
      <meta name='description' content={description} />
    </Helmet>
  );
};

export default Meta;

import { Stack } from "@mui/material";
import { SxProps, styled } from "@mui/system";
import { appColors } from "../../../theme/index";

export const NewsAndEventsCarouselContainerStyle: SxProps = {
  maxWidth: { xs: "100%", sm: "100%" },
  height: "inherit",
  display: "flex",
  position: "relative",
  "& .carousel-root": {
    width: "100%",
  },
  "& .carousel-root > .carousel": {
    overflow: "unset",
    height: "100%",
    padding: { xs: "0px 0px", md: "0px 45px" },
  },
  "& .newsAndEvents-carousel": {
    width: "100%",
  },
  "& .slider.animated": {
    transform: `translate3d(0%, 0px, 0px)`,
  },
  "& .control-dots": {
    bottom: -50,
    transform: { md: `translateX(-40px)` },
  },
};

export const NewsEventBox = styled(Stack)<{ count: number }>(
  ({ count, theme }) => ({
    ".carousel , .slider": {
      justifyContent: `${count < 2 ? "center" : "flex-start"} `,
    },
  })
);

export const newsAndEventsButtonStyles: SxProps = {
  border: "1.5px solid",
  borderColor: appColors.primary,
  background: "white",
  textTransform: "capitalize",
  borderRadius: "0px",
  width: { xs: "25vh", md: "50vh" },
  padding: "10px",
  ":hover": {
    backgroundColor: appColors.primary,
    color: appColors.white,
  },
};

export const NewsAndEventsContainer: SxProps = {
  width: "auto",
  margin: "20px",
  transition: "transform 200ms ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
    cursor: "pointer",
  },
  "&:hover > .view-newsAndEvents": {
    animation: "fadeIn 15s",
    color: appColors.primary,
  },
  ".view-newsAndEvents": {
    color: "transparent",
  },
};

export const imageContainer: SxProps = {
  boxSizing: "border-box",
  position: "relative",
  "& img": {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
};

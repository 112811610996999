import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { selectModelData, selectModelVariants } from '../../../store/models/selectors';
import { setModelVariants } from '../../../store/models/actions';

import { Stack, useTheme, useMediaQuery } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { NextButton, PrevButton, CarouselIndicator } from './ui';
import { ModelCarouselContainerStyle } from './ModelStyle';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import ModelItem from './ModelItem';
import modelSchema from '../../model/compare-models/fragments/modelSchema.json';
import SectionContainer from '../../../components/layout/SectionContainer';
import WebOutlinedButton from '../../../components/common/WebOutlinedButton';

const ModelCarousel = () => {
  const models = useSelector(selectModelData);
  const compareModelsData = useSelector(selectModelVariants);
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const viewAllModels = () => history.push('/models');
  const compareModels = () => {
    history.push('/models/compare-models');

    compareModelsData.selectedModels = [modelSchema, modelSchema, modelSchema];
    compareModelsData.selectedModelIndex = -1;
    compareModelsData.selectedVariants = [
      {
        name: '',
        performance: [],
        summary: { content: '', price: '' },
        specs: [{ category: '', items: [] }],
      },
    ];

    dispatch(setModelVariants({ ...compareModelsData }));
  };

  return (
    <Stack
      sx={{
        ...ModelCarouselContainerStyle,
        '& .carousel-root > .carousel': {
          padding: { lg: '0 45px' },
        },
        '& .control-dots': {
          transform: { xs: '', lg: 'translateX(-48px)' },
          bottom: -15,
        },
      }}
    >
      <SectionContainer>
        <Stack className='models-carousel' gap={5}>
          <Carousel
            showIndicators={models ? (models.length > 1 ? true : false) : false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={true}
            centerMode={3 > 1 && !isMobile}
            centerSlidePercentage={100 / 3}
            renderArrowNext={(onClick, hasNext, label) => (
              <NextButton onClick={onClick} hasNext={hasNext} label={label} />
            )}
            renderArrowPrev={(onClick, hasPrev, label) => (
              <PrevButton onClick={onClick} hasPrev={hasPrev} label={label} />
            )}
            renderIndicator={(clickHandler, isSelected, index, label) => (
              <CarouselIndicator
                clickHandler={clickHandler}
                isSelected={isSelected}
                index={index}
                label={label}
              />
            )}
          >
            {models.map((m, index) => {
              return (
                <Link key={m.id} to={`/models/${m.id}`} style={{ textDecoration: 'none' }}>
                  <ModelItem index={index} models={m} />
                </Link>
              );
            })}
          </Carousel>
          <Stack
            gap={2}
            justifyContent='center'
            sx={{
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
            }}
          >
            <WebOutlinedButton
              bg='white'
              startIcon={<DirectionsCarFilledOutlinedIcon />}
              onClick={viewAllModels}
            >
              View All Models
            </WebOutlinedButton>
            <WebOutlinedButton
              bg='white'
              startIcon={<LoopOutlinedIcon sx={{ transform: 'rotate(-45deg)' }} />}
              onClick={compareModels}
            >
              Compare Models
            </WebOutlinedButton>
          </Stack>
        </Stack>
      </SectionContainer>
    </Stack>
  );
};

export default ModelCarousel;

import { Button, ButtonProps, CircularProgress } from "@mui/material";

const WebPrimaryButton = ({ loading, ...btnProps }: ButtonProps & { loading?: boolean }) => {
  return (
    <Button
      {...btnProps}
      disableElevation
      variant='contained'
      sx={{
        backgroundColor: (theme) => theme.palette.primary.main,
        color: "white",
        padding: { xs: "12px 32px", md: "12px 32px" },
        fontSize: 15,
        minWidth: 220,
        width: { xs: "100%", md: "unset" },
        cursor: "pointer",
        borderRadius: 0,
        textTransform: "capitalize",
        ...btnProps.sx,

        "&:disabled": {
          backgroundColor: "#ccc",
          borderColor: "#ccc",
          color: "white",
        },
      }}
    >
      {loading ? <CircularProgress size={24} sx={{ color: "white" }} /> : btnProps.children}
    </Button>
  );
};

export default WebPrimaryButton;

import { VariantStackStyle } from './ModelVariantStyle';
import { Stack, Box,Typography  } from '@mui/material';
import { VariantBoxStyle } from './ModelVariantStyle';
import { ModelVariant } from '../../../../store/models/types';

interface VariantProps {
    variantData: ModelVariant[]
};

const Variants = ({variantData}:VariantProps) => {
  return (
    <Stack sx={VariantStackStyle} gap={4}>
        {variantData.map((item, index) => (
          <Box sx={VariantBoxStyle}  key={index} >
          <Typography fontSize="31px" fontWeight={400} pb={1} >{item.name}</Typography>
          <Typography fontWeight="bold" pb={2} >{Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "PHP",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(
            Number(!!Number(item.summary.price) ? item.summary.price : 0)
            ).replace("₱", "PHP ")}
          </Typography>             
          <Typography dangerouslySetInnerHTML={{ __html: item.summary.content }}/>
        </Box>
        ))}
    </Stack>
  )
};

export default Variants;
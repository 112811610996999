import { Stack } from '@mui/material';
import { useState } from 'react';
import useShowroomModelColor from './utils/useShowroomModelColor';
import VSColorSelect from './VSColorSelect';
import VSContainer from './VSContainer';
import VSExteriorView from './VSExteriorView';
import VSInteriorView from './VSInteriorView';
import VSNotFound from './VSNotFound';
import VSPageTitle from './VSPageTitle';
import VSTabs from './VSTabs';
import VSVariantCard from './VSVariantCard';

const ShowroomView = () => {
  // for showing the variant card
  const [variantCardShown, setVariantCardShown] = useState(false);
  // for switching views
  const [view, setView] = useState<'exterior' | 'interior'>('exterior');

  const showVariantCard = () => setVariantCardShown(true);
  const hideVariantCard = () => setVariantCardShown(false);

  const { currentShowroom, modelColors, currentColorIndex, onColorIndexChange } =
    useShowroomModelColor();

  if (!currentShowroom) return <VSNotFound />;

  return (
    <VSContainer>
      {view === 'exterior' && (
        <Stack py={4}>
          <VSPageTitle onViewDetailsClick={showVariantCard} />
          <VSVariantCard show={variantCardShown} onClose={hideVariantCard} />
          <VSExteriorView translated={variantCardShown} showroom={currentShowroom} />
          <VSColorSelect
            hidden={variantCardShown}
            modelColors={modelColors}
            currentColorIndex={currentColorIndex}
            onChange={onColorIndexChange}
          />
        </Stack>
      )}
      {view === 'interior' && <VSInteriorView showroom={currentShowroom} />}
      <VSTabs show={!variantCardShown} currentView={view} onChange={setView} />
    </VSContainer>
  );
};

export default ShowroomView;

export const personalInfo = [
  "Contact information, such as your name, company name, job title, address, e-mail address, and phone number;",
  "Additional information about you to help us get to know you better, such as your gender, age, date of birth, nationality, professional associations and registration numbers, information about how you use our products, and demographic information;",
  "Comments, questions, requests and orders you may make; - financial information needed to process payments if you make purchases, such as credit card or account information or tax identification number;",
  "Log-in information, including, if applicable, social media account information for login purposes;",
  "And information about your preferences, such as your preferred methods of communication and product types in which you are interested.",
  "Video footage is also being recorded via a CCTV system installed in all HCBT areas.",
];

export const websiteCookies = [
  "We collects, uses or otherwise processes your personal information through, but not limited to the following:",
  "Information that you provide, or we collect when you visit our website. We may also collect information from you to answer your queries, provide you with requested information, and/or to assist you",
  "Information we collect from representatives of clients, suppliers and other third parties including, but not limited to, basic personal details such as clients/suppliers name, address and contact information, clients/suppliers representatives full name, email address, mobile or phone number, financial details",
  "Information you provide or we collect when you apply for a certain job or position within the company.",
];

export const infoHCBT = [
  "This company collects basic contact information from clients and customers, such as their full name, address, email address, and contact number, as well as the products they wish to purchase. Such information will be gathered by the sales representative, serving the customer via completed order forms.",
  "Personal data collected will be used by the company for documentation, warranty tracking in relation to purchased items, and product inventory.",
  "This company will protect personal data in its possession from accidental or unlawful destruction, alteration, or disclosure, as well as from any other unlawful processing. Depending on the nature of the information, the company will implement appropriate security measures in storing it.",
  "Due to the sensitive and confidential nature of the personal data in the company's custody, only the client and the company's authorized representative shall be permitted to access such personal data for any purpose other than those contrary to law, public policy, public order, or morals.",
  "All company employees and personnel must maintain the confidentiality and secrecy of all personal data that comes into their knowledge and possession, even after resignation, contract termination, or other contractual relations. Personal data under the company's custody shall be disclosed only for lawful purposes and to authorized recipients of such data.",
  "Data subjects have the right to inquire or request information about any aspect of the organization's processing of their personal data, including the data privacy and security policies in place to protect their personal data.",
  "Complaints must be submitted in three (3) printed copies or via email to sherwin.torino@hondacarsbatangas.com. The concerned department or unit must confirm receipt of the complaint with the complainant.",
];

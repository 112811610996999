import { Stack,Typography,Divider } from '@mui/material'
import { ChevronLeft } from "@mui/icons-material";
import RedLink from "../../components/lib/RedLink";

const CareerNotFound = () => {
  return (
    <>
    <Stack
        sx={{
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: 2, md: 10 },
        }}
      >
        <Stack flex={1} gap={2}>
          <Typography component="h2" fontWeight="600" fontSize={38}>
            Career Not Found!
          </Typography>
          <Divider />
            <RedLink to="/careers">
              <ChevronLeft fontSize="small" />
              Go Back
            </RedLink>
          </Stack>
      </Stack>
    </>
  )
}

export default CareerNotFound;
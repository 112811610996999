import { VirtualShowroom } from '../../../store/virtual-showroom/types';
import { VSInteriorContainer } from './VSUi';
import VSInfospotCard from './VSInfospotCard';
import usePanoramaViewer from './utils/usePanoramaViewer';
import Meta from '../../../components/common/Meta';

interface VSInteriorViewProps {
  showroom: VirtualShowroom;
}

const VSInteriorView = ({ showroom }: VSInteriorViewProps) => {
  const panoContainer = usePanoramaViewer(showroom);

  return (
    <>
      <Meta title={`${showroom.name} | Interior`} />
      {showroom?.infospots.map((infospot, idx) => (
        <VSInfospotCard key={idx} infospot={infospot} />
      ))}
      <VSInteriorContainer>
        <div
          key={showroom.id}
          ref={panoContainer}
          id='pano-container'
          style={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }}
        />
      </VSInteriorContainer>
    </>
  );
};

export default VSInteriorView;

import { AppError, IFeatureState, Status } from "../system/types";

export type ICarPricelist = {
  id: string;
  pricelistLinkUrl: string;
  pricelistFileName: string;
}

export type CarPricelistState = IFeatureState<ICarPricelist>;

export const SET_PRICELIST_STATUS = "pricelist/status/set";
export const SET_PRICELIST_ERROR = "pricelist/error/set";
export const LOAD_PRICELIST_DATA = "pricelist/data/load";
export const FETCH_CAREER_DATA = "pricelist/data/fetch";

interface SetCarPricelistStatusAction {
  type: typeof SET_PRICELIST_STATUS;
  payload: { status: Status };
}

interface SetCarPricelistErrorAction {
  type: typeof SET_PRICELIST_ERROR;
  payload: { status: AppError };
}

interface SetCarPricelistDataAction {
  type: typeof LOAD_PRICELIST_DATA;
  payload: { data: CarPricelistState["data"] };
}

interface FetchCarPricelistAction {
  type: typeof FETCH_CAREER_DATA;
  payload: { id: string; data: ICarPricelist };
}

export type CarPricelistAction = 
  | SetCarPricelistStatusAction
  | SetCarPricelistErrorAction
  | SetCarPricelistDataAction
  | FetchCarPricelistAction
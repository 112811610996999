import type { AppError, IFeatureState, Status } from "../system/types";

export interface IAboutUs {
  id: string;
  media: string;
  header: string;
  content: string;
  mission: string;
  missionMedia: string;
  missionContent: string;
  vision: string;
  visionMedia: string;
  visionContent: string;
  coreValueTitle: string;
  coreValue: CoreValue[];
  dateCreated: Date;
  dateUpdated: Date;
}

export interface CoreValue {
  label: string;
  media: string;
}

export type AboutUsState = IFeatureState<IAboutUs>;

export const SET_ABOUT_US_STATUS = "about_us/status/set";
export const SET_ABOUT_US_ERROR = "about_us/error/set";
export const LOAD_ABOUT_US_DATA = "about_us/data/load";
export const FETCH_ABOT_US_DATA = "about-us/data/fetch";

interface SetAboutUsStatusAction {
  type: typeof SET_ABOUT_US_STATUS;
  payload: { status: Status };
}

interface SetAboutUsErrorAction {
  type: typeof SET_ABOUT_US_ERROR;
  payload: { error: AppError };
}

interface SetAboutUsDataAction {
  type: typeof LOAD_ABOUT_US_DATA,
  payload: { data: AboutUsState["data"] }
}

interface FetchAboutUsAction {
  type: typeof FETCH_ABOT_US_DATA;
  payload: { id: string; data: IAboutUs };
}

export type AboutUsAction =
  | SetAboutUsStatusAction
  | SetAboutUsErrorAction
  | SetAboutUsDataAction
  | FetchAboutUsAction;
import {
  SET_PRICELIST_STATUS,
  LOAD_PRICELIST_DATA,
  CarPricelistState,
  CarPricelistAction
} from './types';

const initialState: CarPricelistState = {
    status: "idle",
    error: undefined,
    data: [],
  };

const pricelistReducer = (state = initialState, action: CarPricelistAction): CarPricelistState => {
  switch (action.type) {
    case  SET_PRICELIST_STATUS:
      return { ...state, status: action.payload.status };
    case LOAD_PRICELIST_DATA:
      return { ...state, data: action.payload.data, status: "idle" };
    default:
      return state;
  }
};

export default pricelistReducer;
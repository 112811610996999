import { styled } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';
import { FaImage } from 'react-icons/fa';
import { RenderIf } from './BasicUiLib';

interface ImagePreviewContainerProps {
  width?: number | string;
  height?: number;
  bordered?: boolean;
  bgDark?: boolean;
  objectFit?: string;
}

const ImagePreviewContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'bordered' && prop !== 'width' && prop !== 'height' && prop !== 'bgDark' && prop !== 'objectFit',
})<ImagePreviewContainerProps>(({ width = '50%', height = 150, bordered, bgDark, objectFit }) => ({
  width,
  height,
  border: bordered ? '1px solid #E1E1E1' : 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: bgDark ? '#000' : 'unset',
  padding: bgDark ? '0 8px' : 'unset',

  '& > .preview-img': {
    height: '100%',
    width: '100%',
    objectFit: objectFit ? objectFit : 'cover',
    backfaceVisibility: 'hidden',
    transform: 'translateZ(0)',
  },

  '& > svg': {
    fontSize: height * 0.8,
    color: '#e1e1e1',
  },
}));

interface ImagePreviewProps extends ImagePreviewContainerProps {
  src?: string | null;
  alt?: string;
  initialSrc?: string;
  hideLabel?: boolean;
  label?: string;
  objectFit?: string;
}

export default function ImagePreview({
  src,
  alt,
  initialSrc,
  width,
  height,
  hideLabel = false,
  label = 'Image Preview',
  bgDark,
  objectFit
}: ImagePreviewProps) {
  return (
    <Stack>
      <RenderIf condition={hideLabel === false}>
        <Typography color='primary' component='label' fontSize={14} htmlFor='email' mb='3px'>
          {label}
        </Typography>
      </RenderIf>
      <RenderIf condition={!src && !initialSrc}>
        <ImagePreviewContainer 
          width={width} 
          height={height} 
          bordered 
          objectFit={objectFit}
        >
          <FaImage aria-label='media preview' />
        </ImagePreviewContainer>
      </RenderIf>
      <RenderIf condition={Boolean(src || initialSrc)}>
        <ImagePreviewContainer width={width} height={height} bgDark={bgDark} objectFit={objectFit}>
          <img src={src ? src : initialSrc} alt={alt} className='preview-img' />
        </ImagePreviewContainer>
      </RenderIf>
    </Stack>
  );
}

import {
    HeaderAction,
    HeaderState,
    SET_HEADER_STATUS,
    LOAD_HEADER_DATA,
  } from "./types";

  const initialState: HeaderState = {
    status: "idle",
    error: undefined,
    data: [],
  };

  const headerReducer = (state = initialState, action: HeaderAction): HeaderState => {
    switch (action.type) {
      case SET_HEADER_STATUS:
        return { ...state, status: action.payload.status };
      case LOAD_HEADER_DATA:
        return { ...state, data: action.payload.data, status: "idle" };
      default:
        return state;
    }
  };
  export default headerReducer;
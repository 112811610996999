import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectModelVariants } from "../../../../store/models/selectors";
import { setModelVariants } from "../../../../store/models/actions";

import { AddCircle as AddCircleIcon, Remove } from '@mui/icons-material';
import { Stack, Typography } from "@mui/material";
import { CompareButton, CompareChips, CustomAvatar } from "./CompareModelComponents";
import { convertToPhp } from "../../../../utils/convert-currency";
import { appColors } from "../../../../theme";
import modelSchema from "./modelSchema.json";

const ModelSelectionContent = ({ selectedIndex }: { selectedIndex: number }) => {
  const models = useSelector(selectModelVariants);
  const dispatch = useDispatch();
  const history = useHistory();

  const isHidden = (index: number) => !Boolean(models.selectedModels[index].id)

  const handleSelection = (index: number) => {
    models.selectedModelIndex = index
    history.push('/models/compare-models/select-model-and-variant');
  }

  const removeSelectedModel = (index: number) => {
    models.selectedModels[index] = modelSchema
    models.selectedModelIndex -= 1
    dispatch(setModelVariants({ ...models, selectedModelIndex: models.selectedModelIndex }))
  }

  return (
    <>
      <CompareChips hide={isHidden(selectedIndex)} onClick={() => removeSelectedModel(selectedIndex)}>
        Remove 
        <Remove sx={{ marginLeft: 1 }} />
      </CompareChips>
      <CustomAvatar src={models.selectedModels[selectedIndex]?.imagePreviewUrl} hide={isHidden(selectedIndex)} variant='square' />
      <Stack gap={0} alignItems='center' sx={{ display: isHidden(selectedIndex) ? 'none' : 'flex' }}>
        <Typography color='primary' mb={0} width='25ch' textAlign='center' noWrap>
          {models.selectedModels[selectedIndex]?.type}
        </Typography>
        <Typography color={appColors.darkestGray} fontWeight={600} width='25ch' textAlign='center' noWrap>
          {models.selectedModels[selectedIndex]?.variant[0]?.name}
        </Typography>
        <Typography color={appColors.darkestGray} fontWeight={300}>
          {convertToPhp(models.selectedModels[selectedIndex]?.variant[0]?.summary.price)}
        </Typography>
      </Stack>
      <CompareButton 
        hide={!isHidden(selectedIndex)} 
        variant="outlined" 
        endIcon={<AddCircleIcon />}
        disabled={!!models.selectedModels[selectedIndex].id}
        onClick={() => handleSelection(selectedIndex)}
      >
        Select Model
      </CompareButton>
    </>
  )
}

export default ModelSelectionContent;
import { WebFilterTabItem, WebFilterTabs } from "../../../components/common/WebFilterTab";

interface ChooseCarFilterTabsProps {
  currentType: "All" | "Sedan/Hatchback" | "SUV";
  onChange: (view: "All" | "Sedan/Hatchback" | "SUV") => void;
}

const ChooseCarFilterTabs = ({ currentType, onChange }: ChooseCarFilterTabsProps) => {
  return (
    <WebFilterTabs
      sx={{
        position: "absolute",
        top: { xs: 90, md: 0 },
        right: { xs: "50%", md: 20 },
        transform: { xs: "translateX(50%)", md: "unset" },
        "& > button": { bgcolor: "transparent", color: "#333" },
      }}
    >
      <WebFilterTabItem
        className={currentType === "All" ? "active" : ""}
        onClick={() => onChange("All")}
      >
        All
      </WebFilterTabItem>
      <WebFilterTabItem
        className={currentType === "Sedan/Hatchback" ? "active" : ""}
        onClick={() => onChange("Sedan/Hatchback")}
      >
        Sedan/Hatchback
      </WebFilterTabItem>
      <WebFilterTabItem
        className={currentType === "SUV" ? "active" : ""}
        onClick={() => onChange("SUV")}
      >
        SUV
      </WebFilterTabItem>
    </WebFilterTabs>
  );
};

export default ChooseCarFilterTabs;

import { styled, keyframes } from "@mui/system";

const stroke = keyframes`
    100% {
        stroke-dashoffset: 0
    }
`;

const scale = keyframes`
    0%, 100% {
        transform: none
    }
    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
`;

const fill = (color: string) => keyframes`
    100% {
        box-shadow: inset 0px 0px 0px 30px ${color}
    }
`;
const SuccessMarkWrapper = styled("div")(({ theme }) => ({
  height: "64px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& > .checkmark": {
    width: "56px",
    height: "56px",
    borderRadius: "50%",
    display: "block",
    strokeWidth: 2,
    stroke: "#fff",
    strokeMiterlimit: 10,
    margin: "10% auto",
    boxShadow: `inset 0px 0px 0px ${theme.palette.primary.main}`,
    animation: `${fill(
      theme.palette.primary.main
    )} .4s ease-in-out .4s forwards, ${scale} .3s ease-in-out .9s both`,

    "& > .checkmark__circle": {
      strokeDasharray: 166,
      strokeDashoffset: 166,
      strokeWidth: 2,
      strokeMiterlimit: 10,
      stroke: theme.palette.primary.main,
      fill: "none",
      animation: `${stroke} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards`,
    },

    "& > .checkmark__check": {
      transformOrigin: "50% 50%",
      strokeDasharray: 48,
      strokeDashoffset: 48,
      animation: `${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards`,
    },
  },
}));

const SuccessMark = () => {
  return (
    <SuccessMarkWrapper>
      <svg className='checkmark' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
        <circle className='checkmark__circle' cx='26' cy='26' r='25' fill='none' />{" "}
        <path className='checkmark__check' fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
      </svg>
    </SuccessMarkWrapper>
  );
};

export default SuccessMark;

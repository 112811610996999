import { Switch, Route } from "react-router-dom";
import ModelCarousel from "./ModelCarousel";
import ModelDetails from "../../model/ModelDetails";

const ModelSection = () => {
  return (
    <Switch>
      <Route path='/models/:id'>
        <ModelDetails />
      </Route>
      <Route path='/'>
        <ModelCarousel />
      </Route>
    </Switch>
  );
};

export default ModelSection;

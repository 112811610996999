import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { Status } from "../system/types";
import {
  INewsAndEventsBanner,
  LOAD_NEWS_AND_EVENTS_BANNER_DATA,
  SET_NEWS_AND_EVENTS_BANNER_STATUS,
  NewsAndEventsBannerAction
} from "./types";

// Sync Actions
const setNewsAndEventsBannerStatus = (status: Status): NewsAndEventsBannerAction => ({
  type: SET_NEWS_AND_EVENTS_BANNER_STATUS,
  payload: { status }
});

const loadNewsAndEventsBannerData = (data: INewsAndEventsBanner[]): NewsAndEventsBannerAction => ({
  type: LOAD_NEWS_AND_EVENTS_BANNER_DATA,
  payload: { data }
});

export const fetchNewsAndEventsBanner = (): AppThunk => async (dispatch, getState) => {
  
  try {
    dispatch(setNewsAndEventsBannerStatus("fetching"));

    const response = await apiClient.request<{ banner: INewsAndEventsBanner }>({
      url: `${apiEndpoints.newsAndEvents}/banner/NEWSANDEVENTS::BANNER::1`,
    });

    dispatch(loadNewsAndEventsBannerData([response.data.banner]));
  } catch (err: any) {
    dispatch(setNewsAndEventsBannerStatus("error"));
    errorHandler(err, dispatch);
  }
};
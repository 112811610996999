import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getIsAuthenticated, getIsAuthLoading } from "../../store/auth/selectors";
import { loginCustomer } from "../../store/auth/actions";

import { Stack, Box, Typography, TextField, FormControlLabel } from "@mui/material";
import { SxProps } from "@mui/system";
import CheckBox from "@mui/material/Checkbox";
import { appColors } from "../../theme";
import ProceedButton from "../../components/lib/ProceedButton";
import EliteModal from "./EliteModal";
import background from "../../assets/images/HCBT-Banner.png";
import loginLogo from "../../assets/images/login-logo.png";

const inputStyles: SxProps = {
  border: "none",
  "& .MuiOutlinedInput-root": {
    bgcolor: appColors.softGray,
  },
  "& .MuiOutlinedInput-root > fieldset": {
    borderColor: "white",
  },
  "&:focus": { borderColor: "#ccc" },
};

type Inputs = {
  password: string;
  username: string;
  rememberMe: boolean;
};

const validationSchema = yup.object({
  username: yup.string().required("This field is required."),
  password: yup.string().required("This field is required"),
});

const EliteLogin = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isLoading = useSelector(getIsAuthLoading);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
  } = useForm<Inputs>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: "",
      username: localStorage.getItem("username") ? `${localStorage.getItem("username")}` : "",
      rememberMe: localStorage.getItem("rememberMe") === "true" ? true : false,
    },
  });

  const dispatch = useDispatch();
  const hasError = (inputName: keyof Inputs) => (errors[inputName] ? true : false);
  const getError = (inputName: keyof Inputs) => errors[inputName]?.message;

  const onSubmit = (values: Inputs) => {
    localStorage.setItem("rememberMe", values.rememberMe ? "true" : "false");
    localStorage.setItem("username", values.rememberMe ? values.username : "");

    dispatch(loginCustomer(values.username, values.password));
  };

  const onError = (err: typeof errors) => console.log(err);

  if (isAuthenticated) return <Redirect to='/hcbt-elite/card' />;

  if (isLoading) return <EliteModal open={true} />;

  return (
    <Stack sx={{ flexDirection: { xs: "column-reverse", md: "row" } }} minHeight='100vh'>
      <Stack
        component='form'
        onSubmit={handleSubmit(onSubmit, onError)}
        sx={{
          width: { xs: "100%", md: "48%" },
          padding: { xs: "30px", md: "50px 100px" },
        }}
        gap={12}
      >
        <Link to='/home' aria-label='home'>
          <img src={loginLogo} alt='Honda Batangas' height={25} style={{ marginTop: "14px" }} />
        </Link>
        <Stack gap={3}>
          <Box>
            <Typography fontSize={40} fontWeight={700}>
              Login
            </Typography>
            <Typography fontSize={18} fontWeight={300}>
              Welcome back! please enter your details to access and view your points
            </Typography>
          </Box>
          <Stack gap={3}>
            <TextField
              fullWidth
              aria-label='Username'
              size='small'
              sx={inputStyles}
              placeholder='Username'
              error={hasError("username")}
              helperText={getError("username")}
              {...register("username")}
            />
            <TextField
              type='password'
              fullWidth
              aria-label='Password'
              size='small'
              sx={inputStyles}
              placeholder='Password'
              error={hasError("password")}
              helperText={getError("password")}
              {...register("password")}
            />
            <ProceedButton type='submit' disabled={!isValid || isSubmitting} />
          </Stack>
          <Stack direction='row' justifyContent='start'>
            <Controller
              name='rememberMe'
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value, name, ref } }) => (
                <FormControlLabel
                  sx={{ width: "max-content", alignSelf: "center" }}
                  control={
                    <CheckBox
                      checked={value}
                      onChange={onChange}
                      ref={ref}
                      sx={{
                        "&.Mui-checked": { color: (theme) => theme.palette.primary.main },
                      }}
                    />
                  }
                  label='Remember Me'
                  labelPlacement='end'
                />
              )}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack
        sx={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          width: { xs: "100%", sm: "100%", md: "52%" },
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          minHeight: { xs: "40vh", sm: "60vh", md: "100vh" },
        }}
      />
    </Stack>
  );
};

export default EliteLogin;

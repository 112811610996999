import Stack from "@mui/material/Stack";
import InquiryForm from "./InquiryForm";

const PartsAndAccessories = () => {
  return (
    <>
      <Stack flex={1} width={{ xs: "100%", md: "50%" }}>
        <InquiryForm />
      </Stack>
    </>
  );
};

export default PartsAndAccessories;

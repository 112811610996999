import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { appColors } from "../../theme";
import 
  CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import HondaIcon from "../../assets/images/honda-icon.png";

interface SuccessModalProps {
  open: boolean;
}

const HondaCircularProgress = (props: CircularProgressProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
          variant="determinate"
          sx={{
              color: "#F2DCDC"
          }}
          size={100}
          thickness={4}
          {...props}
          value={100}
          />
          <Box
              sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              }}
          >
              <img src={HondaIcon} width="25" alt=""/>
          </Box>
      </Box>
      <CircularProgress
        variant="indeterminate"
        sx={{
          color: appColors.primary,
          animationDuration: '2000ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={100}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const EliteModal = ({ open }: SuccessModalProps) => {
  return (
    <Dialog open={open} fullWidth maxWidth='md'>
      <DialogContent sx={{ height: "70vh", display: "flex", flexDirection: "column", justifyContent:"center" }} >
        <Stack my={6} gap={4} alignItems='center'>
          <HondaCircularProgress />
          <Typography fontSize={26} fontWeight={600} textAlign="center">
            Redirecting you to HCBT Elite Card Page
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default EliteModal;
import { Typography } from "@mui/material";
import { appColors } from "../../theme";

const PromoSubHeader = () => {
  return (
    <Typography fontSize={14} ml={0.2} color={appColors.grey}>
      Find out more about Honda Batangas monthly offers and promotions.
    </Typography>
  );
};

export default PromoSubHeader;

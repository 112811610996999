import SectionContainer from "../../../../components/layout/SectionContainer";
import useCurrentModel from "../../utils/useCurrentModel";
import Banner from "./Banner";
const ModelBannerSection = () => {
  const model = useCurrentModel();

  if(!model) return null;
  
  return (
    <SectionContainer id='model-banner'>
      <Banner model={model} />
    </SectionContainer>
  );
};

export default ModelBannerSection;
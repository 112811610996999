import {
    AuthAction,
    AuthState,
    CALL_AUTH,
    CALL_AUTH_FAILED,
    CALL_AUTH_SUCCESS,
    LOG_OUT_CUSTOMER,
    SET_AUTH_CUSTOMER,
  } from "./types";
  
  const INITIAL_STATE: AuthState = {
    authCustomer: null,
    isAuthenticated: false,
    status: "idle",
    error: null,
  };
  
const authReducers = ( state = INITIAL_STATE, action: AuthAction): AuthState => {
  switch (action.type) {
    case CALL_AUTH:
      return { ...state, status: "loading" };
    case CALL_AUTH_SUCCESS:
      return { ...state, status: "success", error: null };
    case CALL_AUTH_FAILED:
      return { ...state, status: "failed", error: action.payload };
    case SET_AUTH_CUSTOMER:
      return {
        ...state,
        authCustomer: action.payload,
        status: "idle",
        isAuthenticated: true,
      };
    case LOG_OUT_CUSTOMER:
      return {
        ...state,
        authCustomer: null,
        status: "idle",
        isAuthenticated: false,
      };  
    default:
      return state;
  }
} 
  
export default authReducers;
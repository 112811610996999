import { Box, Tab } from "@mui/material";
import { styled } from "@mui/system";
import { SxProps } from "@mui/system";

export const VariantTab = styled(Tab)({
  color: "#222",
  fontWeight: "bold",
  fontSize: 15,
  textTransform: "capitalize",
  padding: "12px 30px",
  border: "0px solid transparent",
  borderTopColor: "#eee",
  "&.Mui-selected": {
    borderColor: "#eee",
    borderTopColor: "transparent",
    position: "relative",
  },
});

export const VariantImgContainer = styled(Box)({
  margin: "20px 10px",
  height: "50px",
});

export const VariantImg = styled("img")({
  height: "100%",
});

export const variantContentStyle: SxProps = {
  flexDirection: { xs: "column", sm: "row" },
  justifyContent: "space-evenly",
};

export const variantBoxStyle: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: { xs: "25px", sm: "50px 20px", md: "50px" },
  width: { xs: "100%", sm: "33%" },
};

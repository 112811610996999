import { Stack } from "@mui/material";
import { useState } from "react";
import ChooseCarCarousel from "./ChooseCarCarousel";
import ChooseCarFilterTabs from "./ChooseCarFilterTabs";
import VSContainer from "./VSContainer";
import { VSRoundedNumber, VSViewTitle } from "./VSUi";

const ChooseCarView = () => {
  const [currentModelType, setCurrentModelType] = useState<"All" | "Sedan/Hatchback" | "SUV">(
    "All"
  );

  return (
    <VSContainer>
      <Stack py={4}>
        <Stack direction='row' gap={2} justifyContent={{ xs: "center", md: "start" }}>
          <VSRoundedNumber numText='1' />
          <Stack>
            <VSViewTitle title='Choose a car' subTitle='Select Model and Click to Choose' />
          </Stack>
        </Stack>
        <ChooseCarFilterTabs currentType={currentModelType} onChange={setCurrentModelType} />
        <ChooseCarCarousel currentFilter={currentModelType} />
      </Stack>
    </VSContainer>
  );
};

export default ChooseCarView;

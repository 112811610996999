import type { AppState } from "../index";

export const selectPartsAndServicesListState = (state: AppState) => state.partsAndServices;

export const selectPartsAndServicesData = (state: AppState) => state.partsAndServices.data;

export const selectServicesForSearch = (state: AppState) => 
  state.partsAndServices.data.map((i) => { 
    return  { 
      id: i.id, 
      module: "Services", 
      title: i.name,
      content: i.content,
      link: `/services/${i.id}` 
    }
  });
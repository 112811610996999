import SectionTitle from "../../../components/common/SectionTitle";
import SectionContainer from "../../../components/layout/SectionContainer";
import { appColors } from "../../../theme";
import ModelContentCarousel from "../model-ui/ModelContentCarousel";
import useCurrentModel from "../utils/useCurrentModel";

const ModelSafetySection = () => {
  const model = useCurrentModel();

  if (!model) return null;

  const { safety } = model;

  return (
    <SectionContainer className='model-section' id='model-safety' bg={appColors.softGray}>
      <SectionTitle title='Safety' />
      <ModelContentCarousel modelContentData={safety} />
    </SectionContainer>
  );
};

export default ModelSafetySection;

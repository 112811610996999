import { CoreValue } from "../../store/about-us/types";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { appColors } from "../../theme";
import { ImageIcon, TextHeader } from "./ui";
import SectionContainer from "../../components/layout/SectionContainer";

const CoreValueTextIcon = ({ image, label }: { image: string; label: string }) => (
  <Stack alignItems='center'>
    <ImageIcon src={image} alt='Honda Cars Batangas Core Values' />
    <Typography fontWeight={600} fontSize={20}>
      {label}
    </Typography>
  </Stack>
);

interface CoreValuesProps {
  title: string,
  values: CoreValue[];
}

const CoreValues = (props : CoreValuesProps) => {
  const { title, values } = props;
  
  return (
    <SectionContainer bg={appColors.softGray} sx={{ py: { xs: 5, md: 7 } }}>
      <Stack alignItems='center' gap={8}>
        <TextHeader>
          <span>{title}</span>
        </TextHeader>
        <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" alignItems="center" width="100%">
           { values?.map((v, i) => (
            <Box key={i} sx={{ width: "100%", gap: { xs: 6, md: 4 } }} mb={{ xs: 5, sm: 2 }}>
              <CoreValueTextIcon image={v.media} label={v.label} />
            </Box>
          ))}
        </Stack>
      </Stack>
    </SectionContainer>
  );
}

export default CoreValues;
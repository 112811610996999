import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectHeaderData } from '../../../store/banner/selectors';
import { fetchHeaders } from '../../../store/banner/actions';
import { Stack, Box, Typography } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import {
  BannerCarouselContainerStyle,
  imageContainer,
  Heading,
  Content,
  TextContainer,
  ButtonStyle,
} from './BannerStyle';
import WebPrimaryButton from '../../../components/common/WebPrimaryButton';

const Header = () => {
  const dispatch = useDispatch();

  const headers = useSelector(selectHeaderData);

  useEffect(() => {
    dispatch(fetchHeaders());
  }, [dispatch]);

  if (headers.length === 0) return <Box sx={{ aspectRatio: '16 / 9' }} />;

  return (
    <Stack component='section' sx={BannerCarouselContainerStyle}>
      <Stack className='headers-carousel'>
        <Carousel
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          swipeable={false}
          infiniteLoop={true}
        >
          {headers.map((item) => (
            <Box key={item.id} sx={imageContainer}>
              <Box
                position='absolute'
                top={0}
                left={0}
                width='100%'
                height='100%'
                bgcolor='rgba(0,0,0,0.40)'
              />
              <img src={item.media} alt={item.name} />
              <Box sx={TextContainer}>
                <Typography sx={Heading}>{item.name}</Typography>
                <Typography sx={Content}>{item.content}</Typography>
                {item.link && (
                  <WebPrimaryButton sx={ButtonStyle} href={item?.link}>
                    More Details
                  </WebPrimaryButton>
                )}
              </Box>
            </Box>
          ))}
        </Carousel>
      </Stack>
    </Stack>
  );
};

export default Header;

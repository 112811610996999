import { AxiosResponse } from "axios";
import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { Status } from "../system/types";

import {
    LOAD_NAV_CONFIG_DATA,
    SET_NAV_CONFIG_STATUS,
    INavConfig,
    NavConfigListAction,
} from "./types";

const setNavConfigStatus = (status: Status): NavConfigListAction => ({
    type: SET_NAV_CONFIG_STATUS,
    payload: { status }
});

const loadNavConfigData = (data: INavConfig[]) : NavConfigListAction => ({
    type: LOAD_NAV_CONFIG_DATA,
    payload: { data }
})

export const fetchNavConfigs = (): AppThunk => async (dispatch) => {
    try{
        dispatch(setNavConfigStatus('loading'));

        const response: AxiosResponse<any> = await apiClient.request({
            url: `${apiEndpoints.navConfig}`
        });

        dispatch(loadNavConfigData(response.data));
    }
    catch(err: any){
        dispatch(setNavConfigStatus("error"));
        errorHandler(err, dispatch);
    }
}
import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { Status } from "../system/types";
import {
  IPromoBanner,
  LOAD_PROMO_BANNER_DATA,
  SET_PROMO_BANNER_STATUS,
  PromoBannerAction
} from "./types";

// Sync Actions
const setPromoBannerStatus = (status: Status): PromoBannerAction => ({
  type: SET_PROMO_BANNER_STATUS,
  payload: { status }
});

const loadPromoBannerData = (data: IPromoBanner[]): PromoBannerAction => ({
  type: LOAD_PROMO_BANNER_DATA,
  payload: { data }
});

export const fetchPromoBanner = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setPromoBannerStatus("fetching"));

    const response = await apiClient.request<{ banner: IPromoBanner }>({
      url: `${apiEndpoints.promos}/banner/PROMO::BANNER::1`,
    });

    dispatch(loadPromoBannerData([response.data.banner]));
  } catch (err: any) {
    dispatch(setPromoBannerStatus("error"));
    errorHandler(err, dispatch);
  }
};
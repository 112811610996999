import { SxProps } from "@mui/system";
import { appColors } from "../../../../theme";


export const VariantStackStyle:SxProps = {
    flexDirection:{xs:"column",md:"row"},
    marginBottom:{xs:"30px 0px 10px",md:"30px 0px"}
};


export const VariantBoxStyle:SxProps = {
    bgcolor:appColors.white,
    minHeight:"80vh",
    width: {xs:"100%",md:"33%"},
    padding:"30px"
};
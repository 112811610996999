import { styled } from "@mui/material";

export const ImgContainer=styled("img") ({
    width: "100%",
    objectFit: "cover",
    aspectRatio: "16 / 9",
    backfaceVisibility: "hidden",
    transform: "translateZ(0)",
    imageRendering: "-webkit-optimize-contrast",
    verticalAlign: "middle",
});
import Button, { ButtonProps } from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { appColors } from "../../theme";

const ProceedButton = (props: ButtonProps) => {
  return (
    <Button
      disableElevation
      disableRipple
      sx={{
        fontSize: 17,
        borderRadius: "10px",
        px: 4,
        width: "100%",
        backgroundColor: (theme) => theme.palette.primary.main,
        textTransform: "capitalize",
        "&:disabled": {
          backgroundColor: appColors.lightGray,
          color: appColors.white,
        },
      }}
      variant='contained'
      endIcon={<ArrowForwardIcon fontSize='small' />}
      {...props}
    >
      Proceed
    </Button>
  );
};

export default ProceedButton;

// @ts-ignore
import MessengerCustomerChat from "react-messenger-customer-chat";
import { useTheme } from "@mui/system";

const MessengerChat = () => {
  const theme = useTheme();

  const themeColor = theme.palette.primary.main;

  const facebookPageId = "101504285954148"; // https://www.facebook.com/help/1503421039731588
  // https://developers.facebook.com/ <-- navigate to this link and login using the facebook page account
  // to get the facebook app id, you will need to create an app under the facebook page account
  const facebookAppId = "366836012206204";
  return (
    <MessengerCustomerChat
      pageId={facebookPageId}
      appId={facebookAppId}
      themeColor={themeColor}
      language='sv_US'
    />
  );
};

export default MessengerChat;

import { Button } from "@mui/material";
import { BsListUl as ListIcon } from "react-icons/bs";

import useMeasure from "../../../hooks/useMeasure";

const VSViewDetailsButton = ({ onClick }: { onClick: () => void }) => {
  const colorSelectBounds = useMeasure("#vs-color-select");

  const bottomOffset = colorSelectBounds ? colorSelectBounds.height + 60 : 0;

  return (
    <Button
      disableElevation
      size='large'
      variant='contained'
      sx={{
        width: "max-content",
        position: { xs: "absolute", md: "static" },
        bottom: bottomOffset,
        left: "50%",
        transform: { xs: "translateX(-50%)", md: "unset" },
        zIndex: 4,
        textTransform: "capitalize",
      }}
      endIcon={<ListIcon />}
      onClick={onClick}
    >
      View Car Details
    </Button>
  );
};

export default VSViewDetailsButton;

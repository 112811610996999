import { AxiosResponse } from "axios";
import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { Status } from "../system/types";
import {
  LOAD_MODELS_DATA,
  SET_MODEL_STATUS,
  SET_SELECTED_MODEL,
  IModels,
  ModelListAction,
  SelectedModel
} from "./types";

const setModelStatus = (status: Status): ModelListAction => ({
  type: SET_MODEL_STATUS,
  payload: { status }
});

const loadModelData = (data: IModels[]): ModelListAction => ({
  type: LOAD_MODELS_DATA,
  payload: { data },
});

export const setModelVariants = (variant: SelectedModel): ModelListAction => ({
  type: SET_SELECTED_MODEL,
  payload: { ...variant }
});

export const fetchModels = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setModelStatus("loading"));

    const response:AxiosResponse<any> = await apiClient.request({
      url: `${apiEndpoints.models}`,
    });

    dispatch(loadModelData(response.data)); 
  } catch (err: any) {
    dispatch(setModelStatus("error"));
    errorHandler(err, dispatch);
  }
};
import { styled } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import { appColors } from "../../theme";

export const itemContainerStyle: SxProps = {
  display: 'flex',
  flexDirection: { xs: "column", md: "row" },
  alignItems: { xs: 'center', md: 'start' },
  justifyContent: 'flex-start',
  py: 2,
  px: 3,
  backgroundColor: appColors.hondaLight,
  columnGap: '25px',
  gap: { xs: 1, md: 3 },
}

export const imageContainerStyle: SxProps = {
  display: 'flex', 
  flexDirection: 'column', 
  justifyContent: 'center', 
  alignItems: 'center',
  width: "100px", 
  height: "100px"
}

export const textContainerStyle: SxProps = {
  display: 'flex', 
  flexDirection: 'column',
  alignItems: { xs: 'center', md: 'flex-start' }
}

export const nameStyle: SxProps = {
  fontWeight: '900'
}

export const textStyle: SxProps = {
  fontSize: 13, fontWeight: '700'
}

export const Image = styled('img')({
  objectFit:'contain',
  objectPosition:'center',
});
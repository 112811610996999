import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchModels } from '../../store/models/actions';

import ModelSection from './models';
import LatestPromoSection from './fragments/LatestPromoPage';
import NewsAndEventsSection from './news-and-events';
import QuickLinksSection from './quick-links';
import TestimonialSection from './testimonial/TestimonialSection';
import DataPrivacy from './data-privacy/DataPrivacy';
import BannerSection from './banner';
import Meta from '../../components/common/Meta';
const HomeIndex = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchModels());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Meta title='Welcome' />
      <DataPrivacy />
      <BannerSection />
      <QuickLinksSection />
      <ModelSection />
      <LatestPromoSection />
      <NewsAndEventsSection />
      <TestimonialSection />
    </React.Fragment>
  );
};

export default HomeIndex;

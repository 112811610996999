import { IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { controlButtonsStyles } from "./utilStyles";

/***  NEXT BUTTON ****/
interface NextButtonProps {
  onClick: () => void;
  hasNext: boolean;
  label: string;
}

export const NextButton = ({ onClick, hasNext, label }: NextButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      disabled={!hasNext}
      aria-label={label}
      sx={{
        ...controlButtonsStyles,
        zIndex: 2,
        right: "0",
      }}
    >
      <ArrowForwardIcon />
    </IconButton>
  );
};

/***  PREV BUTTON ****/
interface PrevButtonProps {
  onClick: () => void;
  hasPrev: boolean;
  label: string;
}

export const PrevButton = ({ onClick, hasPrev, label }: PrevButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      disabled={!hasPrev}
      aria-label={label}
      sx={{
        ...controlButtonsStyles,
        zIndex: 2,
        left: "0",
      }}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Box } from "@mui/material";
import {
  CarouselIndicator,
  NextButton,
  PrevButton,
} from "./model-content-carousel/ui";
import { containerStyles } from "./model-content-carousel/utilStyles";
import ModelContentCarouselItem from "./model-content-carousel/ModelContentCarouselItem";
import { ModelContent } from "../../../store/models/types";

interface ModelContentCarouselProps {
  modelContentData: ModelContent[];
}

const ModelContentCarousel = ({
  modelContentData,
}: ModelContentCarouselProps) => {
  return (
    <Box sx={containerStyles}>
      <Carousel
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        renderArrowNext={(onClick, hasNext, label) => (
          <NextButton onClick={onClick} hasNext={hasNext} label={label} />
        )}
        renderArrowPrev={(onClick, hasPrev, label) => (
          <PrevButton onClick={onClick} hasPrev={hasPrev} label={label} />
        )}
        renderIndicator={(clickHandler, isSelected, index, label) => (
          <CarouselIndicator
            clickHandler={clickHandler}
            isSelected={isSelected}
            index={index}
            label={label}
          />
        )}
        showIndicators={modelContentData.length <= 1 ? false:true}
      >
        {modelContentData.map((modelContentItem, index) => (
          <ModelContentCarouselItem
            key={index}
            modelContentItem={modelContentItem}
          />
        ))}
      </Carousel>
    </Box>
  );
};

export default ModelContentCarousel;
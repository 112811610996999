import SectionContainer from "../../../components/layout/SectionContainer";

import { appColors } from "../../../theme";
import NewQuickLinks from "./NewQuickLinks";

const QuickLinksSection = () => {
  return (
    <SectionContainer bg={appColors.softGray}>
      <NewQuickLinks />
    </SectionContainer>
  );
};

export default QuickLinksSection;

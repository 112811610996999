import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import useModels from "./utils/useModels";
import { Stack } from "@mui/material";
import useIsTabletOrMobile from "../../../hooks/useIsTabletOrMobile";

import {
  ChooseCarCarouselContainer,
  ModelPreviewImage,
  NextButton,
  PrevButton,
  CarouselIndicator,
} from "./VSUi";
import useMeasure from "../../../hooks/useMeasure";
import { Link } from "react-router-dom";

interface ChooseCarCarouselProps {
  currentFilter: "All" | "Sedan/Hatchback" | "SUV";
}

const ChooseCarCarousel = ({ currentFilter }: ChooseCarCarouselProps) => {
  const models = useModels();
  const containerBounds = useMeasure("#car-carousel");
  const isTabletOrMobile = useIsTabletOrMobile();

  const itemWidth = containerBounds ? containerBounds.width / 3 : "100%";

  const modelsToDisplay =
    currentFilter === "All" ? models : models.filter((m) => m.type === currentFilter);

  return (
    <ChooseCarCarouselContainer height={{ xs: 350, sm: 500, lg: 520 }} id='car-carousel'>
      <Carousel
        key={currentFilter + models.length}
        showStatus={false}
        showIndicators={isTabletOrMobile && modelsToDisplay.length > 1}
        showThumbs={false}
        infiniteLoop={modelsToDisplay.length > 3}
        centerMode={!isTabletOrMobile}
        centerSlidePercentage={100 / 3}
        renderArrowNext={(onClick, hasNext, label) => (
          <NextButton onClick={onClick} hasNext={hasNext} label={label} />
        )}
        renderArrowPrev={(onClick, hasPrev, label) => (
          <PrevButton onClick={onClick} hasPrev={hasPrev} label={label} />
        )}
        renderIndicator={(clickHandler, isSelected, index, label) => (
          <CarouselIndicator
            clickHandler={clickHandler}
            isSelected={isSelected}
            index={index}
            label={label}
          />
        )}
      >
        {modelsToDisplay.map((model) => (
          <Link to={`/virtual-showroom/car/${model.id}`} key={model.id}>
            <Stack
              sx={{
                cursor: "pointer",
                display: "inline-block",
                transition: "transform 0.2s ease-out",
                width: modelsToDisplay.length < 3 ? { xs: "100%", md: itemWidth } : "auto",
                "&: hover": {
                  transform: { xs: "scale(1)", md: "scale(1.2)" },
                },
              }}
            >
              <ModelPreviewImage src={model.imagePreviewUrl} alt={model.name} />
            </Stack>
          </Link>
        ))}
      </Carousel>
    </ChooseCarCarouselContainer>
  );
};

export default ChooseCarCarousel;

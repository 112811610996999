import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import WebPrimaryButton from '../../../../components/common/WebPrimaryButton';
import { IModels } from '../../../../store/models/types';
import { ImgContainer } from './BannerStyle';

interface BannerProps {
  model: IModels;
}

const Banner = ({ model }: BannerProps) => {
  const onDownload = () => {
    const link = document.createElement('a');
    link.href = `${model?.brochureLink}`;
    link.download = `HCBT_${model.name}_brochure.pdf`;

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    link.click();

    //remove the link when done
    document.body.removeChild(link);
  };
  return (
    <Box
      component='section'
      minHeight='70vh'
      display='grid'
      gridTemplateColumns={{ md: '40% 60%' }}
      gap={4}
    >
      <Stack mt={{ xs: 0, md: 10 }} order={{ xs: 2, md: 1 }}>
        <Typography component='h2' fontWeight={900} fontSize={45}>
          {model.bannerTitle}
        </Typography>
        <Typography color='#B4B4B4' width='90%' mb={4}>
          {model.bannerText}
        </Typography>
        {model.brochureLink && (
          <WebPrimaryButton sx={{ alignSelf: 'start' }} onClick={onDownload}>
            Download Brochure
          </WebPrimaryButton>
        )}
      </Stack>
      <Stack order={{ xs: 1, md: 2 }} width={{ xs: '100%', md: 660 }}>
        <ImgContainer src={model.bannerImageUrl} alt={model.name} />
      </Stack>
    </Box>
  );
};

export default Banner;

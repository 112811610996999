import { Stack, Typography } from "@mui/material";
import SectionTitle from "../../../../components/common/SectionTitle";
import { appColors } from "../../../../theme";
import ModelSelection from "./ModelSelection";

const CompareModelsLayout = () => {
  return (
    <Stack>
      <SectionTitle title='Compare Models' />
      <Typography fontSize={14} ml={0.2} color={appColors.grey}>
        Select your desired Honda models for a specs comparison.
      </Typography>
      <ModelSelection />
    </Stack>
  );
};

export default CompareModelsLayout;

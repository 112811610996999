import type { AppError, IFeatureSingleDataState, Status } from "../system/types";

export interface IGeneralJob {
  id: string;
  title: string;
  content: string;
  dateCreated: Date;
  dateUpdated: Date;
}

export type GeneralJobState = IFeatureSingleDataState<IGeneralJob>;

export const SET_GENERAL_JOB_STATUS = "general_job/status/set";
export const SET_GENERAL_JOB_ERROR = "general_job/error/set";
export const LOAD_GENERAL_JOB_DATA = "general_job/data/load";

interface SetGeneralJobStatusAction {
  type: typeof SET_GENERAL_JOB_STATUS;
  payload: { status: Status };
}

interface SetGeneralJobErrorAction {
  type: typeof SET_GENERAL_JOB_ERROR;
  payload: { error: AppError };
}

interface SetGeneralJobDataAction {
  type: typeof LOAD_GENERAL_JOB_DATA;
  payload: { data: GeneralJobState["data"] };
}

export type GeneralJobAction = 
  | SetGeneralJobStatusAction
  | SetGeneralJobErrorAction
  | SetGeneralJobDataAction;
import NewsAndEventsListingPage from "./NewsAndEventsListingPage";

const NewsAndEventsList = () => {
  return (
    <>
      <NewsAndEventsListingPage type='All' />
      <NewsAndEventsListingPage type='News' />
      <NewsAndEventsListingPage type='Event' />
    </>
  );
};

export default NewsAndEventsList;

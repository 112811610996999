import { Link } from "react-router-dom";

import { styled, SxProps } from "@mui/system";
import { Typography, Stack, Button } from "@mui/material";
import { appColors } from "../../theme";

export const searchMainContainerStyle : SxProps = {
  backgroundColor: appColors.softGray,
  px: { xs: 1, lg: 6 }, py: { xs: 2, lg: 5 },
  minHeight: "85vh"
}

export const searchFieldStyle : SxProps = {
  ".MuiOutlinedInput-root" : {
    borderRadius: 0
  },
}

export const SearchButton = styled(Button)({
  backgroundColor: appColors.primary, 
  color: appColors.white, 
  borderRadius: 0, 
  width: "7%",
  ":hover" : {
    border: "1px solid",
    borderColor: appColors.primary,
    color: appColors.primary
  }
});

export const SearchResultLabel = styled(Typography)({
  fontSize: 23,
  fontWeight: 600,
  marginBottom: "15px"
});

export const SearchNoResultLabel = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  margin: "5px 0px"
});

export const SearchModule = styled(Typography)({
  fontSize: 16,
  fontWeight: 500,
  textTransform: "uppercase"
});

export const SearchTitle = styled(Typography)({
  fontSize: 25,
  fontWeight: 600,
  color: appColors.primary
});

export const SearchContent = styled(Typography)({
  fontSize: 13,
  color: appColors.mediumGray
});

export const SearchLink = styled(Link)({
  margin: "3px 0px",
  color: appColors.primary
});

export const PageNumber = styled(Stack)<{ number: number, current: number, istabletormobile: string }>(({ number, current, istabletormobile }) => ({
  padding: istabletormobile === "true" ? "3px 10px" : "8px 16px",
  borderRadius: "3px",
  fontWeight: 500,
  cursor: "pointer",
  backgroundColor: number === current ? appColors.primary : "",
  color: number === current ? appColors.white : "",
  ":hover" : {
    backgroundColor: appColors.white,
    padding: istabletormobile === "true" ? "2px 9px" : "7px 15px",
    border: "1px solid",
    borderColor: appColors.primary,
    color: appColors.primary
  }
}));
import { styled } from "@mui/system";

const PromoImage = styled("img")(({ theme }) => ({
	objectFit: "cover",
	backfaceVisibility: "hidden",
	transform: "translateZ(0)",
	imageRendering: "-webkit-optimize-contrast",
	verticalAlign: "middle",
	transition: "transform 0.3s ease-out",

	[theme.breakpoints.between("xs", "md")]: {
		aspectRatio: "16 / 9",
		height: "100%",
	},
}));

export default PromoImage;
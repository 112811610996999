import { Stack } from "@mui/material";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import LoadingIndicator from "../../../components/common/LoadingIndicator";

import ChooseCarView from "./ChooseCarView";
import ShowroomView from "./ShowroomView";
import useModelShowroomFetcher from "./utils/useModelShowroomFetcher";
import VSNumberedButton from "./VSNumberedButton";

const ShowroomPage = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const loading = useModelShowroomFetcher();

  if (loading)
    return (
      <Stack height='70vh' justifyContent='center'>
        <LoadingIndicator show />
      </Stack>
    );

  const hasSelectedModel = pathname !== "/virtual-showroom/car";

  return (
    <>
      <Switch>
        <Route path='/virtual-showroom/car/:id'>
          <ShowroomView />
        </Route>
        <Route path='/virtual-showroom/car'>
          <ChooseCarView />
        </Route>
      </Switch>
      <Stack 
        direction='row'
        component='footer'
        mt='auto'
        bottom='0'
        position={{ lg:'fixed' }}
        width='100%'
      >
        <VSNumberedButton
          onClick={() => history.push("/virtual-showroom/car")}
          className={hasSelectedModel ? "" : "active"}
          numText='1'
          text='Choose a car'
        />
        <VSNumberedButton
          className={hasSelectedModel ? "active" : ""}
          numText='2'
          text='Virtual Showroom'
          disabled={!hasSelectedModel}
        />
      </Stack>
    </>
  );
};

export default ShowroomPage;

import { Button } from "@mui/material";
import { styled } from "@mui/system";

const WebOutlinedButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "bg" && prop !== "currentColor",
})<{
  bg?: "white" | "transparent";
  currentColor?: "white" | "primary";
}>(({ theme, bg = "transparent", currentColor = "primary" }) => ({
  border: "1.8px solid",
  borderColor: currentColor === "primary" ? theme.palette.primary.main : currentColor,
  color: currentColor === "primary" ? theme.palette.primary.main : currentColor,
  backgroundColor: bg,
  textTransform: "capitalize",
  borderRadius: 0,
  minWidth: 215,
  padding: "10px 24px",

  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    color: "white",
  },
}));

export default WebOutlinedButton;

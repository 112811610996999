import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectModelById } from '../../../store/models/selectors';
import { convertToPhp } from '../../../utils/convert-currency';
import { useState } from 'react';
import { VSCloseButton, VSVariantSelect, VSVariantSummary, VSWhiteButton } from './VSUi';
// import CarIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import BrochureIcon from '@mui/icons-material/DescriptionOutlined';
// import { MdOutlineEditNote as QuoteIcon } from "react-icons/md";
import { useHistory, useParams } from 'react-router-dom';
import { orderBy } from 'lodash';

interface VSVariantCardProps {
  onClose: () => void;
  show: boolean;
}

const VSVariantCard = ({ onClose, show }: VSVariantCardProps) => {
  const { id } = useParams<{ id: string }>();
  const currentModel = useSelector(selectModelById(id));

  const highest = orderBy(
    currentModel ? currentModel.variant.map((v) => v.summary) : [],
    ['price'],
    ['desc']
  )[0];

  const defaultPriceIndex = currentModel?.variant.findIndex(
    (v) => v.summary.price === highest.price
  );

  const [selectedVariant, setSelectedVariant] = useState(defaultPriceIndex || 0);

  const history = useHistory();

  if (!currentModel) return null;

  const { name, variant } = currentModel;

  const variantOptions = variant.map((v) => ({
    value: v.name,
    price: convertToPhp(v.summary.price),
  }));

  const currentVariant = variant[selectedVariant];

  const handleVariantSelectChange = (variantValue: string) => {
    const variantIndex = variant.findIndex((v) => v.name === variantValue);
    setSelectedVariant(variantIndex);
  };

  const goToModelDetailsPage = () => {
    history.push(`/models/${currentModel.id}`);
  };

  return (
    <Stack
      width={{ xs: 'calc(100% - 32px)', md: 530 }}
      pt={2}
      pb={3}
      px={{ xs: 2.2, md: 4 }}
      gap={4}
      bgcolor='#FAFAFA'
      borderRadius={2}
      zIndex={5}
      position='absolute'
      overflow='hidden'
      top={32}
      sx={{
        left: { xs: '50%', md: 'unset' },
        transition: 'transform 0.3s ease-in, opacity 0.3s ease-in',
        transformOrigin: { xs: 'bottom center', md: 'top left' },
        transform: show
          ? { xs: 'translateX(-50%) scale(1)', md: 'scale(1)' }
          : { xs: 'translateX(-50%) scale(0)', md: 'scale(0)' },
        opacity: show ? 1 : 0,
        backfaceVisibility: 'hidden',
      }}
    >
      <Typography fontSize={22} fontWeight={600} lineHeight={1}>
        {name}
      </Typography>
      <VSCloseButton onClick={onClose} />
      <Stack height={{ xs: 350, sm: 'auto' }} overflow='auto' gap={3}>
        <VSVariantSelect
          value={currentVariant.name}
          options={variantOptions}
          onChange={handleVariantSelectChange}
        />
        <VSVariantSummary dangerouslySetInnerHTML={{ __html: currentVariant.summary.content }} />
        <VSWhiteButton disableElevation onClick={goToModelDetailsPage}>
          View Specs & Features
        </VSWhiteButton>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'stretch', md: 'center' }}
          // gap={{ xs: 1, md: 2 }}
          mt={-1}
        >
          {/* <VSWhiteButton disableElevation startIcon={<CarIcon />}>
            Reserve Car
          </VSWhiteButton>
          <VSWhiteButton disableElevation startIcon={<QuoteIcon />}>
            Request Quote
          </VSWhiteButton> */}
          <VSWhiteButton disableElevation startIcon={<BrochureIcon />}>
            <a
              style={{ color: 'inherit', textDecoration: 'none' }}
              href={currentModel.brochureLink}
              rel='noreferrer'
            >
              Download Brochure
            </a>
          </VSWhiteButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default VSVariantCard;

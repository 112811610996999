import { Drawer, Stack, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { navItemsv3 as navItems } from "../../constants";
import { appColors } from "../../theme";
import { MobileNavLink, VirtualShowroomNavLink } from "./NavLinkItem";

interface MobileNavProps {
  open: boolean;
  onClose: () => void;
}
const MobileNav = ({ open, onClose }: MobileNavProps) => {
  return (
    <Drawer
      onClick={onClose}
      open={open}
      anchor='left'
      onClose={onClose}
      sx={{
        width: "70vw",
        flexShrink: 0,
        overflow: "auto",
        display: { xs: "block", md: "none" },
        [`& .MuiDrawer-paper`]: {
          width: "70vw",
          boxSizing: "border-box",
          bgcolor: appColors.blueHeader,
        },
      }}
    >
      <Stack
        px={4}
        height={{ xs: 61, sm: 76 }}
        justifyContent='center'
        borderBottom={`1px solid ${appColors.mediumGray}`}
      >
        <Typography color={appColors.mediumGray} letterSpacing={1} fontSize={14}>
          MENU
        </Typography>
      </Stack>
      <Stack mt={1}>
        {navItems.map((nav) => (
          <MobileNavLink key={nav.label} to={nav.path}>
            {nav.label} <ChevronRightIcon />
          </MobileNavLink>
        ))}
        <Stack px={4} mt={4}>
          <VirtualShowroomNavLink to='/virtual-showroom'>Virtual Showroom</VirtualShowroomNavLink>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default MobileNav;

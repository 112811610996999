import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { TestimonialFields } from "../store/testimonial/types";
import { selectTestimonialState } from "../store/testimonial/selectors";
import { submitTestimonial } from "../store/testimonial/actions";

const schema = yup
  .object({
    fName: yup.string().required("First Name is required").trim(),
    lName: yup.string().required("Last Name is required").trim(),
    address: yup.string().optional().trim(),
    content: yup.string().required("Comment or Message is required"),
  })
  .required();

const defaultValues: TestimonialFields = {
  fName: "",
  lName: "",
  address: "",
  content: "",
  mediaFile: null,
  rating: 0,
  anonymous: false
};

export default function useTestimonialForm() {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm<TestimonialFields>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { status, error } = useSelector(selectTestimonialState);
  const dispatch = useDispatch();

  const onSubmit = (formData: TestimonialFields) => {
    if (status === "submitting") return;

    dispatch(submitTestimonial(formData, reset));
  };

  const submitHandler = handleSubmit(onSubmit);

  return {
    register,
    errors,
    submitHandler,
    disabled: !isValid || status === "submitting",
    submitStatus: status,
    isSubmitSuccessful,
    serverError: error ? error.message : "Something went wrong :(",
    control,
    setValue
  };
}

import { Route, Switch } from 'react-router-dom';

import { Box } from '@mui/material';
import PromoDetail from './PromoDetail';
import PromoListView from './PromoListView';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SectionContainer from '../../components/layout/SectionContainer';
import Meta from '../../components/common/Meta';
import PromoBanner from './PromoBanner';

const queryClient = new QueryClient();

const PromoPage = () => {
  return (
    <>
    <PromoBanner />
    <QueryClientProvider client={queryClient}>
      <Meta title='Promos' />
      <SectionContainer>
        <Box minHeight='85vh'>
          <Switch>
            <Route path='/promos/:id'>
              <PromoDetail />
            </Route>
            <Route path='/promos'>
              <PromoListView />
            </Route>
          </Switch>
        </Box>
      </SectionContainer>
    </QueryClientProvider>
    </>
  );
};

export default PromoPage;

import { SxProps } from "@mui/system";

export const controlButtonsStyles: SxProps = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  bgcolor: "white",
  border: "1px solid #333",
  borderRadius: 0,
  visibility: { xs: "hidden", md: "visible" },
  "&:hover": {
    bgcolor: "white",
  },
  "&:disabled": {
    bgcolor: "white",
    borderColor: "rgba(0,0,0,0.26)",
  },
};

export const containerStyles: SxProps = {
  px: { xs: 2, md: 8 },
  mb: 8,
  position: "relative",
  "& .carousel-slider": { position: "initial" },
};
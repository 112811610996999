import { Button } from "@mui/material";
import { styled } from "@mui/system";

interface VSNumberedButtonProps {
  text: string;
  numText: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const StyledButton = styled(Button)(({ theme }) => ({
  flex: 1,
  backgroundColor: "white",
  color: "#222",
  borderRadius: 0,
  paddingTop: 16,
  paddingBottom: 16,
  fontSize: 16,
  textTransform: "capitalize",

  "& .numText": {
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid currentcolor",
    borderRadius: "50%",
    fontSize: 16,
    marginRight: 16,
  },

  "&.active": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",

    "& .numText": {
      marginRight: 0,
    },
  },
}));

const VSNumberedButton = ({
  text,
  numText,
  className,
  disabled,
  onClick,
}: VSNumberedButtonProps) => {
  return (
    <StyledButton onClick={onClick} className={className} disableElevation disabled={disabled}>
      <span className='numText'>{numText}</span> {text}
    </StyledButton>
  );
};

export default VSNumberedButton;

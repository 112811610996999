import { Box, Button, Typography } from '@mui/material';
import { appColors } from '../../../theme';
import ImageCarCenter from '../../../assets/images/virtual-showroom-page/virtual-car-center/virtual-car-center-01.png';
import ImageCarLeft from '../../../assets/images/virtual-showroom-page/virtual-car-left/virtual-car-left-02.png';
import ImageCarRight from '../../../assets/images/virtual-showroom-page/virtual-car-right/virtual-car-right-01.png';
import useMeasure from '../../../hooks/useMeasure';
import { useHistory } from 'react-router-dom';
import {
  buttonStartVirtualShowroom,
  carCenterKeyframes,
  carLeftKeyframes,
  carRightKeyframes,
  ImageFrame,
  textKeyframes,
  TransparentBackGround,
} from './components';

import { Md360 as Md360Icon } from 'react-icons/md';
import Meta from '../../../components/common/Meta';

const LandingPage = () => {
  const history = useHistory();
  const boxMeasure = useMeasure('#virtualShowroomBoxContainer');
  const boxWidth = boxMeasure ? boxMeasure.width : 0;

  return (
    <Box
      mb={4}
      sx={{
        minHeight: '700px',
      }}
    >
      <Meta title='Virtual Showroom' />
      <TransparentBackGround id='virtualShowroomBoxContainer'>
        <Box
          sx={{
            textAlign: 'center',
            position: 'absolute',
            animation: `${textKeyframes} .7s`,
            animationFillMode: 'forwards',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              gap: { sm: 0, md: 2 },
              flexDirection: { xs: 'column', xl: 'row' },
            }}
          >
            <Typography
              sx={{
                lineHeight: { xs: 1.1, xl: 1.5 },
                fontWeight: 'bold',
                margin: 0,
                padding: 0,
                fontSize: {
                  xs: 36,
                  sm: 65,
                  lg: 60,
                },
              }}
            >
              Welcome to Honda Cars Batangas
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: 17,
              my: { xs: 4, md: 0 },
              marginX: { xs: '5%', sm: '10%', md: 0 },
            }}
          >
            Explore the exterior and interior of our vehicles
          </Typography>
        </Box>
      </TransparentBackGround>
      <TransparentBackGround
        sx={{
          transform: {
            xs:
              boxWidth < 599 && boxWidth >= 500
                ? 'scale(.57)'
                : boxWidth < 499 && boxWidth >= 400
                ? 'scale(.47)'
                : 'scale(.37)',
            sm: 'scale(.7)',
            md: 'scale(1)',
          },
          marginTop: {
            xs: '30px',
            sm: boxWidth < 900 && boxWidth >= 668 ? '30px' : '60px',
            md: 4,
          },
        }}
      >
        <ImageFrame
          imageUrl={`url(${ImageCarLeft})`}
          sx={{
            top: '200px',
            width: '300px',
            height: '300px',
            animation: `${carLeftKeyframes} .7s`,
            animationFillMode: 'forwards',
          }}
        />
        <ImageFrame
          imageUrl={`url(${ImageCarRight})`}
          sx={{
            top: '250px',
            width: '230px',
            height: '230px',
            animation: `${carRightKeyframes} .7s`,
            animationFillMode: 'forwards',
          }}
        />
        <ImageFrame
          imageUrl={`url(${ImageCarCenter})`}
          sx={{
            width: '500px',
            height: '500px',
            animation: `${carCenterKeyframes} .7s`,
            animationFillMode: 'forwards',
          }}
        />
      </TransparentBackGround>
      <TransparentBackGround>
        <Button
          onClick={() => {
            history.push('/virtual-showroom/car');
          }}
          sx={{
            mt: { xs: 3, lg: 6 },
            animation: `${buttonStartVirtualShowroom} .7s`,
            animationFillMode: 'forwards',
            position: 'absolute',
            padding: '16px 32px',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: appColors.white,
            borderRadius: 3,
            textTransform: 'capitalize',
            '&:hover': {
              color: appColors.white,
              backgroundColor: 'primary.main',

              '& > p': {
                color: appColors.white,
              },
            },
          }}
        >
          <Typography fontSize={18} fontWeight='bold' display='flex' alignItems='center' gap={1.4}>
            <Md360Icon fontSize={32} /> Start Virtual Showroom
          </Typography>
          <Typography fontSize={14} textAlign='center' color={appColors.dark} sx={{ mt: 0.5 }}>
            View our vehicles in 360&deg; Angles.
          </Typography>
        </Button>
      </TransparentBackGround>
    </Box>
  );
};

export default LandingPage;

import { Stack, Box, Typography } from "@mui/material";
import { IModels } from "../../store/models/types";
import { appColors } from "../../theme";

interface ModelItemProps {
  model: IModels;
}

const ModelItem = ({ model }: ModelItemProps) => {
  return (
    <Stack
      py={2}
      px={1}
      width="100%"
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="space-between"
      height="100%"
    >
      <Typography fontSize={18} fontWeight={700} color={appColors.dark}>{model.price}</Typography>
      <Box>
        <img
            src={model.imagePreviewUrl}
            alt='Honda Cars Batangas Model'
            style={{
              objectFit: "contain",
              backfaceVisibility: "hidden",
              transform: "translateZ(0)",
              imageRendering: "-webkit-optimize-contrast",
              verticalAlign: "middle",
              width: "100%",
            }}
        />
       </Box>
    </Stack>
  );
};

export default ModelItem;
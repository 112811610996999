import { AnyAction } from "redux";

export type Token = string | null;
export interface AuthCustomer {
  id: string;
  cardNumber: string;
  firstName: string;
  lastName: string;
  points: number;
  redeemablePoints: number;
  token: Token;
}

export interface AuthState {
  authCustomer: AuthCustomer | null;
  isAuthenticated: boolean;
  status: "idle" | "loading" | "success" | "failed";
  error: string | null | undefined;
}

export const CALL_AUTH = "call_auth";
export const CALL_AUTH_SUCCESS = "call_auth_success";
export const CALL_AUTH_FAILED = "call_auth_failed";
export const SET_AUTH_CUSTOMER = "set_auth_customer";
export const LOG_OUT_CUSTOMER = "log_out_customer";

export interface CallAuthAction {
  type: typeof CALL_AUTH;
  payload: null;
}

export interface CallAuthSuccessAction {
  type: typeof CALL_AUTH_SUCCESS;
  payload: null;
}

export interface CallAuthFailedAction {
  type: typeof CALL_AUTH_FAILED;
  payload: string | null;
}

export interface SetAuthCustomerAction {
  type: typeof SET_AUTH_CUSTOMER;
  payload: AuthCustomer;
}

export interface LogoutCustomerAction {
  type: typeof LOG_OUT_CUSTOMER;
  payload: null;
}

export type AuthAction =
  | CallAuthAction
  | CallAuthSuccessAction
  | CallAuthFailedAction
  | SetAuthCustomerAction
  | LogoutCustomerAction
  | AnyAction;

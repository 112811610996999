import {
  NewsAndEventsAction,
  NewsAndEventsState,
  SET_NEWS_AND_EVENTS_STATUS,
  LOAD_ALL_NEWS_AND_EVENTS_DATA,
  LOAD_NEWS_AND_EVENTS_DATA,
  SET_NEWS_AND_EVENTS_FILTER,
  SET_NEWS_AND_EVENTS_ERROR,
  LOAD_MORE_NEWS_AND_EVENTS_DATA,
  SET_NEWS_AND_EVENTS_SORTBY,
} from "./types";

const initialState: NewsAndEventsState = {
  status: "idle",
  error: undefined,
  data: [],
  allData: [],
  filter: null,
  sortBy: "dateCreated:desc",
};

const newsAndEventsReducer = (
  state = initialState,
  action: NewsAndEventsAction
): NewsAndEventsState => {
  switch (action.type) {
    case SET_NEWS_AND_EVENTS_STATUS:
      return { ...state, status: action.payload.status };
    case SET_NEWS_AND_EVENTS_ERROR:
      return { ...state, status: "error", error: action.payload.error };
    case LOAD_ALL_NEWS_AND_EVENTS_DATA:
      return { ...state, allData: action.payload.allData, status: "idle" };
    case LOAD_NEWS_AND_EVENTS_DATA:
      return { ...state, data: action.payload.data, status: "idle" };
    case LOAD_MORE_NEWS_AND_EVENTS_DATA:
      return { ...state, data: [...state.data, ...action.payload.data], status: "idle" };
    case SET_NEWS_AND_EVENTS_FILTER:
      return { ...state, filter: action.payload.filter };
    case SET_NEWS_AND_EVENTS_SORTBY:
      return { ...state, sortBy: action.payload.sortBy };
    default:
      return state;
  }
};

export default newsAndEventsReducer;

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { ReactNode } from "react";

interface WebDialogProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
  subTitle?: string;
}

const WebDialog = (props: WebDialogProps) => {
  const { open, onClose, title, subTitle, children } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      PaperProps={{
        sx: {
          m: { xs: 1, md: 4 },
          width: { xs: "100%", md: 600 },
        },
      }}
    >
      <DialogContent sx={{ padding: { xs: "10px 10px", md: "20px 12px" } }}>
        <Stack alignItems='center' mt={2} mb={4}>
          <Typography
            fontSize={{ xs: 22, md: 32 }}
            textAlign='center'
            fontWeight={600}
            lineHeight={1.3}
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography fontSize={14} fontWeight={300} textAlign='center'>
              {subTitle}
            </Typography>
          )}
          <IconButton
            onClick={onClose}
            disableRipple
            aria-label='close'
            size='small'
            sx={{ position: "absolute", top: 12, right: 12 }}
          >
            <CloseIcon fontSize='small' sx={{ color: "#222" }} />
          </IconButton>
        </Stack>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default WebDialog;

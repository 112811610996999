import { Box } from "@mui/material";
import { Switch, Route } from "react-router-dom";
import SectionContainer from "../../components/layout/SectionContainer";
import CareerDetails from "./CareerDetails";
import CareerList from "./CareerList";
import CareerBanner from "./CareerBanner";
import GeneralJobDetails from "./GeneralJobDetails";
import Meta from '../../components/common/Meta';

const CareersPage = () => {
  return (
    <>
      <CareerBanner />
      <SectionContainer>
        <Meta title='Careers' />
        <Box minHeight='85vh'>
          <Switch>
            <Route path='/careers/general-job'>
              <GeneralJobDetails />
            </Route>
            <Route path='/careers/:id'>
              <CareerDetails />
            </Route>
            <Route exact path='/careers'>
              <CareerList />
            </Route>
          </Switch>
        </Box>
      </SectionContainer>
    </>
  );
};

export default CareersPage;

import { KeyboardArrowDown } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { useState, MouseEvent } from "react";
import WebSortButton from "../../components/common/WebSortButton";
import usePartsAndAccessoriesState from "./utils/usePartsAndAccessoriesState";

const PartsAndAccessoriesFilter = () => {
  const { filter, onFilterChange, status } = usePartsAndAccessoriesState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
       <WebSortButton
        id='sort-button'
        aria-controls={open ? "sort-button-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {filter} <KeyboardArrowDown />
      </WebSortButton>
      <Menu
        id='sort-button-menu'
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "sort-button",
        }}
      >
        <MenuItem
          sx={{ fontSize: 15, color: filter === "All" ? "primary.main" : "inherit" }}
          onClick={() => onFilterChange("All")}
        >
          All
        </MenuItem>
        <MenuItem
          sx={{ fontSize: 15, color: filter === "Part" ? "primary.main" : "inherit" }}
          onClick={() => onFilterChange("Part")}
        >
          Parts
        </MenuItem>
        <MenuItem
          sx={{ fontSize: 15, color: filter === "Accessory" ? "primary.main" : "inherit" }}
          onClick={() => onFilterChange("Accessory")}
        >
          Accessories
        </MenuItem>
        <MenuItem
          sx={{ fontSize: 15, color: filter === "Others" ? "primary.main" : "inherit" }}
          onClick={() => onFilterChange("Others")}
        >
          Others
        </MenuItem>
      </Menu>
    </div>
  )
}

export default PartsAndAccessoriesFilter;
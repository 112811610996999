import { useDispatch, useSelector } from "react-redux";
import {
  setNewsAndEventFilter,
  setNewsAndEventSortBy,
} from "../../../store/news-and-events/actions";
import {
  selectNewsAndEventsData,
  selectNewsAndEventsState,
} from "../../../store/news-and-events/selectors";

export default function useNewsAndEventsState() {
  const dispatch = useDispatch();
  const { status, error, filter, sortBy } = useSelector(selectNewsAndEventsState);

  const data = useSelector(selectNewsAndEventsData);

  const onFilterChange = (fil: typeof filter) => dispatch(setNewsAndEventFilter(fil));

  const onSortByChange = (sort: typeof sortBy) => dispatch(setNewsAndEventSortBy(sort));

  const filteredData = filter === null ? data : data.filter((d) => d.type === filter);

  return { status, error, filteredData, onFilterChange, filter, sortBy, onSortByChange };
}

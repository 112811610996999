import { Stack } from '@mui/material';
import { VirtualShowroom } from '../../../store/virtual-showroom/types';
import { VSPreviewContainer } from './VSUi';
import VSCircularProgress from './VSCircularProgress';
import use360Viewer from './utils/use360Viewer';
import Meta from '../../../components/common/Meta';

interface Props {
  showroom: VirtualShowroom;
  translated: boolean;
}

const VSExteriorView = ({ showroom, translated }: Props) => {
  const { loadProgress } = use360Viewer(showroom);

  if (!showroom) return null;

  const { name, exterior } = showroom;

  const previewImageUrl = exterior[0];

  return (
    <>
      <Meta title={`${name} | Exterior`} />
      <VSPreviewContainer key={name} id='jsv-holder' translated={translated}>
        {loadProgress < 100 && (
          <Stack
            position='absolute'
            top={0}
            right={0}
            width='100%'
            height='75%'
            zIndex={3}
            display='flex'
            justifyContent='center'
            alignItems='center'
            sx={{ backdropFilter: 'blur(10px)' }}
          >
            <VSCircularProgress progress={loadProgress} />
          </Stack>
        )}
        <img id='jsv-image' alt={name} src={previewImageUrl.image as string} />
      </VSPreviewContainer>
    </>
  );
};

export default VSExteriorView;

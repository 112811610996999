import { Stack } from "@mui/material";
import { Switch, Route } from "react-router-dom";
import LandingPage from "./LandingPage";
import ImageBackground from "../../assets/images/virtual-showroom-page/others/background.webp";
import ShowroomPage from "./showroom-page";

const VirtualShowroomPage = () => {
  return (
    <Stack
      sx={{
        minHeight: "90vh",
        backgroundPosition: "center",
        backgroundColor: "#ccc",
        backgroundImage: `url(${ImageBackground})`,
        backgroundSize: "cover",
      }}
    >
      <Switch>
        <Route path='/virtual-showroom/car'>
          <ShowroomPage />
        </Route>
        <Route path='/virtual-showroom'>
          <LandingPage />
        </Route>
      </Switch>
    </Stack>
  );
};

export default VirtualShowroomPage;

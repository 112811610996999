import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { fetchCareerList } from "../store/careers/actions";
import { fetchAllNewsAndEvents } from "../store/news-and-events/actions";
import { fetchPASList } from "../store/parts-and-service/actions";
import { fetchAllPromo } from "../store/promos/actions";
import { fetchModels } from "../store/models/actions";
import { fetchTestimonialList } from "../store/testimonial/actions";
import { fetchNavConfigs } from "../store/nav-config/actions";

export const useAllData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNavConfigs());
    dispatch(fetchCareerList());
    dispatch(fetchAllNewsAndEvents());
    dispatch(fetchPASList());
    dispatch(fetchAllPromo());
    dispatch(fetchModels());
    dispatch(fetchTestimonialList());
  }, [dispatch])
}
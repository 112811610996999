import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChevronLeft } from '@mui/icons-material';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CareerNotFound from './CareerNotFound';
import InquiryForm from './InquiryForm';
import RedLink from '../../components/lib/RedLink';
import SectionTitle from '../../components/common/SectionTitle';
import FetchingLoadingIndicator from '../../components/common/FetchingLoadingIndicator';
import Meta from '../../components/common/Meta';
import { fetchGeneralJob } from "../../store/general-job-application/actions";
import { selectGeneralJobData, selectGeneralJobState } from "../../store/general-job-application/selectors";

const GeneralJobDetails = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectGeneralJobData);
  const { status } = useSelector(selectGeneralJobState);

  useEffect(() => {
    dispatch(fetchGeneralJob());
  }, [dispatch]);

  if (status === 'loading') {
    return <FetchingLoadingIndicator />;
  }

  if (!data) {
    return <CareerNotFound />;
  }

  return (
    <>
      <Meta title={data?.title} />
      <Stack
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: 2, md: 10 },
        }}
      >
        <Stack flex={1} gap={2}>
          <SectionTitle title={data?.title} />
          <Divider />
          <Stack>
            <Typography component='h3' fontWeight='600' fontSize={20}>
              Qualifications
            </Typography>
            <Stack gap={2} pl={0}>
              <div
                style={{
                  color: '#000',
                  fontSize: '0.9rem',
                  marginBottom: '1rem',
                }}
                dangerouslySetInnerHTML={{ __html: data?.content }}
              />
            </Stack>
            <RedLink to='/careers'>
              <ChevronLeft fontSize='small' />
              Job Openings
            </RedLink>
          </Stack>
        </Stack>
        <Stack flex={1}>
          <InquiryForm careerName={data?.title} />
        </Stack>
      </Stack>
    </>
  )
}

export default GeneralJobDetails;
import { Box, Stack } from "@mui/material";
import { appColors } from "../../theme";
import MissionImage from "../../assets/images/about-us/mission.png";
import { Image, Text, TextHeader } from "./ui";
import SectionContainer from "../../components/layout/SectionContainer";

interface OurMissionProps {
  media: string;
  title: string;
  content: string;
}

const OurMission = (props: OurMissionProps) => {
  const { media, title, content } = props;

  return (
    <SectionContainer bg={appColors.softGray} sx={{ py: { xs: 5, md: 7 } }}>
      <Box
        display='grid'
        gap={{ xs: 4, md: 8 }}
        gridTemplateColumns={{ xs: "1fr", md: "repeat(2, 1fr)" }}
      >
        <Box>
          <Image src={media} alt='Honda Cars Batangas mission' />
        </Box>
        <Stack justifyContent='center' gap={2}>
          <TextHeader>
            <span>{title}</span>
          </TextHeader>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </Stack>
      </Box>
    </SectionContainer>
  );
}

export default OurMission;

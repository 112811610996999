import { NavLink } from "react-router-dom";
import { styled } from "@mui/system";
import { appColors } from "../../theme";

const NavLinkItem = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  color: appColors.lightGray,
  fontSize: 13,
  height: "100%",
  display: "flex",
  alignItems: "center",
  position: "relative",
  transition: "color 0.2s",

  "&::after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    height: 3,
    width: 30,
    bordeRadius: 1,
    backgroundColor: theme.palette.primary.main,
    opacity: 0,
    transition: "opacity 0.2s",
  },

  "&:hover, &.active": {
    color: theme.palette.primary.main,

    "&::after": {
      opacity: 1,
    },
  },
}));

export const VirtualShowroomNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  color: appColors.lightGray,
  fontSize: 13,
  height: "100%",
  display: "flex",
  alignItems: "center",
  transition: "all 0.2s",
  border: `2px solid ${appColors.mediumGray}`,
  padding: "8px 24px",
  justifyContent: "center",

  "&:hover": {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },

  "&.active": {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

export const MobileNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  color: appColors.lightGray,
  padding: "0 32px",
  height: 48,
  display: "flex",
  alignItems: "center",
  textTransform: "uppercase",
  fontSize: 13,

  "& > svg": {
    marginLeft: "auto",
    marginRight: -20,
  },

  "&:hover": {
    backgroundColor: appColors.blueHeader2,
  },

  "&.active": {
    backgroundColor: appColors.blueHeader2,
    color: theme.palette.primary.main,
  },
}));

export default NavLinkItem;

import { Fab, Zoom } from '@mui/material';
import UpIcon from '@mui/icons-material/ExpandLessOutlined';
import { useEffect, useState } from 'react';

const BackToTopButton = () => {
  const [shown, setShown] = useState(true);

  useEffect(() => {
    const callback = () => {
      const scrolledHeight = window.scrollY;

      setShown(() => scrolledHeight > 150);
    };

    window.addEventListener('scroll', callback);

    callback();

    return () => {
      window.removeEventListener('scroll', callback);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <Zoom in={shown}>
      <Fab
        onClick={scrollToTop}
        aria-label='scroll to top'
        size='small'
        color='primary'
        sx={{ position: 'fixed', right: '39px', bottom: '108px' }}
      >
        <UpIcon />
      </Fab>
    </Zoom>
  );
};

export default BackToTopButton;

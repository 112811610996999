import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { Status } from "../system/types";
import {
  SET_ABOUT_US_STATUS,
  LOAD_ABOUT_US_DATA,
  IAboutUs,
  AboutUsAction
} from "./types";

// Sync Actions
const setAboutUsStatus = (status: Status): AboutUsAction => ({
  type: SET_ABOUT_US_STATUS,
  payload: { status }
});

const loadAboutUsData = (data: IAboutUs[]): AboutUsAction => ({
  type: LOAD_ABOUT_US_DATA,
  payload: { data }
});

export const fetchAboutUs = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setAboutUsStatus("fetching"));

    const response = await apiClient.request<{ aboutUs: IAboutUs }>({
      url: `${apiEndpoints.aboutUs}`,
    });

    dispatch(loadAboutUsData([response.data.aboutUs]));
  } catch (err: any) {
    dispatch(setAboutUsStatus("error"));
    errorHandler(err, dispatch);
  }
};

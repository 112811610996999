import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type {AppThunk} from "../index";
import type { Status } from "../system/types";
import {
  SET_PRICELIST_STATUS,
  LOAD_PRICELIST_DATA,
  ICarPricelist,
  CarPricelistAction
} from "./types"

const setCarPricelistStatus = (status: Status): CarPricelistAction => ({
  type: SET_PRICELIST_STATUS,
  payload: { status }
});

const loadCarPricelistData = (data: ICarPricelist[]): CarPricelistAction => ({
  type: LOAD_PRICELIST_DATA,
  payload: { data }
});

export const fetchCarPricelist = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setCarPricelistStatus("loading"));

    const response = await apiClient.request<{ pricelist: ICarPricelist }>({
      url: `${apiEndpoints.pricelist}/PRICELIST::1`,
    });

    dispatch(loadCarPricelistData([response.data.pricelist]));
  } catch (err: any) {
    dispatch(setCarPricelistStatus("error"));
    errorHandler(err, dispatch);
  }
};
import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { Status } from "../system/types";
import {
  ICareersBanner,
  LOAD_CAREERS_BANNER_DATA,
  SET_CAREERS_BANNER_STATUS,
  CareersBannerAction
} from "./types";

// Sync Actions
const setCareersBannerStatus = (status: Status): CareersBannerAction => ({
  type: SET_CAREERS_BANNER_STATUS,
  payload: { status }
});

const loadCareersBannerData = (data: ICareersBanner[]): CareersBannerAction => ({
  type: LOAD_CAREERS_BANNER_DATA,
  payload: { data }
});

export const fetchCareersBanner = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setCareersBannerStatus("fetching"));

    const response = await apiClient.request<{ banner: ICareersBanner }>({
      url: `${apiEndpoints.careers}/banner/CAREER::BANNER::1`,
    });

    dispatch(loadCareersBannerData([response.data.banner]));
  } catch (err: any) {
    dispatch(setCareersBannerStatus("error"));
    errorHandler(err, dispatch);
  }
};
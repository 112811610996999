import { useEffect, useState } from "react";
import { JavascriptViewer } from "@3dweb/360javascriptviewer";

import { VirtualShowroom } from "../../../../store/virtual-showroom/types";

export default function use360Viewer(showroom: VirtualShowroom) {
  const [loadProgress, setLoadProgress] = useState(0);

  useEffect(() => {
    if (!showroom) return;

    const { exterior } = showroom;

    const imageUrls = exterior.map((i) => i.image as string);

    const viewer = new JavascriptViewer({
      mainHolderId: "jsv-holder",
      mainImageId: "jsv-image",
      imageUrls,
      speed: 100,
      defaultProgressBar: false,
      firstImageNumber: 1,
      autoRotate: 1,
    });

    viewer.events().loadImage.on((progress) => {
      setLoadProgress(progress.percentage);
    });

    viewer.events().started.on((result) => {
      // use a promise or a start event to trigger things
    });

    viewer.start().then(() => {
      // viewer.rotateDegrees(360);
    });

    const cleaup = async () => {
      try {
        await viewer.destroy();
      } catch (e) {
        //console.log(e);
      }
    };

    return () => {
      cleaup();
    };
  }, [showroom]);

  return { loadProgress };
}

import { AppError, IFeatureState, Status } from "../system/types";

export interface IPromo {
  date: any;
  id: string;
  title: string;
  mediaUrl: string;
  videoLink: string;
  dateCreated: Date;
  dateUpdated: Date;
  startDate: Date;
  endDate: Date;
  isEnabled: boolean;
  content: string;
}

export interface PromoState extends IFeatureState<IPromo> {
  sortBy: "startDate:desc" | "startDate:asc";
  allData : IPromo[];
}

export const SET_PROMO_STATUS = "promo/status/set";
export const SET_PROMO_ERROR = "promo/error/set";
export const SET_PROMO_SORTBY = "promo/sortby/set";
export const LOAD_ALL_PROMO_DATA = "promo/data/load_all";
export const LOAD_PROMO_DATA = "promo/data/load";
export const LOAD_MORE_PROMO_DATA = "promo/data/load_more";

interface SetPromoStatusAction {
  type: typeof SET_PROMO_STATUS;
  payload: { status: Status };
}

interface SetPromoErrorAction {
  type: typeof SET_PROMO_ERROR;
  payload: { error: AppError };
}

interface SetAllPromoDataAction {
  type: typeof LOAD_ALL_PROMO_DATA;
  payload: { allData: PromoState["allData"] };
}

interface SetPromoDataAction {
  type: typeof LOAD_PROMO_DATA;
  payload: { data: PromoState["data"] };
}

interface SetPromoSortByAction {
  type: typeof SET_PROMO_SORTBY;
  payload: { sortBy: PromoState["sortBy"] };
}

interface LoadMorePromoDataAction {
  type: typeof LOAD_MORE_PROMO_DATA;
  payload: { data: PromoState["data"] };
}

export type PromoAction =
  | SetPromoStatusAction
  | SetPromoErrorAction
  | SetAllPromoDataAction
  | SetPromoDataAction
  | SetPromoSortByAction
  | LoadMorePromoDataAction;

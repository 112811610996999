import { InquiryAction, InquiryState, SET_INQUIRY_ERROR, SET_INQUIRY_STATUS } from "./types";

const initialState: InquiryState = {
  status: "idle",
  error: undefined,
};

const inquiryReducer = (state = initialState, action: InquiryAction): InquiryState => {
  switch (action.type) {
    case SET_INQUIRY_STATUS:
      return { ...state, status: action.payload.status };
    case SET_INQUIRY_ERROR:
      return { ...state, error: action.payload.error, status: "error" };
    default:
      return state;
  }
};

export default inquiryReducer;

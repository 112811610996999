import { AxiosResponse } from "axios";
import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { Status } from "../system/types";
import {
  SET_GENERAL_JOB_STATUS,
  SET_GENERAL_JOB_ERROR,
  LOAD_GENERAL_JOB_DATA,
  IGeneralJob,
  GeneralJobState,
  GeneralJobAction,
} from "./types";

// Sync Actions
const setGeneralJobStatus = (status: Status): GeneralJobAction => ({
  type: SET_GENERAL_JOB_STATUS,
  payload: { status }
});

const setGeneralJobError = (error: GeneralJobState["error"]): GeneralJobAction => ({
  type: SET_GENERAL_JOB_ERROR,
  payload: { error },
});

const loadGeneralJobData = (data: IGeneralJob): GeneralJobAction => ({
  type: LOAD_GENERAL_JOB_DATA,
  payload: { data }
});

export const fetchGeneralJob = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setGeneralJobStatus("loading"));

    const response: AxiosResponse<any> = await apiClient.request({
      url: apiEndpoints.generalJob,
    });

    dispatch(loadGeneralJobData(response.data.generalJob));
  } catch (err:any) {
    dispatch(setGeneralJobStatus("error"));
    errorHandler(err, dispatch);
  }
}
import { styled } from "@mui/system";

interface Props {
  bgUrl: string;
  isActive: boolean;
}

const VSColorButton = styled("button", {
  shouldForwardProp: (prop) => prop !== "bgUrl" && prop !== "isActive",
})<Props>(({ bgUrl, isActive, theme }) => ({
  appearance: "none",
  outlineWidth: 0,
  border: "2px solid silver",
  borderRadius: "50%",
  width: 28,
  height: 28,
  backgroundImage: `url(${bgUrl})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  cursor: "pointer",
  position: "relative",

  "&::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 34,
    height: 34,
    border: `1px solid #999`,
    borderRadius: "50%",
    opacity: isActive ? 1 : 0,
  },
}));

export default VSColorButton;

import { formatPromoDate } from "../../store/promos/selectors";
import { IPromo } from "../../store/promos/types";

import { Divider, Stack, Typography } from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import { appColors } from "../../theme";
import PromoImage from "./PromoImage";

const imgGradient = `linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 80%, rgba(0,0,0,0) 100%)`;

const PromoItem = ({ item }: { item: IPromo }) => {
	return (
		<Stack
			position="relative"
			overflow="hidden"
			sx={{
				"&:hover > img": {
					transform: "scale(1.1)",
				},
			}}
		>
			<PromoImage
				sx={{ top: "100px" }}
				src={item.mediaUrl}
				alt={item.title}
				loading="lazy"
				width="100%"
				height={250}
			/>
			<Stack
				position="absolute"
				top={0}
				left={0}
				bgcolor="white"
				textAlign="center"
			></Stack>
			<Stack
				sx={{ background: imgGradient }}
				position="absolute"
				bottom={0}
				width="100%"
				px={3}
				pb={3}
			>
				<Typography
					fontSize={20}
					fontWeight={600}
					color="white"
					mb={0.5}
					lineHeight={1.2}
				>
					{item.title}
				</Typography>
				<Divider sx={{ bgcolor: `${appColors.mediumGray}` }} />
				<Typography
					sx={{
						color: "white",
						justifyContent: "flex-start",
						display: "flex",
						alignItems: "center",
						top: "12px",
						position: "relative",
					}}
				>
					<CampaignIcon
						sx={{ mr: "10px", alignItems: "center", marginBottom: "-2px" }}
					/>
					{formatPromoDate(item.startDate, item.endDate)}
				</Typography>
			</Stack>
		</Stack>
	);
};

export default PromoItem;

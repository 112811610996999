import { Box } from "@mui/material";
import { Route, Switch } from "react-router-dom";
import NewsAndEventDetail from "./NewsAndEventDetail";

import NewsAndEventsListView from "./NewsAndEventsListView";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SectionContainer from "../../components/layout/SectionContainer";
import NewsAndEventsBanner from "./NewsAndEventsBanner";

const queryClient = new QueryClient();

const NewsAndEventsPage = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <NewsAndEventsBanner/>
      <SectionContainer>
        <Box minHeight='85vh'>
          <Switch>
            <Route path='/news-and-events/:id'>
              <NewsAndEventDetail />
            </Route>
            <Route path='/news-and-events'>
              <NewsAndEventsListView />
            </Route>
          </Switch>
        </Box>
      </SectionContainer>
    </QueryClientProvider>
  );
};

export default NewsAndEventsPage;

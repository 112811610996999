import { BrowserRouter } from "react-router-dom";
import { useAllData } from "./hooks/useAllData";

import Footer from "./components/layout/Footer";
import NavBar from "./components/layout/NavBar";
import Trunkline from "./components/layout/Trunkline";
import ContentPage from "./modules";
import MessengerChat from "./components/common/Messenger";

const App = () => {
  useAllData();

  return (
    <BrowserRouter>
      <Trunkline />
      <NavBar />
      <ContentPage />
      <Footer />
      <MessengerChat />
    </BrowserRouter>
  );
};

export default App;

import { ExpandMore } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";

const LoadMoreButton = ({ loading, ...props }: ButtonProps & { loading: boolean }) => {
  return (
    <Button
      {...props}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      size='large'
      fullWidth
      endIcon={loading ? undefined : <ExpandMore />}
      variant='outlined'
      sx={{
        textTransform: "unset",
        border: "1.8px solid #333",
        borderRadius: 0,
        color: "#333",
        my: 3,
        height: 44,

        "&:hover": {
          border: "1.8px solid primary.main",
          bgcolor: "primary.main",
          color: "white",
        },
      }}
    >
      {loading ? "Loading..." : "Load more"}
    </Button>
  );
};

export default LoadMoreButton;

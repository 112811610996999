import { ChevronRight } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { ICareers } from "../../store/careers/types";
import RedLink from "../../components/lib/RedLink";
import { appColors } from "../../theme";

interface CareerItemProps {
  career: ICareers;
}

const CareerItem = ({ career }: CareerItemProps) => {
  return (
    <Stack
      py={2}
      px={3}
      bgcolor={appColors.hondaLight}
      direction='row'
      justifyContent='space-between'
    >
      <Typography fontWeight={600} maxWidth='50%'>
        {career.name}
      </Typography>
      <RedLink to={`/careers/${career.id}`}>
        More Details
        <ChevronRight fontSize='small' />
      </RedLink>
    </Stack>
  );
};

export default CareerItem;

import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Stack, Typography } from "@mui/material";
import { appColors } from "../../../theme";
import { fetchPromo } from "../../../store/promos/actions";
import { formatPromoDate, selectLatestPromo } from "../../../store/promos/selectors";
import { LatestPromoContainer } from "./LatestPromoPageComponents";
import WebOutlinedButton from "../../../components/common/WebOutlinedButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const LatestPromoPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const promo = useSelector(selectLatestPromo);

  useEffect(() => {
    dispatch(fetchPromo());
  }, [dispatch]);

  const promoPeriod = formatPromoDate(promo?.startDate, promo?.endDate);

  const goToPromos = () => history.push("/promos");

  return (
    <LatestPromoContainer>
      <Grid
        container
        gap={{ xs: 4, md: 0 }}
        mx='auto'
        px={{ xs: 0, md: 3 }}
        maxWidth={(theme) => theme.breakpoints.values.lg}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Stack height='100%' justifyContent='center' pr={2}>
            <Typography color={appColors.colorText}>Our Latest Promo</Typography>
            <Typography fontSize={{ xs: 28, md: 46 }} fontWeight='bold' lineHeight={1.2}>
              {promo?.title}
            </Typography>
            <Typography fontSize={14} color={appColors.colorText}>
              {promoPeriod}
            </Typography>
            <WebOutlinedButton
              sx={{ alignSelf: "start", mt: 4, display: { xs: "none", md: "flex" } }}
              currentColor='white'
              endIcon={<ArrowForwardIcon />}
              onClick={goToPromos}
            >
              More Details
            </WebOutlinedButton>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Stack width='100%'>
            <img
              src={promo?.mediaUrl}
              style={{ aspectRatio: "16 / 9", borderRadius: 12 }}
              alt={promo?.title}
            />
          </Stack>
          <WebOutlinedButton
            sx={{ mt: 4, display: { xs: "flex", md: "none" } }}
            fullWidth
            currentColor='white'
            endIcon={<ArrowForwardIcon />}
            onClick={goToPromos}
          >
            More Details
          </WebOutlinedButton>
        </Grid>
      </Grid>
    </LatestPromoContainer>
  );
};

export default LatestPromoPage;

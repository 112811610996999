import type { AppError, IFeatureState, Status } from "../system/types";

export interface IServicesBanner {
  id: string;
  tagline: string;
  media: string;
  dateUpdated: string;
}

export type ServicesBannerState = IFeatureState<IServicesBanner>;
export const SET_SERVICES_BANNER_STATUS = "services/banner/status/set";
export const SET_SERVICES_BANNER_ERROR = "services/banner/error/set";
export const LOAD_SERVICES_BANNER_DATA = "services/banner/data/load";

interface SetServicesBannerStatusAction {
  type: typeof SET_SERVICES_BANNER_STATUS;
  payload: { status: Status };
}

interface SetServicesBannerErrorAction {
  type: typeof SET_SERVICES_BANNER_ERROR;
  payload: { error: AppError };
}

interface SetServicesBannerDataAction {
  type: typeof LOAD_SERVICES_BANNER_DATA;
  payload: { data: ServicesBannerState["data"] };
}

export type ServicesBannerAction = 
  | SetServicesBannerStatusAction
  | SetServicesBannerErrorAction
  | SetServicesBannerDataAction

import { SxProps, styled } from "@mui/system";
import { Button } from "@mui/material";
import { appColors } from "../../../theme";

export const DialogButtons = styled(Button)({
  height: "50px",
  width: "200px",
  borderRadius: "0px",
});

export const DialogContentStyle: SxProps = {
  backgroundColor: appColors.dark,
  padding: "20px 40px 20px 40px",
};

export const LinkColor: SxProps = {
  color: "primary.main",
};

export const HighlightedText: SxProps = {
  color: appColors.white,
};

export const DialogActionsStyle: SxProps = {
  backgroundColor: appColors.dark,
  justifyContent: "center",
  paddingBottom: "40px",
  gap: "10px",
};

export const BoxStyle: SxProps = {
  boxSizing: "content-box",
};

export const EmailLink = styled("span")(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: "10px",
  textDecoration: "underline",
}));

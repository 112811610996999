import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { promoFetcher } from "../../store/promos/actions";
import { selectPromoState } from "../../store/promos/selectors";
import { orderBy } from "lodash";
import { useQuery } from "@tanstack/react-query";

import { Box, Typography } from "@mui/material";
import PromoItem from "./PromoItem";
import LoadMoreButton from "../../components/common/LoadMoreButton";
import FetchingLoadingIndicator from "../../components/common/FetchingLoadingIndicator";

const PromoList = () => {
  const [page, setPage] = useState(1);
  const { sortBy } = useSelector(selectPromoState);

  const { isLoading, isFetching, isPreviousData, data } = useQuery(
    [page],
    async () => await promoFetcher(page),
    { keepPreviousData: true }
  );

  if (isLoading) return <FetchingLoadingIndicator />;

  const [property, order] = sortBy.split(":");
  const sortedData = orderBy(data?.validPromos, [property], [order as "asc" | "desc"]);
  const promoCount = data?.totalPromos;
  const totalPages = promoCount && Math.ceil(promoCount / 6);

  if (promoCount === 0) {
    return (
      <Typography textAlign="center" mt={3} fontWeight={600}>
          No available data as of the moment.
      </Typography>
    )
  }

	return (
		<>
			<Box
        display="grid"
        gap={3}
        sx={{
          gridTemplateColumns: {
            xs: "1fr",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          },
        }}
      >
        {sortedData.map((item) => (
          <Link key={item.id} to={`/promos/${item.id}`}>
            <PromoItem item={item} />
          </Link>
        ))}
      </Box>
      { promoCount && 
        promoCount > 6 && totalPages !== page &&
        !isFetching && 
        <LoadMoreButton
          loading={isFetching}
          onClick={() => {
            if (!isPreviousData) {
              setPage((old) => old + 1);
            }
          }}
        />
      }
      { isFetching && <FetchingLoadingIndicator /> }
		</>
	);
};

export default PromoList;

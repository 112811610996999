import {
  SET_PROMO_BANNER_STATUS,
  LOAD_PROMO_BANNER_DATA,
  PromoBannerAction,
  PromoBannerState
} from "./types";

const initialState: PromoBannerState = {
  status: "idle",
  error: undefined,
  data: [],
};

const promoBannerReducer = (state = initialState, action: PromoBannerAction): PromoBannerState => {
  switch (action.type) {
    case SET_PROMO_BANNER_STATUS:
      return { ...state, status: action.payload.status };
    case LOAD_PROMO_BANNER_DATA:
      return { ...state, data: action.payload.data, status: "idle" };
    default:
      return state;
  }
};

export default promoBannerReducer;
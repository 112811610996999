import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logOutCustomer } from "../../store/auth/actions";
import { getAuthCustomer } from "../../store/auth/selectors";

import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { appColors } from "../../theme";

const AccountMenu = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const auth = useSelector(getAuthCustomer);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);
  const handleSignOut = () => dispatch(logOutCustomer());

  return (
    <React.Fragment>
      <Tooltip title='Account'>
        <Button
          onClick={handleClick}
          sx={{ textTransform: "none", color: appColors.dark, padding: 0 }}
        >
          <Stack direction='row' alignItems='center' gap={2}>
            <Box>
              <Typography
                sx={{ fontSize: "17px", fontWeight: "600", lineHeight: 1, textAlign: "left" }}
              >
                {auth?.firstName} {auth?.lastName}
              </Typography>
              <Typography>{auth?.cardNumber}</Typography>
            </Box>
            <ExpandMore sx={{ color: (theme) => theme.palette.primary.main }} />
          </Stack>
        </Button>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        elevation={1}
        PaperProps={{
          sx: { width: 240, bgcolor: "white", paddingX: 3, paddingY: 2 },
        }}
      >
        <Button
          variant='outlined'
          sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            color: "#fff",
            width: "100%",
            ":hover": {
              color: (theme) => theme.palette.primary.main,
            },
          }}
          onClick={handleSignOut}
        >
          Sign Out
        </Button>
      </Menu>
    </React.Fragment>
  );
};

export default AccountMenu;

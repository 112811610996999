import { useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { fetchModels } from "../../store/models/actions";
import { modelOptions } from "../../store/models/selectors";

import ErrorModal from "../../components/common/ErrorModal";
import SuccessModal from "../../components/common/SuccessModal";
import WebInput from "../../components/common/WebInput";
import WebSelect from "../../components/common/WebSelect";
import WebPrimaryButton from "../../components/common/WebPrimaryButton";
import AppUploadButton from "./AppUploadButton"
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import useInquiryForm from "./useInquiryForm";
import { setInquiryStatus } from "../../store/inquiry/actions";

const UploadButton = styled("span")(({ theme }) => ({
  backgroundColor: "transparent", 
  border: "1px solid", 
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  padding: "5px",
  borderRadius: 5,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  transition: "transform 0.3s ease-in",
  marginLeft: "2px"
}));

const InquiryForm = () => {
  const { control, register, errors, submitHandler, submitStatus, serverError, setValue, getValues } =
    useInquiryForm("Parts and Accessories");  

  const dispatch = useDispatch();
  const [attachmentName, setAttachmentName] = useState<string | null>("");
  const handleClose = () => {
    dispatch(setInquiryStatus("idle"));
  };

  const options = useSelector(modelOptions);

  useEffect(() => {
    dispatch(fetchModels());
  }, [dispatch]);

  return (
    <>
      <SuccessModal
        open={submitStatus === "success"}
        message="Thank you for your inquiry. Our Team will contact you as soon as possible."
        moduleName="Parts and Accessories"
        callback={handleClose}
      />
      <ErrorModal
        open={submitStatus === "error"}
        message={serverError}
        callback={handleClose}
      />
      <Typography variant="h4" fontWeight={900}>
        Parts Inquiry
      </Typography>
      <Stack component="form" mt={1.7} gap={3} mb={8} onSubmit={submitHandler}>
        <Stack gap={2}>
          <Stack sx={{ flexDirection: { xs: "column", md: "row" }, gap: 2 }}>
            <WebInput
              fullWidth
              placeholder="First Name"
              {...register("firstName")}
              error={!!errors?.firstName}
              helperText={errors?.firstName?.message}
            />
            <WebInput
              fullWidth
              placeholder="Last Name"
              {...register("lastName")}
              error={!!errors?.lastName}
              helperText={errors?.lastName?.message}
            />
          </Stack>
          <WebInput
            type="email"
            placeholder="Email"
            {...register("email")}
            error={!!errors?.email}
            helperText={errors?.email?.message}
          />
          <WebInput
            placeholder="Phone number"
            {...register("phoneNumber")}
            error={!!errors?.phoneNumber}
            helperText={errors?.phoneNumber?.message}
          />
          <Controller
            control={control}
            name='model'
            render={({ field }) => (
              <WebSelect
                label='Model'
                options={options}
                {...field}
                placeholder='Select Model'
                {...register("model")}
                error={!!errors?.model}
                helperText={errors?.model?.message}
              />
            )}
          />
          <WebInput
            placeholder="Chassis Number"
            {...register("chassisNumber")}
            error={!!errors?.chassisNumber}
            helperText={errors?.chassisNumber?.message}
          />
          <WebInput
            placeholder='Attach Photos'
            value={attachmentName && getValues("mediaFile") ? attachmentName : ""}
            InputProps={{
              endAdornment:
              <>
                <Controller
                  name='mediaFile'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { name, ref } }) => (
                    <>
                      <AppUploadButton
                        onChange={(e) => {
                          if (e.currentTarget.files?.item(0) === null) return;

                          setValue("mediaFile", e.currentTarget.files?.item(0) || null);
                          setAttachmentName(e.currentTarget.files?.item(0)?.name || null)
                          e.target.value = "";
                        }}
                        ref={ref}
                        name={name}
                      />
                      { attachmentName && getValues("mediaFile") &&
                        <UploadButton 
                          onClick={() => {
                            setValue("mediaFile", null);
                            setAttachmentName("")
                          }}
                        >
                          <RemoveCircleOutlineIcon sx={{ color: theme => theme.palette.primary.main, fontSize: 13 }} />
                        </UploadButton>
                      }
                    </>
                  )}
                />
              </>
            }}
          />
          <WebInput
            multiline
            rows={4}
            placeholder="Comment or Message"
            {...register("comment")}
            error={!!errors?.comment}
            helperText={errors?.comment?.message}
          />
          <WebPrimaryButton
            type="submit"
            loading={submitStatus === "submitting"}
            sx={{ alignSelf: "start" }}
          >
            Submit
          </WebPrimaryButton>
        </Stack>
      </Stack>
    </>
  );
};

export default InquiryForm;

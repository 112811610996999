import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { filterModels, selectModelListState } from '../../store/models/selectors';
import { selectModelVariants } from '../../store/models/selectors';
import { setModelVariants } from '../../store/models/actions';
import { selectCarPriceListData } from "../../store/pricelist/selectors";

import { ModelFilters } from '../../utils/constants';
import { Stack, Typography, Box } from '@mui/material';
import ModelItem from './ModelItem';
import LoopIcon from '@mui/icons-material/Loop';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import modelSchema from './compare-models/fragments/modelSchema.json';
import SectionTitle from '../../components/common/SectionTitle';
import WebOutlinedButton from '../../components/common/WebOutlinedButton';
import { WebFilterTabItem, WebFilterTabs } from '../../components/common/WebFilterTab';
import SectionContainer from '../../components/layout/SectionContainer';
import FetchingLoadingIndicator from '../../components/common/FetchingLoadingIndicator';
import WebPrimaryButton from "../../components/common/WebPrimaryButton";
import Meta from '../../components/common/Meta';

const ModelList = () => {
  const [activeModelType, setActiveModelType] = useState<string>('All');
  const handleFilterChange = (id: string) => setActiveModelType(id);
  const data = useSelector(filterModels(activeModelType));
  const models = useSelector(selectModelVariants);
  const pricelist = useSelector(selectCarPriceListData);
  const dispatch = useDispatch();
  const history = useHistory();
  const { status } = useSelector(selectModelListState);

  const handleChangeRoute = () => {
    history.push('/models/compare-models');

    models.selectedModels = [modelSchema, modelSchema, modelSchema];
    models.selectedModelIndex = -1;
    models.selectedVariants = [
      {
        name: '',
        performance: [],
        summary: { content: '', price: '' },
        specs: [{ category: '', items: [] }],
      },
    ];

    dispatch(setModelVariants({ ...models }));
  };

  return (
    <SectionContainer>
      <Meta title='Models' />
      <Stack display='flex' direction={{ xs: 'column', sm: 'row' }} gap={3} alignItems='center' justifyContent='space-between'>
        <SectionTitle title='Models' />
        <Stack direction={{ xs: 'column', sm: 'row' }} gap={2}>
          <a href={pricelist[0]?.pricelistLinkUrl} target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
            <WebPrimaryButton startIcon={<FileDownloadIcon />}>
              Car Price list
            </WebPrimaryButton>
          </a>
          <WebOutlinedButton onClick={handleChangeRoute} startIcon={<LoopIcon />}>
            Compare Models
          </WebOutlinedButton>
        </Stack>
      </Stack>
      <WebFilterTabs>
        {ModelFilters.map((m) => (
          <WebFilterTabItem
            key={m.id}
            className={activeModelType === m.type ? 'active' : ''}
            onClick={() => handleFilterChange(m.type)}
          >
            {m.type}
          </WebFilterTabItem>
        ))}
      </WebFilterTabs>
      {status === 'loading' ? (
        <FetchingLoadingIndicator />
      ) : (
        <Stack gap={1.7} flexDirection='row' width='100%' flexWrap='wrap' mt={5}>
          {data.length > 0 ? (
            data.map((model) => (
              <Box key={model.id} sx={{ width: { xs: '47%', sm: '48%', md: '32%' } }}>
                <Link to={`/models/${model.id}`} style={{ textDecoration: 'none' }}>
                  <ModelItem model={model} />
                </Link>
              </Box>
            ))
          ) : (
            <Typography sx={{ margin: '0 auto' }} pt={10} fontSize={17} fontWeight={500}>
              No available Models for this type as of the moment.
            </Typography>
          )}
        </Stack>
      )}
    </SectionContainer>
  );
};

export default ModelList;

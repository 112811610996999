import { styled } from "@mui/system";

const LightGrayContainer = styled("div")(({ theme }) => ({
  width: "100vw",
  backgroundColor: "#f4f4f4",
  position: "absolute",
  top: "45%",
  bottom: 0,
  left: "50%",
  right: "50%",
  zIndex: -1,
  marginLeft: "-50vw",
  marginRight: "-50vw",

  [theme.breakpoints.down("sm")]: {
    top: "34%",
  },
}));

export default LightGrayContainer;

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { setPartsAndAccessoriesFilter } from "../../../store/parts-and-accessories/actions";
import { 
  selectPartsAndAccessoriesData,
  selectPartsAndAccessoriesListState 
} from "../../../store/parts-and-accessories/selectors";

export default function usePartsAndAccessoriesState() {
  const dispatch = useDispatch();
  const { status, error, filter } = useSelector(selectPartsAndAccessoriesListState);
  const { category, reloadToken } = useParams<{ category: typeof filter, reloadToken: string }>();

  useEffect(()=>{ 
    if(category !== undefined && reloadToken !== undefined){
      dispatch(setPartsAndAccessoriesFilter(category));
    }
    else{
      dispatch(setPartsAndAccessoriesFilter("All"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[category, reloadToken]);

  const data = useSelector(selectPartsAndAccessoriesData);

  const onFilterChange = (fil: typeof filter) => dispatch(setPartsAndAccessoriesFilter(fil));

  const filteredData = filter === null ? data : data.filter((d) => d.type === filter);

  return { status, error, filteredData, onFilterChange, filter };
}
import { Stack, Typography } from "@mui/material";
import { ModelColorSelection } from "../../../store/models/types";
import VSColorButton from "./VSColorButton";

interface VSColorSelectProps {
  modelColors: ModelColorSelection[];
  currentColorIndex: number;
  onChange: (colorIndex: number) => void;
  hidden: boolean;
}

const VSColorSelect = ({
  modelColors,
  currentColorIndex,
  onChange,
  hidden,
}: VSColorSelectProps) => {
  return (
    <Stack
      id='vs-color-select'
      alignItems='center'
      gap={2}
      mt={{ xs: -2, md: -10 }}
      sx={{ transition: "opacity 0.3s linear", opacity: hidden ? 0 : 1 }}
    >
      <Stack direction='row' alignItems='center' gap={2}>
        {modelColors.map((color, colorIdx) => (
          <VSColorButton
            key={color.colorName}
            bgUrl={color.colorPreviewUrl}
            onClick={() => onChange(colorIdx)}
            isActive={colorIdx === currentColorIndex}
          />
        ))}
      </Stack>
      <Typography
        fontSize={{ xs: 18, md: 24 }}
        fontWeight='600'
        textAlign='center'
        sx={{ zIndex: 2 }}
      >
        {modelColors[currentColorIndex].colorName}
      </Typography>
    </Stack>
  );
};

export default VSColorSelect;

import { Stack } from "@mui/material";
import SectionTitle from "../../components/common/SectionTitle";
import PromoList from "./PromoList";
import PromoSort from "./PromoSort";
import PromoSubHeader from "./PromoSubHeader";

const PromoListView = () => {
  return (
    <>
      <SectionTitle title='Promotions' />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ md: "center" }}
        justifyContent='space-between'
        sx={{ marginBottom: { xs: "20px", sm: "40px" } }}
      >
        <PromoSubHeader />
        <PromoSort />
      </Stack>
      <PromoList />
    </>
  );
};

export default PromoListView;

import {
  SET_QUICK_LINKS_STATUS,
  LOAD_QUICK_LINKS_DATA,
  QuickLinksState,
  QuickLinksAction
} from "./types";

const initialState: QuickLinksState = {
  status: "idle",
  error: undefined,
  data: []
}

const quickLinksReducer = (state = initialState, action: QuickLinksAction): QuickLinksState => {
  switch (action.type) {
    case SET_QUICK_LINKS_STATUS:
      return { ...state, status: action.payload.status }
    case LOAD_QUICK_LINKS_DATA:
      return { ...state, data: action.payload.data, status: "idle" }
    default:
      return state;
  }
}

export default quickLinksReducer;
import { useLocation, useHistory, Link } from "react-router-dom";
import { Container, Divider, Stack, Typography } from "@mui/material";
import SocialIconLink from "../lib/SocialIconLink";
import { styled } from "@mui/system";
import { Box } from "@mui/system";
import { appColors } from "../../theme";
import logo from "../../assets/images/login-logo.png";

const year = new Date().getFullYear();

//|| location.pathname.includes("virtual-showroom")

export const DataPrivacy = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: appColors.lightGray,
  cursor: "pointer",
  
  "&:hover": {
    color: theme.palette.primary.main,
  }
}));

const Footer = () => {
  const history = useHistory();
  const location = useLocation();
  if (location.pathname === "/hcbt-elite" || location.pathname.includes("virtual-showroom")) return null;

  return (
    <Box component='footer' bgcolor={appColors.blueHeader}>
      <Container maxWidth='lg'>
        <Stack
          py={{ xs: 6, md: 9 }}
          px={{ xs: 4, md: 0 }}
          color='white'
          direction={{ xs: "column", md: "row" }}
          gap={{ xs: 4, md: 0 }}
          borderBottom={`1px solid ${appColors.mediumGray}`}
        >
          <Stack direction={{ xs: "column", md: "row" }}  gap={5}>
            <Stack gap={4}>
              <img src={logo} alt='Honda Cars Batangas' style={{ height: 32, width: 200 }} />
              <Stack>
                <Typography fontSize={20} fontWeight='bolder'>
                  Honda Cars Batangas
                </Typography>
                <Typography fontSize={14} color={appColors.lightGray}>
                  Diversion Road, Bolbok, Batangas City
                </Typography>
              </Stack>
            </Stack>
            <Stack direction='column' alignItems="start" gap={2}>
              <Typography>Stay Connected</Typography>
              <Stack direction="row" gap={1}>
                <SocialIconLink 
                  name='facebook' 
                  link='https://www.facebook.com/hondacarsbatangas/' 
                />
                <SocialIconLink
                  name='instagram'
                  link='https://www.instagram.com/honda_cars_batangas/?hl=en'
                />
                <SocialIconLink
                  name='youtube'
                  link='https://www.youtube.com/channel/UCKkNfeZJBMjeATsZEOP8DJw'
                />
              </Stack>
              <Stack direction="row" gap={1}>
                <SocialIconLink
                    name='viber'
                    link='https://invite.viber.com/?g2=AQAzm9awnxuJMk5CKc22m5WrevURnYc5AJW96arf2BU1%2B7Jpr0E8Pxjc9xAJXRxP&lang=en'
                  />
                  <SocialIconLink
                    name='linkedin'
                    link='https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F90434514%2Fadmin%2F%3Ffbclid%3DIwAR1NAyHdlqi-BHBqCJsRWwa_cRkuQ8Uw6gL_IRDb5YGUnf2ozjlpmpcgZi8&h=AT02nSpx-2-ENCOSd7plrWapqLasLb8WH9rZX_LtaHtAOwKLIBReDdovHRXGi3bdTtDvmHVo8TrOatpQmt4L0xH7H73DTnojSc2RekHOyVgfXXg_1RtRY9qhk2WeS6hGxgI'
                  />
                  <SocialIconLink
                    name='tiktok'
                    link='https://www.tiktok.com/@hondacarsbatangas?is_from_webapp=1&sender_device=pc'
                  />
                </Stack>
            </Stack>
          </Stack>  
          <Stack ml={{ xs: 0, md: "auto" }} gap={1}>
            <Typography fontSize={20} fontWeight='bolder' sx={{ textDecoration: "underline" }}>
              Contact us
            </Typography>
            <Stack gap={1} color={appColors.lightGray} sx={{ "& p": { fontSize: 14 } }}>
              <Stack direction='row' alignItems='center' gap={{ xs: 1, md: 2 }} flexWrap='wrap'>
                <Typography color='white'>Trunkline:</Typography>
                <Stack direction='row' alignItems='center' gap={2}>
                  <Typography>(043) 702-9376</Typography>
                  <Divider
                    orientation='vertical'
                    sx={{
                      bgcolor: appColors.mediumGray,
                      height: 22,
                    }}
                  />
                  <Typography>(043) 702-9377</Typography>
                  <Divider
                    orientation='vertical'
                    sx={{
                      bgcolor: appColors.mediumGray,
                      height: 22,
                    }}
                  />
                  <Typography>(043) 402-0355</Typography>
                </Stack>
              </Stack>
              <Stack display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={1} justifyContent={{ sm: "start", md: "space-between" }}>
                <Box display="flex" flexDirection="row">
                  <Typography color='white' mr={1}>Sales:</Typography>
                  <Typography>Local 109 | 09458205547</Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Typography color='white' mr={1}>Service:</Typography>
                  <Typography>Local 146 | 09178277560</Typography>
                </Box>
              </Stack>
              <Stack display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={1} justifyContent={{ sm: "start", md: "left" }}>
                <Box display="flex" flexDirection="row">
                  <Typography color='white' mr={1}>Parts:</Typography>
                  <Typography>Local 123 | 09451142238</Typography>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Typography color='white' mr={1}>Customer Service:</Typography>
                  <Typography>Local 143</Typography>
                </Box>
              </Stack>
              <Box display="flex" flexDirection="row">
                <Typography color='white' mr={1}>Insurance:</Typography>
                <Typography>09176578092</Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          px={{ xs: 4, md: 0 }}
          py={3}
          direction={{ xs: "column", md: "row" }}
          alignItems='center'
          justifyContent='space-between'
          gap={3}
        >
          <Typography
            fontSize={14}
            color={appColors.lightGray}
            textAlign={{ xs: "center", md: "left" }}
          >
            Copyright {year} Honda Cars Batangas | All Rights Reserved
          </Typography>
          <DataPrivacy onClick={() => history.push("/data-privacy")}>
            Data Privacy
          </DataPrivacy>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;

import { useState } from 'react';
import { ITestimonialData } from "../../../store/testimonial/types";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Rating from '@mui/material/Rating';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import { appColors } from "../../../theme/index";
import {
  testimonialContainer,
  imageContainer,
  userDataContainer,
  contentContainer,
  Image,
  imageModal
} from "./TestimonialStyle";
import QuatationIcon from "../../../assets/images/Quote Icon.png";
import { hideName } from "../../../utils/anonymous-name";

interface TestimonialsItemProps {
  index: number;
  testimonials: ITestimonialData;
  count: number;
}

const TestimonialItem = ({ index, testimonials, count }: TestimonialsItemProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Stack key={index} sx={testimonialContainer} spacing={2}>
        <Box>
          <Box sx={imageContainer}>
            <img src={QuatationIcon} alt='Testimonials' />
          </Box>
          <Box sx={contentContainer}>
            <Typography fontWeight={300} color={appColors.dark}>
              {testimonials.content}
            </Typography>
            { testimonials.media &&  
              <Box sx={{ marginTop: 2 }} onClick={handleOpen}>
                <Image src={testimonials.media} />
              </Box>
            }
          </Box>
          <Divider sx={{ marginX: "10px", marginTop: "20px" }} />
          <Box sx={userDataContainer}>
            { testimonials.rating &&
              <Rating name="read-only" value={testimonials.rating} sx={{ fontSize: 14 }} readOnly />
            }
            <Typography fontWeight={600} color={appColors.dark}>
              {
                testimonials.anonymous !== undefined && testimonials.anonymous === true 
                ? `${hideName(testimonials.fName)}  ${hideName(testimonials.lName)}`
                : testimonials.fName + " " + testimonials.lName
              }
            </Typography>
            <Typography fontWeight={300} fontSize={13} color={appColors.mediumGray}>
              {testimonials?.address}
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={imageModal}>
          <Image src={testimonials.media} sx={{ boxShadow: 24  }} />
        </Box>
      </Modal>
    </>
  );
};

export default TestimonialItem;
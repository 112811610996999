import { AppError, IFeatureState, Status } from "../system/types";

export type ICareers = {
    id:string;
    name:string;
    content:string;
};

export type CareerListState = IFeatureState<ICareers>;

export const SET_CAREERS_STATUS = "careers/status/set";
export const SET_CAREERS_ERROR = "careers/error/set";
export const LOAD_CAREERS_DATA = "careers/data/load";

interface SetCareerListStatusAction {
    type: typeof SET_CAREERS_STATUS;
    payload: { status: Status };
  };

  interface SetCareerListErrorAction {
    type: typeof SET_CAREERS_ERROR;
    payload: { error: AppError };
  };
  
  interface SetCareerListDataAction {
    type: typeof LOAD_CAREERS_DATA;
    payload: { data: CareerListState["data"] };
  };

export type CareerListAction = 
    | SetCareerListStatusAction
    | SetCareerListErrorAction
    | SetCareerListDataAction;

import { useState } from "react";
import { useDispatch } from "react-redux";
import { Controller } from "react-hook-form";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Rating from '@mui/material/Rating';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

import ErrorModal from "../../../components/common/ErrorModal";
import SuccessModal from "../../../components/common/SuccessModal";
import WebDialog from "../../../components/common/WebDialog";
import WebInput from "../../../components/common/WebInput";
import WebPrimaryButton from "../../../components/common/WebPrimaryButton";
import WebUploadButton from "../../../components/common/WebUploadButton";
import ImagePreview from "../../../components/common/ImagePreview";
import useTestimonialForm from "../../../hooks/useTestimonialForm";
import { setTestimonialStatus } from "../../../store/testimonial/actions";
import useMediaPreview from "../../../hooks/useMediaPreview";

const NameSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.text.primary[600],
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.text.primary[600],
  },
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

const TestimonialForm = ({ onClose }: { onClose: () => void }) => {
  const { mediaPreview, fileName, setMedia } = useMediaPreview();
  const [rating, setRating] = useState<number>(0);
  const [anonymous, setIsAnonymous] = useState<boolean>(false);

  const { register, submitHandler, errors, disabled, submitStatus, serverError, control, setValue  } =
    useTestimonialForm();

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setTestimonialStatus("idle"));
    onClose();
  };

  if (submitStatus === "success") {
    return (
      <SuccessModal open message='Your testimonial has been submitted!' callback={handleClose} />
    );
  }

  if (submitStatus === "error") {
    return <ErrorModal open message={serverError} callback={handleClose} />;
  }

  return (
    <WebDialog open onClose={onClose} title='Customer Testimonial'>
      <Stack component='form' gap={2.2} px={{ xs: 1, md: 4 }} mb={4} onSubmit={submitHandler}>
        <Stack direction={{ xs: "column", md: "row" }} gap={2.2}>
          <WebInput
            {...register("fName")}
            placeholder='First Name'
            fullWidth
            error={!!errors?.fName}
            helperText={errors?.fName?.message}
          />
          <WebInput
            {...register("lName")}
            placeholder='Last Name'
            fullWidth
            error={!!errors?.lName}
            helperText={errors?.lName?.message}
          />
        </Stack>
        <WebInput
          {...register("address")}
          placeholder='Address'
          error={!!errors?.address}
          helperText={errors?.address?.message}
        />
        <WebInput
          {...register("content")}
          placeholder='Comment or Message'
          multiline
          rows={4}
          error={!!errors?.content}
          helperText={errors?.content?.message}
        />
        <Box
            sx={{
              '& > legend': { 
                mb: 1,
                fontSize: 14 
              },
            }}
          >
            <Controller
              name="rating"
              control={control}
              defaultValue={0}
              render={() => (
                <>
                    <Typography component="legend">Rating</Typography>
                    <Rating
                      name="simple-controlled"
                      value={rating}
                      onChange={(e, value) => {
                        setRating(value !== null ? value : 0);
                        setValue("rating", value !== null ? value : 0)
                      }}
                    />
                </>
              )}
            />    
          </Box>
          <Controller
            name="anonymous"
            control={control}
            render={() => (
              <Stack direction="column">
                <Typography fontSize={14}>
                  Hide name on your testimonial
                </Typography>
                <NameSwitch 
                  {...label}  
                  value={anonymous} 
                  onChange={(e, newValue) => {
                    setIsAnonymous(newValue)
                    setValue("anonymous", newValue)
                  }}
                  sx={{
                    
                  }}
                />
              </Stack>
            )}
          />
        <Stack gap={1.5}>
          <ImagePreview src={mediaPreview} alt={fileName} />
          <Controller
            name='mediaFile'
            control={control}
            rules={{ required: true }}
            render={({ field: { name, ref } }) => (
              <WebUploadButton
                onChange={(e) => {
                  if (e.currentTarget.files?.item(0) === null) return;
                  
                  setValue("mediaFile", e.currentTarget.files?.item(0) || null);
                  setMedia(e.currentTarget.files?.item(0) || null);
                }}
                fileName={fileName}
                ref={ref}
                name={name}
              />
            )}
          />
        </Stack>
        <WebPrimaryButton type='submit' disabled={disabled} sx={{ alignSelf: "center", mt: 3 }}>
          Submit
        </WebPrimaryButton>
      </Stack>
    </WebDialog>
  );
};

export default TestimonialForm;

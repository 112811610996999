import { Stack } from "@mui/material";
import { WebFilterTabItem } from "../../../components/common/WebFilterTab";
import useMeasure from "../../../hooks/useMeasure";

interface VSTabsProps {
  show: boolean;
  currentView: "exterior" | "interior";
  onChange: (view: "exterior" | "interior") => void;
}

const VSTabs = ({ show, currentView, onChange }: VSTabsProps) => {
  const pageTitleBounds = useMeasure("#vs-page-title");

  const topOffset = pageTitleBounds ? pageTitleBounds.height + 40 : 0;

  return (
    <Stack
      bgcolor='rgba(255,255,255,0.2)'
      direction='row'
      position='absolute'
      top={{ xs: topOffset, md: 32 }}
      left='50%'
      sx={{
        transform: "translateX(-50%)",
        transition: "opacity 0.3s linear",
        opacity: show ? 1 : 0,
        "& > button": {
          bgcolor: "transparent",
          color: currentView === "exterior" ? "#aaa" : "#222",
        },
      }}
      pt={1.5}
      pb={2}
      px={{ xs: 2, md: 4 }}
      borderRadius={2}
      gap={3}
    >
      <WebFilterTabItem
        className={currentView === "exterior" ? "active" : ""}
        onClick={() => onChange("exterior")}
      >
        Exterior
      </WebFilterTabItem>
      <WebFilterTabItem
        className={currentView === "interior" ? "active" : ""}
        onClick={() => onChange("interior")}
      >
        Interior
      </WebFilterTabItem>
    </Stack>
  );
};

export default VSTabs;

import { ChevronLeft } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import FetchingLoadingIndicator from '../../components/common/FetchingLoadingIndicator';
import Meta from '../../components/common/Meta';
import RedLink from '../../components/lib/RedLink';
import { newsAndEventsFetchById } from '../../store/news-and-events/actions';
import { formatDatePosted } from '../../store/utils/helpers';
import NewsAndEventNotFound from './NewsAndEventNotFound';
import NewsAndEventsImage from './NewsAndEventsImage';

const NewsAndEventDetail = () => {
  const { id } = useParams<{ id: string }>();

  const { isLoading, data } = useQuery([id], async () => await newsAndEventsFetchById(id));

  if (isLoading)
    return (
      <Stack minHeight='45vh'>
        <FetchingLoadingIndicator />
      </Stack>
    );

  if (!data) return <NewsAndEventNotFound />;

  const { name, dateCreated, content, mediaUrl } = data;

  const postedDate = 'Posted - ' + formatDatePosted(dateCreated);

  return (
    <Stack component='article' my={{ xs: 1, md: 3 }} maxWidth={{ xs: '100%', md: '75%' }} mx='auto'>
      <Meta title={name} />
      <Typography component='h1' fontFamily='Roboto' fontSize={32} fontWeight={700}>
        {name}
      </Typography>
      <Typography
        component='time'
        fontFamily='Roboto'
        fontSize={14}
        fontWeight={400}
        color='#8E8E8E'
      >
        {postedDate}
      </Typography>
      <Stack my={3}>
        <NewsAndEventsImage
          src={mediaUrl}
          alt={name}
          width='100%'
          style={{ objectFit: 'fill', aspectRatio: '16 / 9' }}
        />
      </Stack>
      <div
        style={{ fontFamily: 'Roboto', marginBottom: 24 }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <RedLink to='/news-and-events' style={{ fontFamily: 'Roboto', fontSize: 15 }}>
        <ChevronLeft fontSize='small' />
        Back to News and Events
      </RedLink>
    </Stack>
  );
};

export default NewsAndEventDetail;

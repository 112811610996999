import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { Status } from "../system/types";
import {
  IServicesBanner,
  LOAD_SERVICES_BANNER_DATA,
  SET_SERVICES_BANNER_STATUS,
  ServicesBannerAction
} from "./types";

// Sync Actions
const setServicesBannerStatus = (status: Status): ServicesBannerAction => ({
  type: SET_SERVICES_BANNER_STATUS,
  payload: { status }
});

const loadServicesBannerData = (data: IServicesBanner[]): ServicesBannerAction => ({
  type: LOAD_SERVICES_BANNER_DATA,
  payload: { data }
});

export const fetchServicesBanner = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setServicesBannerStatus("fetching"));

    const response = await apiClient.request<{ banner: IServicesBanner }>({
      url: `${apiEndpoints.partsServices}/banner/SERVICES::BANNER::1`,
    });

    dispatch(loadServicesBannerData([response.data.banner]));
  } catch (err: any) {
    dispatch(setServicesBannerStatus("error"));
    errorHandler(err, dispatch);
  }
};
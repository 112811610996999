import { Box, ImageListItemBar, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-router-dom";
import SectionTitle from "../../../components/common/SectionTitle";
import SectionContainer from "../../../components/layout/SectionContainer";
import ModelDesignModal from "../model-ui/ModelDesignModal";
import useCurrentModel from "../utils/useCurrentModel";

const imgGradient = `linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 80%, rgba(0,0,0,0) 100%)`;

const ModelDesignImg = styled("img")(({ theme }) => ({
  objectFit: "cover",
  aspectRatio: "16 / 9",
  backfaceVisibility: "hidden",
  transform: "translateZ(0)",
  imageRendering: "-webkit-optimize-contrast",
  verticalAlign: "middle",

  [theme.breakpoints.between("xs", "md")]: {
    height: "100%",
  },
}));

const ModelDesignSection = () => {
  const model = useCurrentModel();

  if (!model) return null;

  const { exterior, interior } = model;

  const exteriorPreviewImg = exterior[0].imagePreviewUrl;
  const interiorPreviewImg = interior[0].imagePreviewUrl;

  return (
    <>
      <ModelDesignModal />
      <SectionContainer className='model-section' id='model-design'>
        <SectionTitle title='Design' />
        <Box
          display='grid'
          mt={2}
          gap={4}
          sx={{ gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" } }}
        >
          <Link
            to={`/models/${model.id}?design=interior`}
            aria-label='show interior design modal'
            style={{ position: "relative" }}
          >
            <ModelDesignImg
              src={interiorPreviewImg}
              alt={model.name}
              loading='lazy'
              height={290}
              width='100%'
            />
            <ImageListItemBar
              sx={{
                background: imgGradient,
              }}
              title={
                <Typography
                  fontSize={26}
                  fontWeight='500'
                  letterSpacing='0.5px'
                  sx={{ ml: 1, mb: 1 }}
                >
                  Interior
                </Typography>
              }
              position='bottom'
            />
          </Link>
          <Link
            to={`/models/${model.id}?design=exterior`}
            aria-label='show exterior design modal'
            style={{ position: "relative" }}
          >
            <ModelDesignImg
              src={exteriorPreviewImg}
              alt={model.name}
              loading='lazy'
              height={290}
              width='100%'
            />
            <ImageListItemBar
              sx={{
                background: imgGradient,
              }}
              title={
                <Typography
                  fontSize={26}
                  fontWeight='500'
                  letterSpacing='0.5px'
                  sx={{ ml: 1, mb: 1 }}
                >
                  Exterior
                </Typography>
              }
              position='bottom'
            />
          </Link>
        </Box>
      </SectionContainer>
    </>
  );
};

export default ModelDesignSection;

import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectModelVariants } from "../../../../store/models/selectors";

import { Stack, Typography } from "@mui/material";
import { HondaButton, Selection, selectionContainerStyles } from "./CompareModelComponents";
import { useReactToPrint } from "react-to-print";
import ModelAccordion from "./ModelAccordion";
import ModelSelectionContent from "./ModelSelectionContent";
import CompareModelsPrintView from "./CompareModelsPrintView";

const ModelSelection = (props: any, ref: any) => {
  const models = useSelector(selectModelVariants);
  const [adjustHeight, setAdjustHeight] = useState("");
  const [hide] = useState(false);
  const componentRef = useRef(ref);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `HCBT Compare Models_${models.selectedModels.map((m) => m.name).join("_")}`,
    pageStyle: `
      @media print {
        . {
            page-break-before: always;
        }
      }
    `,
  });

  useEffect(() => {
    setAdjustHeight(
      !!models.selectedModels.filter((v) => v.id.includes("MODEL")).length ? "grow" : ""
    );
  }, [models]);

  return (
    <>
      <CompareModelsPrintView ref={componentRef} />
      <Stack px={1}>
        <Stack sx={selectionContainerStyles}>
          <Selection alignItems='inherit' justifyContent='center' className={adjustHeight}>
            <Typography fontSize={18} fontWeight={500} ml={{ xs: 0, md: 5 }}>
              Select models:
            </Typography>
          </Selection>
          <Selection alignItems='center' justifyContent='center' className={adjustHeight}>
            <ModelSelectionContent selectedIndex={0} />
          </Selection>
          <Selection alignItems='center' justifyContent='center' className={adjustHeight}>
            <ModelSelectionContent selectedIndex={1} />
          </Selection>
          <Selection alignItems='center' justifyContent='center' className={adjustHeight}>
            <ModelSelectionContent selectedIndex={2} />
          </Selection>
        </Stack>
        <Stack gap={2}>
          <ModelAccordion hide={hide} />
        </Stack>
      </Stack>
      <Stack gap={2} flexDirection='row' justifyContent='center' alignItems='center'>
        <HondaButton marginy={3} onClick={handlePrint}>
          PRINT
        </HondaButton>
      </Stack>
    </>
  );
};

export default React.forwardRef(ModelSelection);

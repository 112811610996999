import { AppThunk } from "../index";
import { AxiosResponse } from "axios";
import {
  loginAxiosInstance,
} from "../utils/api";
import errorHandler from "../utils/errorHandler";
import {
  AuthAction,
  AuthCustomer,
  CALL_AUTH,
  CALL_AUTH_FAILED,
  CALL_AUTH_SUCCESS,
  SET_AUTH_CUSTOMER,
  LOG_OUT_CUSTOMER,
} from "./types";
import { showErrorSnackbar } from "../system/actions";

const loginUrl = "/elite/login";

export const callAuth = (): AuthAction => {
  return {
    type: CALL_AUTH,
  };
};

export const callAuthSuccess = (): AuthAction => {
  return {
    type: CALL_AUTH_SUCCESS,
  };
};

export const callAuthFailed = (err: string | null): AuthAction => {
  return {
    type: CALL_AUTH_FAILED,
    payload: err,
  };
};

export const setAuthCustomer = (authCustomer: AuthCustomer | null): AuthAction => {
  return {
    type: SET_AUTH_CUSTOMER,
    payload: authCustomer,
  };
};

export const logOutCustomer = (): AuthAction => {
  localStorage.removeItem("customerDetails");
  localStorage.removeItem("session");
  return {
    type: LOG_OUT_CUSTOMER,
  };
};

// ASYNC Actions
/**
 *
 * @param email the user-provided email
 * @param password the user-provided password
 * @description logs in a user
 */
export const loginCustomer =
  (username: string, password: string): AppThunk =>
  async (dispatch) => {
    dispatch(callAuth());
    try {
      const response:AxiosResponse<any> = await loginAxiosInstance.request({
        url: loginUrl,
        method: "POST",
        data: { username, password },
      });
      
      const {
        accessToken,
        id,
        cardNumber,
        firstName,
        lastName,
        points,
        redeemablePoints
      } = response.data;

      const authCustomer = {
        id,
        cardNumber,
        firstName,
        lastName,
        points,
        redeemablePoints,
        token: accessToken
      } as AuthCustomer;
      dispatch(setAuthCustomer(authCustomer));
      saveUserInLocalStorage(authCustomer as Omit<AuthCustomer, 'cardNumber' | 'id'>);
      dispatch(runLogoutTimer(3599 * 1000));
      dispatch(callAuthSuccess());
    } catch (err: any) {
      dispatch(showErrorSnackbar(err.response.data.message));
      dispatch(callAuthFailed(err.message));
      errorHandler(err, dispatch);
    }
  };

// HELPER METHODS
export function saveUserInLocalStorage(customerDetails: any) {
  localStorage.setItem("customerDetails", JSON.stringify(customerDetails));
  localStorage.setItem(
    "session",
    JSON.stringify({ expireDate: new Date(new Date().getTime() + 3599 * 1000) })
  );
}

export const runLogoutTimer =
  (timer: any): AppThunk =>
  async (dispatch) => {
    setTimeout(() => {
      dispatch(logOutCustomer());
    }, timer);
  };

import { ReactNode } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";

const Content = styled(DialogContent)(({ theme }) => ({
  "&::-webkit-scrollbar": {
    width: 8,
  },

  "&::-webkit-scrollbar-track": {
    borderRadius: 12,
    backgroundColor: "#eee",
  },

  "&::-webkit-scrollbar-thumb": {
    borderRadius: 12,
    backgroundColor: theme.palette.primary.main,
  },
}));

interface ModelDesignBareDialogProps {
  title: string;
  onClose: () => void;
  children: ReactNode;
}

const ModelDesignBareDialog = ({ title, onClose, children }: ModelDesignBareDialogProps) => {
  return (
    <Dialog
      open
      onClose={onClose}
      aria-labelledby='model-design-dialog-title'
      aria-describedby='model-design-dialog-description'
      maxWidth='md'
      fullWidth
      PaperProps={{ sx: { px: 1, m: { xs: 1, md: 4 }, width: { xs: "100%", md: 900 } } }}
    >
      <DialogTitle
        id='model-design-dialog-title'
        sx={{
          textTransform: "capitalize",
          fontSize: { xs: 24, md: 32 },
          fontWeight: 600,
          px: { xs: "10px", md: "24px" },
        }}
      >
        {title}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        disableRipple
        aria-label='close'
        size='small'
        sx={{ position: "absolute", top: 12, right: 12 }}
      >
        <CloseIcon fontSize='small' sx={{ color: "#222" }} />
      </IconButton>
      <Content sx={{ padding: { xs: "10px 10px", md: "20px 24px" } }}>{children}</Content>
    </Dialog>
  );
};

export default ModelDesignBareDialog;

import { AxiosResponse } from "axios";
import { AppThunk } from "../index";
import { apiClient, apiEndpoints } from "../utils/api";
import { uploadMedia } from "../../utils/upload";
import {
  SET_TESTIMONIAL_ERROR,
  SET_TESTIMONIAL_STATUS,
  TestimonialAction,
  TestimonialFields,
  ITestimonialData,
  TestimonialListState,
  LOAD_TESTIMONIAL_DATA
} from "./types";

// Sync actions
export const setTestimonialStatus = (status: TestimonialListState["status"]): TestimonialAction => ({
  type: SET_TESTIMONIAL_STATUS,
  payload: { status },
});

const setTestimonialError = (error: TestimonialListState["error"]): TestimonialAction => ({
  type: SET_TESTIMONIAL_ERROR,
  payload: { error },
});

const loadTestimonialData = (data:  TestimonialListState["data"]): TestimonialAction => ({
    type: LOAD_TESTIMONIAL_DATA,
    payload: { data },
});

// Async actions
export const submitTestimonial =
  (formData: TestimonialFields, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setTestimonialStatus("submitting"));

      const { mediaFile } = formData;

      let media = "";

      if (mediaFile) {
        const uploadRes = await uploadMedia(mediaFile, "careers");
        media = uploadRes[0].path;
      }

      const newFormData: Partial<ITestimonialData> = {
        ...formData,
        media
      };

      // call api here
      await apiClient.post(apiEndpoints.testimonial, newFormData);

      dispatch(setTestimonialStatus("success"));

      if (callback) callback();
    } catch (err: any) {
      let error = new Error("Server Error");
      if (err?.response?.data) {
        if (Array.isArray(err.response.data)) {
          error.message = err.response.data[0].message;
        } else {
          error.message = err.response.data.message;
        }
      }

      dispatch(setTestimonialError(error));
    }
  };

export const fetchTestimonialList = (): AppThunk => async (dispatch) => {
    try {
        
        dispatch(setTestimonialStatus("fetching"));
        const response:AxiosResponse<any> = await apiClient.request({
            url: `${apiEndpoints.testimonial}?active=true`,
        });
        
        dispatch(loadTestimonialData(response.data.testimonies)); 
    } catch (err: any) {
      let error = new Error("Server Error");
      if (err?.response?.data) {
        if (Array.isArray(err.response.data)) {
          error.message = err.response.data[0].message;
        } else {
          error.message = err.response.data.message;
        }
      }
        dispatch(setTestimonialStatus(err));
    }
  };

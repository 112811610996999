//@ts-ignore
import * as PANOLENS from "panolens";
import * as THREE from "three";

import { useEffect, useRef } from "react";
import { VirtualShowroom } from "../../../../store/virtual-showroom/types";

export default function usePanoramaViewer(showroom: VirtualShowroom) {
  const panoContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!showroom) return;

    // remove first the canvas
    const panoCanvasElements = document.querySelectorAll(".panolens-canvas");
    for (let i = 0; i < panoCanvasElements.length; i++) {
      panoContainer.current?.removeChild(panoCanvasElements[i]);
    }

    const { interior, infospots } = showroom;

    const panorama = new PANOLENS.ImagePanorama(interior);

    const viewer = new PANOLENS.Viewer({
      container: document.getElementById("pano-container"),
      controlBar: false,
      output: "console",
      autoHideInfospot: false,
    });

    panorama.addEventListener("leave", function () {
      document.querySelectorAll(".infospot").forEach(function (infospot) {
        (infospot as HTMLElement).style.display = "none";
      });
    });

    panorama.addEventListener("enter-fade-start", function () {
      viewer.tweenControlCenter(new THREE.Vector3(5000, -700, -80), 0);

      if (infospots.length > 0) {
        for (let i = 0; i < infospots.length; i++) {
          const { position, title } = infospots[i];
          const infoSpot = new PANOLENS.Infospot(300, PANOLENS.DataImage.Info);
          const { x, y, z } = position;
          infoSpot.position.set(x, y, z);

          infoSpot.addHoverElement(
            document.getElementById(title.toLowerCase().split(" ").join("-")),
            200
          );

          panorama.add(infoSpot);
          infoSpot.show();

          // const box = document.createElement("div");
          // box.style.width = "100px";
          // box.style.height = "100px";
          // box.style.position = "absolute";
          // box.style.top = "100px";
          // box.style.backgroundColor = "yellow";
          // box.style.transform = `translate(${x}px, ${y}px)`;

          // panorama.container.appendChild(box);

          // const localPos = panorama.worldToLocal(new THREE.Vector3(x, y, z));

          // console.log(localPos);
          // console.log(infoSpot.element);
        }
      }
    });

    viewer.add(panorama);

    return () => {
      viewer.remove(viewer.panorama);
      viewer.dispose();
    };
  }, [showroom]);

  return panoContainer;
}

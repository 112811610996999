import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { formatPromoDate } from '../../store/promos/selectors';
import { promosFetchById } from '../../store/promos/actions';

import { Stack, Typography } from '@mui/material';
import MuiLink from "@mui/material/Link";
import { ChevronLeft } from '@mui/icons-material';
import { appColors } from '../../theme';
import RedLink from '../../components/lib/RedLink';
import PromoNotFound from './PromoNotFound';
import PromoImage from './PromoImage';
import FetchingLoadingIndicator from '../../components/common/FetchingLoadingIndicator';
import Meta from '../../components/common/Meta';
import ReactPlayer from "react-player/youtube";

const PromoDetail = () => {
  const { id } = useParams<{ id: string }>();

  const { isLoading, data } = useQuery([id], async () => await promosFetchById(id));

  if (isLoading) {
    return (
      <Stack minHeight='45vh'>
        <FetchingLoadingIndicator />
      </Stack>
    );
  }

  if (!data) return <PromoNotFound />;

  const { title, content, mediaUrl, videoLink } = data;

  return (
    <Stack component='article' my={{ xs: 1, md: 3 }} maxWidth={{ xs: '100%', md: '75%' }} mx='auto'>
      <Meta title={title} />
      <Typography component='h1' fontFamily='Roboto' fontSize={32} fontWeight={700}>
        {title}
      </Typography>
      <Typography
        component='time'
        fontFamily='Roboto'
        fontSize={14}
        fontWeight={400}
        color={appColors.mediumGray}
      >
        {formatPromoDate(data.startDate, data.endDate)}
      </Typography>
      <Stack my={3}>
        { videoLink 
          ? <Stack> 
              <ReactPlayer url={videoLink} width="100%" height={400} controls />
              <Typography mt={1} fontSize={13}>
                Source: <span> </span>
                <MuiLink
                  target='_blank'
                  href={videoLink}
                  aria-label={`Link to details of ${title}`}
                  rel='noreferrer'
                >
                  {videoLink}
                </MuiLink>
              </Typography>
            </Stack>
          : <PromoImage
              src={mediaUrl}
              alt={title}
              width='100%'
              style={{ objectFit: 'fill', aspectRatio: '16 / 9' }}
            />
        }
			</Stack>
      <div
        style={{ fontFamily: 'Roboto', marginBottom: 24 }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <RedLink to='/promos' style={{ fontFamily: 'Roboto', fontSize: 15 }}>
        <ChevronLeft fontSize='small' />
        Back to Promotions
      </RedLink>
    </Stack>
  );
};

export default PromoDetail;

import { useSelector } from 'react-redux';
import { selectModelListState } from '../../store/models/selectors';
import ModelAccessorySection from './model-sections/ModelAccessorySection';
import ModelColorSection from './model-sections/ModelColorSection';
import ModelDesignSection from './model-sections/ModelDesignSection';
import ModelSafetySection from './model-sections/ModelSafetySection';
import ModelSpecificationsSection from './model-sections/Specifications';
import ModelPerformanceSection from './model-sections/performance';
import ModelNotFound from './ModelNotFound';
import useCurrentModel from './utils/useCurrentModel';
import { Stack } from '@mui/material';
import ModelVariantSection from './model-sections/ModelVariantsSection';
import FetchingLoadingIndicator from '../../components/common/FetchingLoadingIndicator';
import ModelNav from './model-ui/model-nav/ModelNav';
import ModelBannerSection from './model-sections/ModelBannerSection';
import Meta from '../../components/common/Meta';

const ModelDetails = () => {
  const model = useCurrentModel();
  const { status } = useSelector(selectModelListState);

  if (status === 'loading')
    return (
      <Stack minHeight='85vh'>
        <FetchingLoadingIndicator />
      </Stack>
    );

  if (!model) return <ModelNotFound />;

  return (
    <>
      <Meta title={model.name} />
      <ModelBannerSection />
      <ModelNav />
      <ModelDesignSection />
      <ModelColorSection />
      <ModelPerformanceSection />
      <ModelSafetySection />
      <ModelAccessorySection />
      <ModelVariantSection />
      <ModelSpecificationsSection />
    </>
  );
};

export default ModelDetails;

import { Button, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";

import { styled, SxProps } from "@mui/system";
import ArrowDownIcon from "@mui/icons-material/ExpandMoreOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CircleIcon from "@mui/icons-material/Circle";

export const VSCloseButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <IconButton
      onClick={onClick}
      disableRipple
      aria-label='close'
      size='small'
      sx={{ position: "absolute", top: 12, right: 12 }}
    >
      <CloseIcon fontSize='small' sx={{ color: "#222" }} />
    </IconButton>
  );
};

interface VSVariantSelectProps {
  options: { value: string; price: string }[];
  value: string;
  onChange: (variantValue: string) => void;
}

export const VSVariantSelect = ({ options, value, onChange }: VSVariantSelectProps) => {
  return (
    <Select
      onChange={(e) => onChange(e.target.value)}
      value={value}
      size='small'
      IconComponent={ArrowDownIcon}
      sx={{
        fontSize: 14,
        "& .MuiSvgIcon-root": { color: "#222" },
      }}
      renderValue={(selected) => {
        const current = options?.find((i) => i.value === (selected as string));
        if (!current) return "";

        return (
          <Stack direction='row' sx={{ "& > p": { fontFamily: "Roboto" } }}>
            <Typography fontSize={14}>{current.value} - </Typography>
            <Typography fontSize={14} color='primary.main'>
              &nbsp;{current.price}
            </Typography>
          </Stack>
        );
      }}
    >
      {options &&
        options.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            sx={{ width: "100%", "& > p": { fontFamily: "Roboto" } }}
          >
            <Typography fontSize={14}>{item.value} - </Typography>
            <Typography fontSize={14} color='primary.main'>
              &nbsp;{item.price}
            </Typography>
          </MenuItem>
        ))}
    </Select>
  );
};

export const VSVariantSummary = styled("div")(({ theme }) => ({
  padding: "0 20px",
  fontFamily: "Roboto",
  fontSize: 14,

  "& > *": {
    margin: 0,
    padding: 0,
  },

  "& > ul": {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: 32,
    rowGap: 4,

    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

export const VSWhiteButton = styled(Button)(({ theme }) => ({
  fontFamily: "inherit",
  fontSize: 13,
  fontWeight: 400,
  textTransform: "capitalize",
  backgroundColor: "white",
  flex: 1,
  paddingTop: 8,
  paddingBottom: 8,

  "&:hover": {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

export const VSPreviewContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "translated",
})<{ translated: boolean }>(({ translated, theme }) => ({
  position: "relative",
  alignSelf: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 400,
  width: "70%",
  overflow: "hidden",
  // marginLeft: "-50vw",
  // marginRight: "-50vw",
  backfaceVisibility: "hidden",

  // "& > div": {
  //   // display: "flex",
  //   // justifyContent: "center",
  //   // backfaceVisibility: "hidden",
  //   // width: "100vw",
  // },

  "& img": {
    width: "100%",
    aspectRatio: "16 / 9",
    objectFit: "cover",
    verticalAlign: "middle",
  },

  [theme.breakpoints.up("lg")]: {
    transition: "transform 0.3s ease-in",
    transform: translated ? "translate(300px, -20px) scale(1.1)" : "translate(0, 0) scale(1)",
  },

  [theme.breakpoints.up("md")]: {
    transition: "transform 0.3s ease-in",
    transform: translated ? "translate(280px, -20px) scale(1)" : "translate(0, 0) scale(1)",
  },

  [theme.breakpoints.down("md")]: {
    height: 450,
    width: "100%",
  },

  [theme.breakpoints.down("sm")]: {
    height: 250,
    marginTop: 50,
  },
}));

export const VSInteriorContainer = styled("div")({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  height: "90vh",
  overflow: "hidden",
  marginLeft: "-50vw",
  marginRight: "-50vw",
});

export const VSRoundedNumber = ({ numText }: { numText: string }) => {
  return (
    <Stack
      p={1}
      width={{ xs: 30, md: 35 }}
      height={{ xs: 30, md: 35 }}
      borderRadius='50%'
      bgcolor='primary.main'
      alignItems='center'
      justifyContent='center'
    >
      <Typography component='span' fontSize={{ xs: 18, md: 24 }} fontWeight={700} color='white'>
        {numText}
      </Typography>
    </Stack>
  );
};

export const VSViewTitle = ({ title, subTitle }: { title: string; subTitle: string }) => {
  return (
    <>
      <Typography fontSize={{ xs: 28, md: 36 }} fontWeight={700} lineHeight={{ xs: 1, md: 0.95 }}>
        {title}
      </Typography>
      <Typography my={{ xs: 0.5, md: 1 }} ml={0.3}>
        {subTitle}
      </Typography>
    </>
  );
};

export const ModelPreviewImage = styled("img")(({ theme }) => ({
  objectFit: "cover",
  aspectRatio: "16 / 9",
  backfaceVisibility: "hidden",
  transform: "translateZ(0)",
  imageRendering: "-webkit-optimize-contrast",
  verticalAlign: "middle",

  [theme.breakpoints.between("xs", "md")]: {
    height: "100%",
  },
}));

export const ChooseCarCarouselContainer = styled(Stack)(({ theme }) => ({
  justifyContent: "center",

  "& .carousel-root": {
    width: "100%",
  },
  "& .carousel-root > .carousel": {
    overflow: "unset",
    height: "100%",

    [theme.breakpoints.up("lg")]: {
      padding: "0px 45px",
    },
  },

  "& .slider.animated": {
    transform: `translate3d(0%, 0px, 0px)`,
  },

  "& .control-dots": {
    transform: "translateY(60px)",
  },
}));

// Carousel UIs

const controlButtonsStyles: SxProps = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  bgcolor: "white",
  borderRadius: 1,
  visibility: { xs: "hidden", md: "visible" },
  "&:hover": {
    bgcolor: "white",
  },
  "&:disabled": {
    bgcolor: "white",
    borderColor: "rgba(0,0,0,0.26)",
  },
};

/***  NEXT BUTTON ****/
interface NextButtonProps {
  onClick: () => void;
  hasNext: boolean;
  label: string;
}

export const NextButton = ({ onClick, hasNext, label }: NextButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      disabled={!hasNext}
      aria-label={label}
      sx={{
        ...controlButtonsStyles,
        zIndex: 2,
        right: "0",
      }}
    >
      <ArrowForwardIcon />
    </IconButton>
  );
};

/***  PREV BUTTON ****/
interface PrevButtonProps {
  onClick: () => void;
  hasPrev: boolean;
  label: string;
}

export const PrevButton = ({ onClick, hasPrev, label }: PrevButtonProps) => {
  return (
    <IconButton
      onClick={onClick}
      disabled={!hasPrev}
      aria-label={label}
      sx={{
        ...controlButtonsStyles,
        zIndex: 2,
        left: "0",
      }}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

/***  INDICATOR BUTTON ****/
interface CarouselIndicatorProps {
  clickHandler: (e: any) => void;
  isSelected: boolean;
  index: number;
  label: string;
}

export const CarouselIndicator = ({
  clickHandler,
  isSelected,
  index,
  label,
}: CarouselIndicatorProps) => {
  return (
    <IconButton aria-label={label} onClick={clickHandler} size='small'>
      <CircleIcon
        sx={{
          fontSize: 13,
          color: isSelected ? (theme) => theme.palette.primary.main : "#ddd",
        }}
      />
    </IconButton>
  );
};

import { useLocation } from "react-router-dom";

export default function useSearchKeyword() {
  const location = useLocation();
  const { search: searchParam } = location;
  const searchQuery = new URLSearchParams(searchParam);
  const searchKeyword = searchQuery.get("query") || "";

  return searchKeyword;
}

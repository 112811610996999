import { useEffect, useRef, useState } from "react";
import { apiClient, apiEndpoints } from "../store/utils/api";
import { appColors } from "../theme";

interface ThemeData {
  id: string;
  colorValue: string;
}

export default function useThemeLoader() {
  const [loading, setLoading] = useState(false);
  const [themeData, setThemeData] = useState<ThemeData>(() => {
    try {
      const storedTheme = localStorage.getItem("hcbt-theme");
      if (storedTheme) {
        return JSON.parse(storedTheme) as ThemeData;
      } else {
        return { id: "THEME::1", colorValue: appColors.primary };
      }
    } catch (error) {
      return { id: "THEME::1", colorValue: appColors.primary };
    }
  });

  const loadedRef = useRef(false);

  useEffect(() => {
    const loadTheme = async () => {
      try {
        setLoading(true);
        const response = await apiClient.request<{ themes: ThemeData }>({
          url: apiEndpoints.theme,
        });

        if (!loadedRef.current) {
          setThemeData((old) => (response.data.themes ? response.data.themes : old));
          localStorage.setItem("hcbt-theme", JSON.stringify(response.data.themes));
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    loadTheme();

    return () => {
      loadedRef.current = true;
    };
  }, [themeData]);

  return {
    loading,
    themeData,
  };
}

import { Box, Container, Stack, Typography } from "@mui/material";
import { BoxStyle, EmailLink } from "./DataPrivacyStyle";
import { personalInfo, websiteCookies, infoHCBT } from "./DataPrivacyTexts";

const DataPrivacyAct = () => {
  return (
    <Stack component='div' sx={{ px: { xs: 0, lg: 9 }, py: { xs: 2, lg: 5 } }} minHeight='85vh'>
      <Container maxWidth='xl'>
        <Typography fontWeight={600} gutterBottom fontSize='30px'>
          Data Privacy Act
        </Typography>
        <Box width='100%' sx={BoxStyle} mb={2}>
          <Box mb={1}>
            <Typography variant='body1' fontSize='13.5px' gutterBottom>
              We, Hondacars Batangas, pledge to collect and process personal data in accordance with
              Republic Act No. 10173, generally known as the Data Privacy Act of 2012 (the Data
              Privacy Act).
            </Typography>
            <Typography variant='body1' fontSize='13.5px' mb={3} gutterBottom>
              This Privacy Policy (the "Privacy Policy") explains how we collect, use, and disclose
              your Personal Information (as defined below), as well as how you can exercise any
              rights you may have.
            </Typography>
          </Box>
        </Box>
        <Typography fontSize='20px' mb={2} fontWeight={600}>
          The Information We Collect
        </Typography>
        <Typography fontSize='16px' mt={2} mb={2} fontWeight={600}>
          1. Personal information you provide us, which may include:
        </Typography>
        <Box width='100%' sx={BoxStyle} mb={2}>
          {personalInfo.map((text, index) => (
            <Box component='ul' key={index} pl={3} mb={1}>
              <Typography component='li' fontSize='13.5px'>
                {text}
              </Typography>
            </Box>
          ))}
          <Typography fontSize='16px' fontWeight={600} mt={2} mb={2}>
            2. Website Cookies
          </Typography>
          {websiteCookies.map((text, index) => (
            <Box component='ul' key={index} mb={1} pl={3}>
              <Typography component='li' fontSize='13.5px'>
                {text}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box width='100%' sx={BoxStyle}>
          <Typography fontSize='20px' fontWeight={600} mb={1}>
            Use of Information by HCBT
          </Typography>
          {infoHCBT.map((text, index) => (
            <Box key={index} mb={1}>
              <Typography fontSize='13.5px'>{text}</Typography>
            </Box>
          ))}
          <Typography fontSize='20px' fontWeight={600} mb={2}>
            Sharing of Information with Third Parties
          </Typography>
          <Typography variant='body1' fontSize='13.5px' mb={2} gutterBottom>
            We may share personal and anonymous information collected from the Sites as outlined in
            this section, unless otherwise limited by the Policy. The sharing of information must be
            covered by the corresponding Data Sharing Agreement and/or Confidentiality /
            Non-Disclosure Agreement in each case.
          </Typography>
          <Typography fontSize='20px' fontWeight={600} mb={2}>
            Contact Information
          </Typography>
          <Typography variant='body1' fontSize='13.5px' gutterBottom>
            If you have any questions about this Privacy Policy or our online privacy practices, you
            may get in touch with HCBT thru its Data Protection Officer at:
          </Typography>
          <Typography variant='body1' fontSize='13.5px'>
            <strong>Email:</strong>
            <EmailLink>sherwin.torino@hondacarsbatangas.com</EmailLink>
          </Typography>
          <Typography variant='body1' fontSize='13.5px' mb={2}>
            <strong>Phone:</strong> &nbsp; (043) 702-9376 local 103
          </Typography>
          <Typography fontSize='20px' fontWeight={600} mb={2}>
            Policy Updates
          </Typography>
          <Typography variant='body1' fontSize='13.5px' gutterBottom>
            This Privacy Policy will be updated whenever HCBT's privacy policies or practices change
            to ensure that our users are constantly informed of the data we collect, how we use it,
            and under what circumstances, if any, we release it. We advise checking this page
            frequently to examine our policies and processes.
          </Typography>
        </Box>
      </Container>
    </Stack>
  );
};

export default DataPrivacyAct;

import { KeyboardArrowDown } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { useState, MouseEvent } from "react";
import WebSortButton from "../../components/common/WebSortButton";
import usePromoState from "./utils/usePromoState";

const PromoSort = () => {
	const { sortBy, onSortByChange, status } = usePromoState();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	if (status === "fetching") return null;

	return (
		<div style={{  display: "flex", alignSelf: "flex-end", marginTop: "10px" }}>
			<WebSortButton
				id="sort-button"
				aria-controls={open ? "sort-button-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				sx={{
					fontWeight: 500
				}}
			>
				Sort <KeyboardArrowDown />
			</WebSortButton>
			<Menu
				id="sort-button-menu"
				anchorEl={anchorEl}
				open={open}
				onClick={handleClose}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "sort-button",
				}}
			>
				<MenuItem
					sx={{
						color: sortBy === "startDate:desc" ? "primary.main" : "inherit",
					}}
					onClick={() => onSortByChange("startDate:desc")}
				>
					Newest to Oldest
				</MenuItem>
				<MenuItem
					sx={{
						color: sortBy === "startDate:asc" ? "primary.main" : "inherit",
					}}
					onClick={() => onSortByChange("startDate:asc")}
				>
					Oldest to Newest
				</MenuItem>
			</Menu>
		</div>
	);
};

export default PromoSort;

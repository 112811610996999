import type { AppState } from "../index";

export const selectModelListState = (state: AppState) => state.models;

export const selectModelData = (state: AppState) => state.models.data;

export const selectModelVariants = (state: AppState) => state.variants;

export const selectModelById = (id: string) => (state: AppState) => 
  state.models.data.find(m => m.id === id);

export const filterModels = (type: string) => (state: AppState) => {
  if (type === "All") return state.models.data;
  else return state.models.data.filter(d => d.type === type);
}

export const selectModelsForSearch = (state: AppState) => 
  state.models.data.map((i) => { 
    return  { 
      id: i.id, 
      module: "Models", 
      title: i.name,
      link: `/models/${i.id}` 
    }
  });

export const selectVariantsForSearch = (state: AppState) => {
  let modelVariant = [];

  for (const m of state.models.data) {
    for (const v of m.variant) {
      modelVariant.push({ 
        id: m.id, 
        module: "Model Variant", 
        title: v.name, 
        content: v.summary.content,
        link: `/models/${m.id}` 
      })
    }
  }

  return modelVariant;
}

export const modelOptions = (state: AppState) => {
  let options = state.models.data.map((i) => { 
    return {
      id: i.id, 
      label: i.name, 
      value: i.name
    }
  });

  options.push({ id: "Others", label: "Others", value: "Others" });

  return options;
}
import { Stack } from "@mui/material";
import { SxProps, styled } from "@mui/system";
import { appColors } from "../../../theme/index";

export const TestimonialBox = styled(Stack)<{ count: number }>(({ count, theme }) => ({
  height: "inherit",
  position: "relative",
  padding: "50px 45px",

  [theme.breakpoints.down("md")]: {
    padding: "20px 0",
  },

  "& .carousel-root": {
    width: "100%",
  },
  "& .carousel-root > .carousel": {
    overflow: "unset",
    height: "100%",
  },
  "& .testimonials-carousel": {
    width: "100%",
  },
  "& .slider.animated": {
    transform: `translate3d(0%, 0px, 0px)`,
  },
  ".carousel , .slider": {
    justifyContent: `${count < 2 ? "center" : "flex-start"} `,
  },
  ".testimonials-carousel .carousel-root .carousel-slider": {
    padding: 0,
  },
}));

export const testimonialButtonStyles: SxProps = {
  border: "1.5px solid",
  borderColor: appColors.primary,
  background: "white",
  textTransform: "capitalize",
  borderRadius: "0px",
  width: "33vh",
  padding: "10px",
  ":hover": {
    backgroundColor: appColors.primary,
    color: appColors.white,
  },
};

export const contentContainer: SxProps = {
  height: { xs: "20vh", sm: "10vh", md: "20vh" },
  padding: "0 10px 0 10px",
  overflowY: "auto",
  width: "100%",
};

export const testimonialContainer: SxProps = {
  marginX: "2.5%",
  backgroundColor: appColors.white,
  minHeight: { xs: "20vh", md: "100%" },
  textAlign: "start",
  padding: "12px",
  minWidth: "95%",
};

export const imageContainer: SxProps = {
  display: "flex",
  boxSizing: "border-box",
  position: "relative",
  "& img": {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
  width: { xs: "15px", md: "35px" },
  height: { xs: "35px", md: "40px" },
  margin: "10px 0 10px 10px",
};

export const userDataContainer: SxProps = {
  lineHeight: "normal",
  textAlign: "start",
  justifyContent: "center",
  marginTop: "20px",
  marginBottom: "10px",
  padding: "0 10px 0 10px",
};

export const Image = styled("img")({
  width: "100%",
  objectFit: "cover",
  objectPosition: "center",
  aspectRatio: "16 / 9",
});

export const imageModal: SxProps = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', md: '60%' },
  bgcolor: 'transparent',
  pb: 0  
};

import { Stack } from "@mui/material";
import { VSRoundedNumber, VSViewTitle } from "./VSUi";
import VSViewDetailsButton from "./VSViewDetailsButton";

interface VSPageTitleProps {
  onViewDetailsClick: () => void;
}

const VSPageTitle = ({ onViewDetailsClick }: VSPageTitleProps) => {
  return (
    <Stack
      direction='row'
      gap={2}
      justifyContent={{ xs: "center", md: "start" }}
      id='vs-page-title'
    >
      <VSRoundedNumber numText='2' />
      <Stack>
        <VSViewTitle title='Virtual 360' subTitle='Choose color and swipe to rotate' />
        <VSViewDetailsButton onClick={onViewDetailsClick} />
      </Stack>
    </Stack>
  );
};

export default VSPageTitle;

import {
  SET_NEWS_AND_EVENTS_BANNER_STATUS,
  LOAD_NEWS_AND_EVENTS_BANNER_DATA,
  NewsAndEventsBannerAction,
  NewsAndEventsBannerState
} from "./types";

const initialState: NewsAndEventsBannerState = {
  status: "idle",
  error: undefined,
  data: [],
};

const newsAndEventsBannerReducer = (state = initialState, action: NewsAndEventsBannerAction): NewsAndEventsBannerState => {
  switch (action.type) {
    case SET_NEWS_AND_EVENTS_BANNER_STATUS:
      return { ...state, status: action.payload.status };
    case LOAD_NEWS_AND_EVENTS_BANNER_DATA:
      return { ...state, data: action.payload.data, status: "idle" };
    default:
      return state;
  }
};

export default newsAndEventsBannerReducer;
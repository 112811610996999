import { Stack } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import SectionTitle from "../../../../components/common/SectionTitle";

import WebPrimaryButton from "../../../../components/common/WebPrimaryButton";
import SectionContainer from "../../../../components/layout/SectionContainer";
import useCurrentModel from "../../utils/useCurrentModel";
import LightGrayContainer from "./LightGrayContainer";
import ModelColorPreviewImage from "./ModelColorPreviewImage";
import ModelColorSelect from "./ModelColorSelect";

const ModelColorSection = () => {
  const model = useCurrentModel();
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const history = useHistory();

  if (!model) return null;

  const modelColors = model.colorSelection;
  const currentModelColor = modelColors[currentColorIndex];

  const goToShowroom = () =>
    history.push(`/virtual-showroom/car/${model.id}?color=${currentModelColor.colorName}`);

  return (
    <SectionContainer
      className='model-section'
      id='model-color'
      sx={{ position: "relative", py: { xs: 4, lg: 8 } }}
    >
      <SectionTitle title='Model Color' />
      <Stack alignItems='center' alignSelf='center' mt={4} pb={10}>
        <ModelColorPreviewImage src={currentModelColor.vehicleImagePreviewUrl} />
        <ModelColorSelect
          modelColors={modelColors}
          currentColorIndex={currentColorIndex}
          onChange={setCurrentColorIndex}
        />
        <WebPrimaryButton
          onClick={goToShowroom}
          sx={{
            mt: 4,
            alignSelf: "center",
            width: "auto",
          }}
        >
          View 360
        </WebPrimaryButton>
        <LightGrayContainer />
      </Stack>
    </SectionContainer>
  );
};

export default ModelColorSection;

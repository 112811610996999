import { styled } from "@mui/system";

const ModelColorPreviewImage = styled("img")(({ theme }) => ({
  maxWidth: "100%",

  objectFit: "cover",
  backfaceVisibility: "hidden",
  transform: "translateZ(0)",
  imageRendering: "-webkit-optimize-contrast",
  verticalAlign: "middle",

  [theme.breakpoints.down("md")]: {
    transform: "translateZ(0) translateY(0)",
    aspectRatio: "16 / 9",
  },
}));

export default ModelColorPreviewImage;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Divider, Stack, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import CareerNotFound from './CareerNotFound';
import InquiryForm from './InquiryForm';
import RedLink from '../../components/lib/RedLink';
import { fetchCareerList } from '../../store/careers/actions';
import { selectCareerListState, selectCareersById } from '../../store/careers/selectors';
import SectionTitle from '../../components/common/SectionTitle';
import FetchingLoadingIndicator from '../../components/common/FetchingLoadingIndicator';
import Meta from '../../components/common/Meta';

const CareerDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { status } = useSelector(selectCareerListState);
  const career = useSelector(selectCareersById(id));

  useEffect(() => {
    dispatch(fetchCareerList());
  }, [dispatch]);

  if (status === 'loading') {
    return <FetchingLoadingIndicator />;
  }

  if (!career) {
    return <CareerNotFound />;
  }

  return (
    <>
      <Meta title={career.name} />
      <Stack
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          gap: { xs: 2, md: 10 },
        }}
      >
        <Stack flex={1} gap={2}>
          <SectionTitle title={career.name} />
          <Divider />
          <Stack>
            <Typography component='h3' fontWeight='600' fontSize={20}>
              Qualifications
            </Typography>
            <Stack gap={2} pl={0}>
              <div
                style={{
                  color: '#000',
                  fontSize: '0.9rem',
                  marginBottom: '1rem',
                }}
                dangerouslySetInnerHTML={{ __html: career?.content }}
              />
            </Stack>
            <RedLink to='/careers'>
              <ChevronLeft fontSize='small' />
              Job Openings
            </RedLink>
          </Stack>
        </Stack>
        <Stack flex={1}>
          <InquiryForm careerName={career?.name} />
        </Stack>
      </Stack>
    </>
  );
};

export default CareerDetails;

import { Stack, Typography } from "@mui/material";
import VSContainer from "./VSContainer";

const VSNotFound = () => {
  return (
    <VSContainer>
      <Stack height={400} justifyContent='center'>
        <Typography fontWeight='600' fontSize={{ xs: 22, md: 28 }}>
          The selected model does not have a Virtual Showroom yet.
        </Typography>
      </Stack>
    </VSContainer>
  );
};

export default VSNotFound;

import { styled, keyframes, Box } from "@mui/system";

export const TransparentBackGround = styled(Box)({
  position: "absolute",
  width: "100%",
  minHeight: "700px",
  display: "flex",
  justifyContent: "center",
});

interface ImageFrameProps {
  imageUrl: string;
}

export const ImageFrame = styled(Box)(({ imageUrl }: ImageFrameProps) => ({
  position: "absolute",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: imageUrl,
  backgroundSize: "100%",
}));

export const carLeftKeyframes = keyframes`
    0% {
      opacity: 0;
      transform: translateX(0px);
    }
    100% {
      opacity: 1;
      transform: translateX(-275px);
    }
`;

export const carRightKeyframes = keyframes`
    0% {
      opacity: 0;
      transform: translateX(0px);
    }
    100% {
      opacity: 1;
      transform: translateX(300px);
    }
`;

export const carCenterKeyframes = keyframes`
    0% {
      opacity: 0;
      transform: translateY(350px);
    }
    100% {
      opacity: 1;
      transform: translateY(140px);
    }
`;

export const textKeyframes = keyframes`
    0% {
      opacity: 0;
      transform: translateY(350px);
    }
    100% {
      opacity: 1;
      transform: translateY(70px);
    }
`;

export const buttonStartVirtualShowroom = keyframes`
    0% {
      opacity: 0;
      transform: translateY(650px);
    }
    100% {
      opacity: 1;
      transform: translateY(520px);
    }
`;

import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { SxProps } from '@mui/system';

export const Text = styled(Typography)(() => ({
  fontSize: 15,
}));

export const TextHeader = styled("h2")(({ theme }) => ({
  fontWeight: 600,
  margin: 0,

  "& > span": {
    color: theme.palette.primary.main,
  },

  [theme.breakpoints.up("xs")]: {
    fontSize: 28,
  },

  [theme.breakpoints.up("sm")]: {
    fontSize: 32,
  },
}));

export const Image = styled("img")({
  width: "100%",
  objectFit: "cover",
  objectPosition: "center",
  aspectRatio: "16 / 9",
});

export const ImageIcon = styled("img")({
  objectFit: "fill",
  objectPosition: "center",
  aspectRatio: "1 / 1",
  width: 55,
  marginBottom: 15,
});

export const TopImage = styled("img")<{ isMobile: boolean }>(({ isMobile, theme }) => ({
  width: "100%",
  aspectRatio: "16 / 9",
  objectFit: "cover",
  objectPosition: "center",
  verticalAlign: "middle",
  height: isMobile ? "auto" : "83vh"
}));
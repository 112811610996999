import { styled } from "@mui/system";
import { SxProps } from "@mui/system";

export const ContainerStyle: SxProps = {
  display: "flex",
  flex: 1,
  alignItems: "center",
};

export const ButtonStyle: SxProps = {
  display: { xs: "flex", md: "none" },
  ml: "auto",
  color: "white",
  fontWeight: 500,
};

export const TextStyle : SxProps = {
    cursor: "pointer"
};

export const ModalNavItemStyled = styled("li")(({ theme }) => ({
    padding: "24px 8px",
    height: "100%",
    cursor: "pointer",
    fontSize: 14,
    listStyle: "none",
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: 4,
      left: 0,
      width: "100%",
      height: "4px",
      backgroundColor: theme.palette.primary.main,
      opacity: 0,
      transition: "opacity 0.3s ease-in",
    },
  
    "&:hover:after": { opacity: 0.5 },
  
    "&.active": {
      opacity: 1,
    },
  
    "&.active:after": {
      opacity: 1,
    },
  }));
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchModels } from "../../../../store/models/actions";
import { selectModelListState } from "../../../../store/models/selectors";
import { fetchVirtualShowrooms } from "../../../../store/virtual-showroom/actions";
import { selectVirtualShowroomState } from "../../../../store/virtual-showroom/selectors";

export default function useModelShowroomFetcher() {
  const { status: vsStatus } = useSelector(selectVirtualShowroomState);
  const { status: modelStatus } = useSelector(selectModelListState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchModels());
    dispatch(fetchVirtualShowrooms());
  }, [dispatch]);

  return vsStatus === "fetching" || modelStatus === "loading";
}

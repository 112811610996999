import { useEffect, useState } from "react";

import { Stack, CircularProgress, Box, Divider } from "@mui/material";
import { 
  SearchNoResultLabel,
  SearchModule, 
  SearchTitle, 
  SearchContent, 
  SearchLink 
} from "./SearchStyle";
import useIsTabletOrMobile from "../../hooks/useIsTabletOrMobile";

interface SearchListProps {
  searchData: DataProps[]
  isLoading: boolean
}

interface DataProps {
  id: string,
  module: string,
  title: string,
  content: string,
  link: string
}

const SearchList = ({ searchData, isLoading }: SearchListProps) => {
  const [length, setLength] = useState<number>();
  const isTabletOrMobile = useIsTabletOrMobile();
  
  useEffect(() => {
    if (isTabletOrMobile) setLength(100);
    else setLength(400);
  }, [isTabletOrMobile])

  if (isLoading)
    return (
      <Stack width='100%' mt={10} flex={1} alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    );

  return(
    <>
      { searchData.length === 0 
        ? <SearchNoResultLabel>
            No results found.
          </SearchNoResultLabel>
        : searchData?.map((d, i) => (
            <Box key={i}>
              <Box marginY={2}>
                <SearchModule>
                  {d.module}
                </SearchModule>
                <SearchTitle>
                  {d.title}
                </SearchTitle>
                <SearchContent>
                  { d.content && d.content.length > 100 
                    ? `${d.content.slice(0, length)}...` 
                    : d.content }
                </SearchContent>
                <SearchLink to={d.link}>
                  Read More
                </SearchLink>
              </Box>
              <Divider />
            </Box>
        ))}
    </>
  )
}

export default SearchList;
import {
  SET_SERVICES_BANNER_STATUS,
  LOAD_SERVICES_BANNER_DATA,
  ServicesBannerAction,
  ServicesBannerState
} from "./types";

const initialState: ServicesBannerState = {
  status: "idle",
  error: undefined,
  data: [],
};

const careersBannerReducer = (state = initialState, action: ServicesBannerAction): ServicesBannerState => {
  switch (action.type) {
    case SET_SERVICES_BANNER_STATUS:
      return { ...state, status: action.payload.status };
    case LOAD_SERVICES_BANNER_DATA:
      return { ...state, data: action.payload.data, status: "idle" };
    default:
      return state;
  }
};

export default careersBannerReducer;
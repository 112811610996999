import type { AppState } from "../index";
import { orderBy } from "lodash";

export const selectNewsAndEventsState = (state: AppState) => state.newsAndEvents;

export const selectNewsAndEventsData = (state: AppState) => {
  const sortBy = state.newsAndEvents.sortBy.split(":");

  const [property, order] = sortBy;

  return orderBy(state.newsAndEvents.data, [property], [order as "asc" | "desc"]);
};

export const selectNewsAndEventsLatestData = (state: AppState) => {
  const orderedNewsAndEvents = orderBy(state.newsAndEvents.data, ["dateCreated"], ["desc"]).slice(0,5)

  return orderedNewsAndEvents
};

export const selectNewsAndEventsById = (id: string) => (state: AppState) =>
  state.newsAndEvents.data.find((i) => i.id === id);

export const selectNewsAndEventsForSearch = (state: AppState) => 
  state.newsAndEvents.allData.map((i) => { 
    return  { 
      id: i.id, 
      module: "News & Events", 
      title: i.name,
      content: i.content,
      link: `/news-and-events/${i.id}` 
    }
  });
import type { AppError, IFeatureState, Status } from "../system/types";

export interface IHeader {
  id: string;
  name: string;
  media: string;
  content: string;
  isEnabled: boolean;
  link: string;
  dateCreated: Date;
  dateUpdated: Date;
}

export type HeaderState = IFeatureState<IHeader>;
export const SET_HEADER_STATUS = "header/status/set";
export const SET_HEADER_ERROR = "header/error/set";
export const LOAD_HEADER_DATA = "header/data/load";

interface SetHeaderStatusAction {
  type: typeof SET_HEADER_STATUS;
  payload: { status: Status };
}

interface SetHeaderErrorAction {
  type: typeof SET_HEADER_ERROR;
  payload: { error: AppError };
}

interface SetHeaderDataAction {
  type: typeof LOAD_HEADER_DATA;
  payload: { data: HeaderState["data"] };
}

export type HeaderAction =
  | SetHeaderStatusAction
  | SetHeaderErrorAction
  | SetHeaderDataAction;
import { styled } from "@mui/system";

interface Props {
  bgUrl: string;
  isActive: boolean;
}

const ModelColorButton = styled("button", {
  shouldForwardProp: (prop) => prop !== "bgUrl" && prop !== "isActive",
})<Props>(({ bgUrl, isActive }) => ({
  appearance: "none",
  outlineWidth: 0,
  border: "2px solid silver",
  borderRadius: "50%",
  width: 24,
  height: 24,
  backgroundImage: `url(${bgUrl})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  cursor: "pointer",
  position: "relative",

  "&::after": {
    content: '""',
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 34,
    height: 34,
    border: "1px solid silver",
    borderRadius: "50%",
    opacity: isActive ? 1 : 0,
  },
}));

export default ModelColorButton;

import SectionTitle from "../../../../components/common/SectionTitle";
import SectionContainer from "../../../../components/layout/SectionContainer";
import ModelContentCarousel from "../../model-ui/ModelContentCarousel";
import useCurrentModel from "../../utils/useCurrentModel";
import Variant from "./Variant";

const ModelPerformanceSection = () => {
  const model = useCurrentModel();

  if (!model) return null;

  const { performance, variant } = model;

  return (
    <SectionContainer className='model-section' id='model-performance'>
      <SectionTitle title='Performance' />
      <ModelContentCarousel modelContentData={performance} />
      <Variant variantData={variant} />
    </SectionContainer>
  );
};

export default ModelPerformanceSection;

import { Stack, Typography } from "@mui/material";
import { INewsAndEvent } from "../../store/news-and-events/types";
import { formatToDay, formatToMonth } from "../../store/utils/helpers";

import NewsAndEventsImage from "./NewsAndEventsImage";

const colorMap = {
  News: "#CC2229",
  Event: "#ABCC22",
};

const imgGradient = `linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 80%, rgba(0,0,0,0) 100%)`;

const NewsAndEventItem = ({ item }: { item: INewsAndEvent }) => {
  const day = formatToDay(item.dateCreated);
  const month = formatToMonth(item.dateCreated);

  return (
    <Stack
      position='relative'
      overflow='hidden'
      border={1}
      borderColor='#eee'
      sx={{
        "&:hover > img": {
          transform: "scale(1.1)",
        },
      }}
    >
      <NewsAndEventsImage
        src={item.mediaUrl}
        alt={item.name}
        loading='lazy'
        width='100%'
        height={230}
      />
      <Stack position='absolute' top={0} left={0} bgcolor='white' textAlign='center'>
        <Typography color='#222' fontSize={20} fontWeight={700} mb={-1} mt={0.3}>
          {day}
        </Typography>
        <Typography fontSize={14} sx={{ color: "#bbb" }} mb={0.5}>
          {month}
        </Typography>
        <Stack
          fontWeight={500}
          fontSize={13}
          px={1.4}
          pt={0.5}
          pb={0.6}
          bgcolor={colorMap[item.type]}
          color='white'
        >
          {item.type}
        </Stack>
      </Stack>
      <Stack
        sx={{ background: imgGradient }}
        position='absolute'
        bottom={0}
        width='100%'
        px={3}
        pb={3}
      >
        <Typography fontSize={20} fontWeight={600} color='white' mb={0.5} lineHeight={1.2}>
          {item.name}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default NewsAndEventItem;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { selectCareersBannerData } from "../../store/careers-banner/selectors";
// import { fetchCareersBanner } from "../../store/careers-banner/actions";
import { selectNewsAndEventsBannerData } from "../../store/news-and-events-banner/selectors";
import { fetchNewsAndEventsBanner } from "../../store/news-and-events-banner/actions";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {
  BannerContainer,
  taglineContainerStyle,
  taglineStyle
} from "./NewsAndEventsBannerStyle";

const NewsAndEventsBanner = () => {
  const dispatch = useDispatch();

  const banner = useSelector(selectNewsAndEventsBannerData);

  useEffect(() => {
    dispatch(fetchNewsAndEventsBanner());
  }, [dispatch]);

  if (banner.length === 0) return null;

  return (
    <Stack component='section'>
      <BannerContainer 
        height="50vh" 
        image={banner[0]?.media}
      >
        <Box sx={taglineContainerStyle}>
          <Typography sx={taglineStyle}>
            {banner[0]?.tagline}
          </Typography>
        </Box>
      </BannerContainer>
    </Stack>
  )
}

export default NewsAndEventsBanner;
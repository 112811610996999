import { styled } from "@mui/system";

const WebSortButton = styled("button")(({ theme }) => ({
  fontSize: 15,
  position: "relative",
  cursor: "pointer",
  border: "none",
  backgroundColor: "white",
  color: "#222",
  fontFamily: "inherit",
  display: "flex",
  alignItems: "center",
  gap: 8,

  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

export default WebSortButton;

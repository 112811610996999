import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Stack, Typography, Divider, TextField, Box } from "@mui/material";
import { searchFieldStyle, SearchButton, SearchResultLabel } from "./SearchStyle";
import SearchList from "./SearchList";
import Pagination from "./Pagination";
import SectionContainer from "../../components/layout/SectionContainer";
import { appColors } from "../../theme";
import useSearchKeyword from "./utils/useSearchKeyword";
import useSearchResults from "./utils/useSearchResults";

export const SearchPage = () => {
  const searchKeyword = useSearchKeyword();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const { isLoading, searchResults } = useSearchResults(searchKeyword);

  const indexOfLastPost = currentPage * dataPerPage;
  const indexOfFirstPost = indexOfLastPost - dataPerPage;
  const currentData = searchResults?.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const lowerBound = currentPage > 1 ? (currentPage - 1) * dataPerPage + 1 : currentPage;
  const upperBound =
    dataPerPage * currentPage < searchResults.length
      ? dataPerPage * currentPage
      : searchResults.length;

  const resultText = `Showing ${lowerBound} - ${upperBound} of ${searchResults?.length} of '${searchKeyword}'`;

  const onSearch = () => {
    if (!searchInputRef.current?.value) return;

    history.push({
      pathname: "/search",
      search: `?query=${searchInputRef.current.value}`,
    });

    searchInputRef.current.value = "";
  };

  return (
    <SectionContainer bg={appColors.softGray}>
      <Box minHeight='85vh'>
        <Stack direction='row' mb={2}>
          <TextField
            defaultValue={searchKeyword}
            id='outlined-size-small'
            size='small'
            fullWidth={true}
            sx={searchFieldStyle}
            inputRef={searchInputRef}
          />
          <SearchButton onClick={onSearch}>Search</SearchButton>
        </Stack>
        <SearchResultLabel>Search Results</SearchResultLabel>
        <Divider />
        {searchResults.length > 0 && (
          <Typography mt={2} mb={3}>
            {resultText}
          </Typography>
        )}
        <SearchList searchData={currentData} isLoading={isLoading} />
        <Pagination
          currentPage={currentPage}
          dataPerPage={dataPerPage}
          totalData={searchResults?.length}
          paginate={paginate}
        />
      </Box>
    </SectionContainer>
  );
};

export default SearchPage;

import { AppThunk } from "../index";
import { Status } from "../system/types";
import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import {
  LOAD_VIRTUAL_SHOWROOM_DATA,
  SET_VIRTUAL_SHOWROOM_STATUS,
  VirtualShowroom,
  VirtualShowroomAction,
} from "./types";

// Sync Actions
const setVirtualShowroomStatus = (status: Status): VirtualShowroomAction => ({
  type: SET_VIRTUAL_SHOWROOM_STATUS,
  payload: { status },
});

const loadVirtualShowroomData = (data: VirtualShowroom[]): VirtualShowroomAction => ({
  type: LOAD_VIRTUAL_SHOWROOM_DATA,
  payload: { data },
});

// Async Actions
/**
 *
 * @description Fetches the Showrooms from the database
 */
export const fetchVirtualShowrooms = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setVirtualShowroomStatus("fetching"));

    const response = await apiClient.request({
      url: apiEndpoints.virtualShowroom,
    });

    dispatch(loadVirtualShowroomData(response.data));
  } catch (err: any) {
    dispatch(setVirtualShowroomStatus("error"));
    errorHandler(err, dispatch);
  }
};

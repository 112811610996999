import { Link, useLocation } from "react-router-dom";
import { Container, Divider, Box, Stack, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/LocalPhoneRounded";

import { appColors } from "../../theme";
import logo from "../../assets/images/login-logo.png";

const Trunkline = () => {
  const location = useLocation();
  if (location.pathname === "/hcbt-elite") return null;

  return (
    <Box
      bgcolor={appColors.blueHeader}
      color='white'
      overflow='hidden'
      borderBottom={`1px solid ${appColors.mediumGray}`}
      position='relative'
    >
      <Stack
        sx={{
          zIndex: 3,
          display: { xs: "none", md: "flex" },
          position: "absolute",
          bgcolor: "black",
          top: 0,
          right: -80,
          width: { xs: 230, sm: 270, md: 350, lg: 420 },
          height: "100%",
          transform: "skewX(-20deg)",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          sx={{
            marginRight: "80px",
            transform: "skewX(20deg)",
            display: { xs: "none", md: "flex" },
            "& img": { height: { xs: 18, md: 32 } },
          }}
        >
          <img src={logo} alt='Honda Cars Batangas' style={{ width: "auto" }} />
        </Stack>
      </Stack>
      <Container
        maxWidth='lg'
        sx={{
          height: { xs: 60, sm: 75 },
          display: "flex",
          alignItems: "center",
          gap: { xs: 0, md: 4 },
          position: "relative",
        }}
      >
        <Stack display={{ xs: "none", md: "none" }}>
          <Link to='/home' aria-label='home' style={{ display: "flex", alignItems: "center" }}>
            <img src={logo} alt='Honda Cars Batangas' style={{ height: 34, width: "auto" }} />
          </Link>
        </Stack>
        <Divider
          orientation='vertical'
          sx={{ bgcolor: appColors.mediumGray, height: 50, display: { xs: "none", md: "none" } }}
        />
        <Stack flex={{ xs: 1, md: "auto" }} alignItems={{ xs: "center", md: "start" }}>
          <Typography fontSize={{ xs: 15, sm: 18 }} fontWeight='bolder'>
            Honda Cars Batangas
          </Typography>
          <Typography fontSize={{ xs: 9, sm: 11 }} color={appColors.lightGray}>
            Diversion Road, Bolbok, Batangas City
          </Typography>
        </Stack>
        <Stack
          sx={{
            display: "none", // hidden for a moment
            position: "absolute",
            bgcolor: "black",
            top: 0,
            right: -100,
            width: { xs: 220, sm: 280 },
            height: "100%",
            transform: "skewX(-20deg)",
          }}
        />
        <Stack ml='auto' zIndex={1} sx={{ transform: { lg: "translateX(20px)" } }}>
          <Typography
            color='primary.main'
            fontSize={{ xs: 11, sm: 14 }}
            sx={{ display: "none", alignItems: "center", gap: 1 }}
          >
            <PhoneIcon
              sx={{
                color: "white",
                bgcolor: "primary.main",
                borderRadius: "50%",
                boxSizing: "unset",
                fontSize: "inherit",
                p: 0.3,
              }}
            />
            Contact us
          </Typography>
          <Typography
            fontSize={{ xs: 12, sm: 14 }}
            color={appColors.lightGray}
            sx={{ display: "none" }}
          >
            (043) 702-9376
          </Typography>
          {/* <Stack
            sx={{
              display: { xs: "none", md: "flex" },
              transform: { lg: "translateX(30px)" },
              "& img": { height: { xs: 18, md: 32 } },
            }}
          >
            <Link to='/' aria-label='home' style={{ display: "flex", alignItems: "center" }}>
              <img src={logo} alt='Honda Cars Batangas' style={{ width: "auto" }} />
            </Link>
          </Stack> */}
        </Stack>
      </Container>
    </Box>
  );
};

export default Trunkline;

// import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAuthCustomer } from "../../store/auth/selectors";
// import { fetchElitePoints } from "../../store/elite/actions";
// import { selectEliteData } from "../../store/elite/selectors";

import { Stack, Box, Typography, Divider } from "@mui/material";
import { appColors } from "../../theme";
import InfoIcon from '@mui/icons-material/Info';
import EliteAccountMenu from "./EliteAccountMenu";
import cardImage from '../../assets/images/card.png';

const EliteCard = () => {
  // const dispatch = useDispatch();
  const auth = useSelector(getAuthCustomer);
  // const elite = useSelector(selectEliteData);

  // useEffect(() => {
  //   dispatch(fetchElitePoints(auth?.email))
  // }, [dispatch, auth])

  return (
    <Stack 
      bgcolor={appColors.softGray}
      minHeight='100vh'
      sx={{ padding: { xs: 2, sm: 4, md: "48px 120px" }  }}
    >
      <Stack bgcolor="#fff" direction="column">
        <Stack 
          sx={{ 
            flexDirection: { xs: "column", sm: "row" }, 
            alignItems: { xs: "start", sm: "center" }
          }} 
          px={5} 
          py={3} 
          gap={1}
          justifyContent="space-between"
        >
          <Typography fontSize={30} fontWeight={600}>
            HCBT Elite Card
          </Typography>
          <EliteAccountMenu />
        </Stack>
        <Divider />
        <Stack  
          sx={{ flexDirection: { xs: "column", sm: "row" } }} 
          padding={4} 
          my={7} 
          justifyContent="space-around" 
          alignItems="center"
        >
          <Box width="25%">
            <img src={cardImage} style={{ width: "100%" }} alt=""/>
          </Box>
          <Stack textAlign="center" sx={{ width: { sm: "45%" } }}>
            <Stack direction="row" alignItems="baseline" justifyContent="center">
              <Typography color={appColors.primary} fontSize={120} fontWeight={600} lineHeight={1}>
                {/* { elite.length > 0 ? elite[0].redeemablePoints : 0 } */}
                {auth && auth.redeemablePoints}
              </Typography>
              <Typography color={appColors.primary} fontSize={20} lineHeight={1}>Points</Typography>
            </Stack>
            <Typography fontSize={35} fontWeight={600}>
              Your card points for now
            </Typography>
            <Typography color={appColors.grey} fontSize={20}>
              <InfoIcon sx={{ color: appColors.primary, position: "relative", top: "5px", right: "4px" }} />
              Redeemable points earned for a transaction takes 48 hours to be reflected on your account
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default EliteCard;
import { useTheme } from "@mui/system";

interface VSCircularProgressProps {
  size?: number;
  trackWidth?: number;
  progress: number;
}

const VSCircularProgress = ({ size = 80, trackWidth = 6, progress }: VSCircularProgressProps) => {
  const center = size / 2;
  const radius = center - trackWidth;

  const arcLength = 2 * Math.PI * radius;
  const arcOffset = 2 * Math.PI * radius * ((100 - progress) / 100);

  const theme = useTheme();

  return (
    <div style={{ position: "relative" }}>
      <span
        style={{
          position: "absolute",
          top: center,
          left: center,
          fontWeight: 500,
          fontSize: 17,
          zIndex: 2,
          transform: "translate(-50%, -50%)",
        }}
      >
        {progress}%
      </span>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox={`0 0 ${size} ${size}`}
        width={size}
        height={size}
        style={{ transform: "rotate(-90deg)" }}
      >
        <circle
          r={radius}
          cx={center}
          cy={center}
          strokeWidth={trackWidth}
          fill='#fff'
          stroke='#eee'
        />
        <circle
          r={radius}
          cx={center}
          cy={center}
          strokeWidth={trackWidth * 1.1}
          strokeDasharray={arcLength}
          strokeDashoffset={arcOffset}
          stroke={theme.palette.primary.main}
          fill='none'
        />
      </svg>
    </div>
  );
};

export default VSCircularProgress;

import type { AppState } from "../index";

export const selectCareerListState = (state: AppState) => state.careers;

export const selectCareerData = (state: AppState) => state.careers.data;

export const selectCareersById = (id: string) => (state: AppState) =>
  state.careers.data.find((i) => i.id === id);

export const selectCareersForSearch = (state: AppState) => 
  state.careers.data.map((i) => { 
    return  { 
      id: i.id, 
      module: "Careers", 
      title: i.name,
      content: i.content,
      link: `/careers/${i.id}` 
    }
  });
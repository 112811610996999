import { Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { Infospot } from "../../../store/virtual-showroom/types";

const InfospotCardContainer = styled("div")({
  display: "none",
  marginLeft: "250px",
  marginTop: "150px",
  position: "relative",
  zIndex: 4,

  "&::before": {
    content: '""',
    position: "absolute",
    bottom: 150,
    left: -80,
    width: 100,
    height: 2,
    // border: "1px dashed white",
    transform: "translateY(-50%)",
  },
});

interface VSInfospotCardProps {
  infospot: Infospot;
}

const VSInfospotCard = ({ infospot }: VSInfospotCardProps) => {
  const { banner, content, title } = infospot;

  return (
    <InfospotCardContainer id={title.toLowerCase().split(" ").join("-")} className='infospot'>
      <Card
        sx={{
          width: 360,
          p: 3,
          pb: 0,
        }}
      >
        <Stack width='100%'>
          <img
            src={banner}
            alt={title}
            style={{
              width: "100%",
              aspectRatio: "16 / 9",
              objectFit: "cover",
              backfaceVisibility: "hidden",
              transform: "translateZ(0)",
              imageRendering: "-webkit-optimize-contrast",
              verticalAlign: "middle",
            }}
          />
        </Stack>
        <CardContent sx={{ px: 0 }}>
          <Typography fontWeight='500' fontFamily='Roboto'>
            {title}
          </Typography>
          <Typography fontSize={13} fontFamily='Roboto' color='text.secondary'>
            {content}
          </Typography>
        </CardContent>
      </Card>
    </InfospotCardContainer>
  );
};

export default VSInfospotCard;

import { AxiosResponse } from "axios";
import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { Status } from "../system/types";
import {
  SET_QUICK_LINKS_STATUS,
  SET_QUICK_LINKS_ERROR,
  LOAD_QUICK_LINKS_DATA,
  IQuickLinks,
  QuickLinksState,
  QuickLinksAction,
} from "./types";

// Sync Actions
const setQuickLinksStatus = (status: Status): QuickLinksAction => ({
  type: SET_QUICK_LINKS_STATUS,
  payload: { status }
});

const setQuickLinksError = (error: QuickLinksState["error"]): QuickLinksAction => ({
  type: SET_QUICK_LINKS_ERROR,
  payload: { error },
});

const loadQuickLinksData = (data: IQuickLinks[]): QuickLinksAction => ({
  type: LOAD_QUICK_LINKS_DATA,
  payload: { data }
});

export const fetchQuickLinks = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setQuickLinksStatus("loading"));

    const response: AxiosResponse<any> = await apiClient.request({
      url: apiEndpoints.quickLinks,
    });

    const data: IQuickLinks = response.data.quickLinks;

    dispatch(loadQuickLinksData([data]));
  } catch (err:any) {
    dispatch(setQuickLinksStatus("error"));
    errorHandler(err, dispatch);
  }
}
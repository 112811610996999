import Meta from '../../components/common/Meta';
import SectionContainer from '../../components/layout/SectionContainer';
import PartsAndService from './PartsAndService';
import PartsAndServiceBanner from './PartsAndServiceBanner';
const PartsAndServicePage = () => {
  return (
    <>
      <PartsAndServiceBanner />
      <SectionContainer>
        <Meta title='Services' />
        <PartsAndService />
      </SectionContainer>
    </>
  );
};

export default PartsAndServicePage;

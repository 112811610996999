import { AppState } from "../index";

export const selectTestimonialState = (state: AppState) => state.testimonial;

export const selectTestimonialData = (state: AppState) => state.testimonial.data;

export const selectTestimoniesForSearch = (state: AppState) => 
  state.testimonial.data?.map((i) => { 
    return  { 
      id: i.id, 
      module: "Testimonies", 
      title: `${i.fName} ${i.lName}`,
      content: i.content,
      link: `/` 
    }
  });
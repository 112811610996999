import { Box, Tab, Tabs } from "@mui/material";
import useCurrentModel from "../../utils/useCurrentModel";
import { styled } from "@mui/system";
import { useState } from "react";
import SpecificationsDetails from "./SpecificationsDetails";
import { appColors } from "../../../../theme";

const VariantTab = styled(Tab)({
  color: "#222",
  fontSize: 15,
  fontWeight: "bold",
  textTransform: "capitalize",
  padding: "12px",
  border: "0px solid transparent",
  borderTopColor: "#eee",

  "&.Mui-selected": {
    borderColor: "#eee",
    borderTopColor: "transparent",
    position: "relative",
  },
});

function Specifications() {
  const model = useCurrentModel();
  const [currentTab, setCurrentTab] = useState(0);

  if (!model) return null;
  const { variant } = model;

  return (
    <>
      <Box borderBottom={`1px solid ${appColors.lightestGray}`} mt={2}>
        <Tabs
          value={currentTab}
          onChange={(e, v) => setCurrentTab(v)}
          aria-label='variant tabs'
          variant='scrollable'
          visibleScrollbar={false}
        >
          {variant.map((v) => (
            <VariantTab key={v.name} label={v.name} />
          ))}
        </Tabs>
      </Box>
      <SpecificationsDetails specs={variant[currentTab].specs} />
    </>
  );
}

export default Specifications;

import {
  PromoAction,
  PromoState,
  SET_PROMO_STATUS,
  LOAD_ALL_PROMO_DATA,
  LOAD_PROMO_DATA,
  SET_PROMO_ERROR,
  LOAD_MORE_PROMO_DATA,
  SET_PROMO_SORTBY,
} from "./types";

const initialState: PromoState = {
  status: "idle",
  error: undefined,
  data: [],
  allData: [],
  sortBy: "startDate:desc",
};

const promoReducer = (state = initialState, action: PromoAction): PromoState => {
  switch (action.type) {
    case SET_PROMO_STATUS:
      return { ...state, status: action.payload.status };
    case SET_PROMO_ERROR:
      return { ...state, status: "error", error: action.payload.error };
    case LOAD_ALL_PROMO_DATA:
      return { ...state, allData: action.payload.allData, status: "idle" };
    case LOAD_PROMO_DATA:
      return { ...state, data: action.payload.data, status: "idle" };
    case LOAD_MORE_PROMO_DATA:
      return { ...state, data: [...state.data, ...action.payload.data], status: "idle" };
    case SET_PROMO_SORTBY:
      return { ...state, sortBy: action.payload.sortBy };
    default:
      return state;
  }
};

export default promoReducer;

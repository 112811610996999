import { AppError, IFeatureState, Status } from "../system/types";

export interface IPartsAndAccessories {
  id: string;
  name: string;
  mediaUrl: string;
  content: string;
  type: "All" | "Part" | "Accessory" | "Others";
  dateCreated: Date;
  dateUpdated: Date;
  isEnabled: boolean;
  chassisNumber: string;
}

export interface PartsAndAccessoriesState extends IFeatureState<IPartsAndAccessories> {
  filter: IPartsAndAccessories["type"];
  allData: IPartsAndAccessories[];
}

export const SET_PARTS_AND_ACCESSORIES_STATUS = "parts_and_accessories/status/set";
export const SET_PARTS_AND_ACCESSORIES_ERROR = "parts_and_accessories/error/set";
export const SET_PARTS_AND_ACCESSORIES_FILTER = "parts_and_accessories/filter/set";
export const LOAD_PARTS_AND_ACCESSORIES_DATA = "parts_and_accessories/data/load";

interface SetPartsAndAccessoriesStatusAction {
  type: typeof SET_PARTS_AND_ACCESSORIES_STATUS;
  payload: { status: Status };
}

interface SetPartsAndAccessoriesErrorAction {
  type: typeof SET_PARTS_AND_ACCESSORIES_ERROR;
  payload: { error: AppError };
}

interface SetPartsAndAccessoriesDataAction {
  type: typeof LOAD_PARTS_AND_ACCESSORIES_DATA;
  payload: { data: PartsAndAccessoriesState["data"] };
}

interface SetPartsAndAccessoriesFilterAction {
  type: typeof SET_PARTS_AND_ACCESSORIES_FILTER;
  payload: { filter: PartsAndAccessoriesState["filter"] };
}

export type PartsAndAccessoriesListAction =
 | SetPartsAndAccessoriesStatusAction
 | SetPartsAndAccessoriesErrorAction
 | SetPartsAndAccessoriesDataAction
 | SetPartsAndAccessoriesFilterAction
import { Box, Divider, Stack, Typography } from "@mui/material";
import { forwardRef } from "react";
import { useSelector } from "react-redux";
import { selectModelVariants } from "../../../../store/models/selectors";
import { convertToPhp } from "../../../../utils/convert-currency";
import specsCategories from "./specs.json";
import logo from "../../../../assets/images/login-logo.png";

const CompareModelsPrintView = forwardRef((props, ref) => {
  const { selectedModels } = useSelector(selectModelVariants);

  const modelsToDisplay = selectedModels.filter((m) => m.id !== "");

  const getSpecValue = (category: string, specLabel: string, modelIndex: number) => {
    return (
      modelsToDisplay[modelIndex]?.variant[0]?.specs
        .find((s) => s.category === category)
        ?.items.find((sp) => sp.label === specLabel)?.value || "-"
    );
  };

  return (
    <Stack
      key={modelsToDisplay.length}
      display='none'
      displayPrint='flex'
      id='compare-model-print'
      ref={ref}
      px={4}
      py={2}
      mb={2}
    >
      <Stack direction='row' mb={4}>
        <img src={logo} alt='Honda Cars Batangas' style={{ height: 32, width: "auto" }} />
        <Stack ml='auto'>
          <Typography fontSize={{ xs: 15, sm: 18 }} fontWeight='bolder'>
            Honda Cars Batangas
          </Typography>
          <Typography fontSize={{ xs: 9, sm: 11 }}>
            Diversion Road, Bolbok, Batangas City
          </Typography>
        </Stack>
      </Stack>
      <Typography fontSize={24} fontWeight='bold'>
        Compare Models
      </Typography>
      <Divider sx={{ my: 2 }} />
      {modelsToDisplay.length > 0 && (
        <Box display='grid' gridTemplateColumns='repeat(3, 1fr)' gap={4}>
          {modelsToDisplay.map((model, index) => (
            <Stack alignItems='center' width='100%' key={model.id + index.toString()}>
              <Stack width='100%' alignItems='center'>
                <img
                  width='80%'
                  style={{ aspectRatio: "16 / 9", objectFit: "cover" }}
                  src={model.imagePreviewUrl}
                  alt={model.name}
                />
              </Stack>
              <Typography fontWeight='bold'>{model.name}</Typography>
              <Typography fontSize={14}>{model.variant[0]?.name}</Typography>
              <Typography fontSize={14}>{convertToPhp(model.variant[0]?.summary.price)}</Typography>
            </Stack>
          ))}
        </Box>
      )}
      <Typography fontSize={24} fontWeight='bold' sx={{ mt: 4 }}>
        Specifications Comparison
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Stack gap={2}>
        {specsCategories.map((spec, index) => (
          <Stack key={spec.category + index.toString()}>
            <Typography fontSize={18} fontWeight='bold' mb={1}>
              {spec.category}
            </Typography>
            {spec.items.map((specItem, itemIndex) => (
              <Box
                key={"box-" + itemIndex.toString() + specItem.label}
                py={1.3}
                borderTop='1px solid #eee'
                display='grid'
                gridTemplateColumns='350px 150px 150px 150px'
              >
                <Typography fontSize={14}>{specItem.label}</Typography>
                {selectedModels.map((model, modelIndex) => (
                  <Typography key={modelIndex + specItem.label} fontSize={14}>
                    {getSpecValue(spec.category, specItem.label, modelIndex)}
                  </Typography>
                ))}
              </Box>
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
});

export default CompareModelsPrintView;

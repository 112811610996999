import type { AppState } from "../index";

export const selectPartsAndAccessoriesListState = (state: AppState) => state.partsAndAccessories;

export const selectPartsAndAccessoriesData = (state: AppState) => state.partsAndAccessories.data;

export const selectPartsAndAccessoriesForSearch = (state: AppState) => 
  state.partsAndAccessories.data.map((i) => { 
    return  { 
      id: i.id, 
      module: "Parts & Accessories", 
      title: i.name,
      content: i.content,
      link: `/parts-and-accessories/${i.id}` 
    }
  });
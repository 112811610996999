import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { getSystemSnackbar } from '../../store/system/selectors';
import { Stack } from '@mui/material';
import EliteLogin from './EliteLogin';
import EliteCard from './EliteCard';
import AuthenticatedRoute from '../../components/common/AuthenticatedRoute';
import WebAlert from '../../components/common/WebAlert';
import Meta from '../../components/common/Meta';

const ElitePage = () => {
  const snackbar = useSelector(getSystemSnackbar);

  return (
    <Stack minHeight='100vh'>
      <Meta title='Elite Card' />
      <Switch>
        <AuthenticatedRoute path='/hcbt-elite/card'>
          <EliteCard />
        </AuthenticatedRoute>
        <Route exact path='/hcbt-elite'>
          <EliteLogin />
        </Route>
      </Switch>
      {snackbar && (
        <WebAlert
          message={snackbar?.message as string}
          open={snackbar?.open}
          type={snackbar?.type}
          autoHideDuration={3000}
          onClose={() => {}}
        />
      )}
    </Stack>
  );
};

export default ElitePage;

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchModels, setModelVariants } from "../../../../store/models/actions";
import { selectModelVariants } from "../../../../store/models/selectors";
import { IModels } from "../../../../store/models/types";

import { Stack, Avatar, Typography } from "@mui/material";
import { HondaButton } from "./CompareModelComponents";
import Variant from "./CompareVariant";

interface ModelProps {
  model: IModels | undefined;
}

const CompareModel = ({ model }: ModelProps) => {
  const models = useSelector(selectModelVariants)
  const [checked, setChecked] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = () => {
    models.selectedModels[models.selectedModelIndex] = model!
    models.selectedModels[models.selectedModelIndex].variant = model!.variant.filter(v => v.name === checked)
    dispatch(setModelVariants({
      ...models,
      selectedVariants: model!.variant.filter(v => v.name === checked)
    }))
    dispatch(fetchModels())
    history.push("/models/compare-models");
  }

  return (
    <Stack alignItems='center'>
      <Avatar 
        src={model!.imagePreviewUrl} 
        variant='square' 
        sx={{ width: 200, height: 200, 'img': { objectFit: 'contain' } }} 
      />
      <Typography fontSize={16} fontWeight={500} mb={2}>
        Choose Car Variant:
      </Typography>
      <Stack gap={2} width='100%' alignItems='center'>
        { model?.variant.map(variant => <Variant key={variant.name} variant={variant} setChecked={setChecked} checked={checked} /> ) }
        <HondaButton disabled={!checked} marginy={3} onClick={handleClick}>Add Model</HondaButton>
      </Stack>
    </Stack>
  )
}

export default CompareModel
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useDispatch, useSelector } from "react-redux";
import { Stack, useTheme, useMediaQuery, Box } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import { NextButton, PrevButton } from "./ui";
import TestimonialItem from "./TestimonialItem";
import { selectTestimonialData } from "../../../store/testimonial/selectors";
import { useEffect } from "react";
import { fetchTestimonialList } from "../../../store/testimonial/actions";
import { TestimonialBox } from "./TestimonialStyle";
import SectionTitle from "../../../components/common/SectionTitle";
import { CarouselIndicator } from "../../model/model-ui/model-content-carousel/ui";
import useIsTabletOrMobile from "../../../hooks/useIsTabletOrMobile";

const TestimonialsCarousel = () => {
  const dispatch = useDispatch();
  const testimonials = useSelector(selectTestimonialData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTabletOrMobile = useIsTabletOrMobile();

  useEffect(() => {
    dispatch(fetchTestimonialList());
  }, [dispatch]);

  if (!testimonials) return null;

  return (
    <Box
      mb={10}
      paddingBottom={{
        xs: testimonials ? (testimonials.length > 1 ? 5 : 0) : 0,
        md: 10,
      }}
      sx={{
        "& .control-dots": {
          bottom: -50,
        },
      }}
    >
      <SectionTitle title="Testimonials" />
      <TestimonialBox count={testimonials.length}>
        <Stack className="testimonials-carousel" gap={5}>
          <Carousel
            showIndicators={
              testimonials
                ? (testimonials.length > 3 && !isMobile) ||
                  (testimonials.length >= 2 && isMobile)
                  ? true
                  : false
                : false
            }
            selectedItem={0}
            showStatus={false}
            showThumbs={false}
            infiniteLoop={testimonials.length > 3 ? true : false}
            centerMode={true}
            centerSlidePercentage={
              isTabletOrMobile 
              ? 100 
              : 100 / (testimonials ? (testimonials.length === 2 ? 2 : 3) : 3)
            }
            renderArrowNext={(onClick, hasNext, label) =>
              testimonials.length > 3 &&
              !isMobile && (
                <NextButton
                  onClick={onClick}
                  hasNext={!isMobile ? hasNext : false}
                  label={label}
                />
              )
            }
            renderArrowPrev={(onClick, hasPrev, label) =>
              testimonials.length > 3 &&
              !isMobile && (
                <PrevButton
                  onClick={onClick}
                  hasPrev={!isMobile ? hasPrev : false}
                  label={label}
                />
              )
            }
            renderIndicator={(clickHandler, isSelected, index, label) => (
              <CarouselIndicator
                clickHandler={clickHandler}
                isSelected={isSelected}
                index={index}
                label={label}
              />
            )}
          >
            {testimonials?.map((t, index) => {
              return (
                <TestimonialItem
                  count={
                    testimonials.length && !isMobile ? testimonials.length : 0
                  }
                  index={index}
                  key={t.id || index}
                  testimonials={t}
                />
              );
            })}
          </Carousel>
        </Stack>
      </TestimonialBox>
    </Box>
  );
};

export default TestimonialsCarousel;

import type { AppError, IFeatureState, Status } from "../system/types";

export interface IQuickLinks {
  id: string;
  promosMedia: string;
  vehicleSalesMedia: string;
  serviceAppointmentMedia: string;
  partsMedia: string;
  dateCreated: Date;
  dateUpdated: Date;
}

export type QuickLinksState = IFeatureState<IQuickLinks>;

export const SET_QUICK_LINKS_STATUS = "quick_links/status/set";
export const SET_QUICK_LINKS_ERROR = "quick_links/error/set";
export const LOAD_QUICK_LINKS_DATA = "quick_links/data/load";

interface SetQuickLinksStatusAction {
  type: typeof SET_QUICK_LINKS_STATUS;
  payload: { status: Status };
}

interface SetQuickLinksErrorAction {
  type: typeof SET_QUICK_LINKS_ERROR;
  payload: { error: AppError };
}

interface SetQuickLinksDataAction {
  type: typeof LOAD_QUICK_LINKS_DATA;
  payload: { data: QuickLinksState["data"] };
}

export type QuickLinksAction = 
  | SetQuickLinksStatusAction
  | SetQuickLinksErrorAction
  | SetQuickLinksDataAction;
import { ModelVariant } from "../../../../store/models/types";

import { Checkbox, Stack, Typography } from "@mui/material";
import { CompareVariantStyle, CompareVariantDetails, CompareVariantText } from "./CompareModelComponents";

interface CompareItemProps {
  variant: ModelVariant;
  setChecked: (name: string) => void;
  checked: string;
}

const CompareVariant = ({ variant, setChecked, checked }: CompareItemProps) => {
  return (
    <Stack component='section' onClick={() => setChecked(variant.name)} key={variant.name} sx={CompareVariantStyle}>
      <CompareVariantDetails sx={{ width: { sm: "40%" } }}>
        <CompareVariantText>
          Variant
        </CompareVariantText>
        <Typography>
          {variant.name}
        </Typography>
      </CompareVariantDetails>
      <CompareVariantDetails gap={0}>
        <CompareVariantText>
          Price
        </CompareVariantText>
        <Typography fontSize={17} fontWeight={600}>
          {Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'PHP',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
          }).format(Number(!!Number(variant.summary.price) ? variant.summary.price : 0)).replace("₱", "PHP ")}
        </Typography>
      </CompareVariantDetails>
      <Stack gap={0}>
        <Checkbox checked={checked === variant.name}/>
      </Stack>
    </Stack>
  );
};

export default CompareVariant;
import type { ReactNode } from "react";
import { ReactComponent as PromoIcon } from "../../../assets/images/promos-icon.svg";
import { ReactComponent as PartsInquiryIcon } from "../../../assets/images/parts-inquiry-icon.svg";
import { ReactComponent as ServiceAppointmentIcon } from "../../../assets/images/service-appointment-icon.svg";
import { ReactComponent as VehiclesSalesIcon } from "../../../assets/images/vehicles-sales-icon.svg";

import promosSrc from "../../../assets/images/promos.png";
import vehicleSalesSrc from "../../../assets/images/inquiry.png";
import partsSrc from "../../../assets/images/parts-inquiry.png";
import serviceSrc from "../../../assets/images/service-appointment.png";

export type QuickLink = {
  title: string;
  icon: ReactNode;
  image: string;
};

export const quickLinkItems: QuickLink[] = [
  {
    title: "Promos",
    icon: <PromoIcon />,
    image: promosSrc,
  },
  {
    title: "Vehicles Sales Inquiry",
    icon: <VehiclesSalesIcon />,
    image: vehicleSalesSrc,
  },
  {
    title: "Service Appointment",
    icon: <ServiceAppointmentIcon />,
    image: serviceSrc,
  },
  {
    title: "Parts Inquiry",
    icon: <PartsInquiryIcon />,
    image: partsSrc,
  },
];

import {
    SET_NAV_CONFIG_STATUS,
    LOAD_NAV_CONFIG_DATA,
    NavConfigListState,
    NavConfigListAction
} from './types';


const initialState: NavConfigListState = {
    status: 'idle',
    error: undefined,
    data: []
}

const navConfigReducer = (state = initialState, action: NavConfigListAction): NavConfigListState => {
    switch(action.type) {
      case SET_NAV_CONFIG_STATUS:
        return { ...state, status: action.payload.status }
      case LOAD_NAV_CONFIG_DATA:
        return { ...state, data: action.payload.data, status: 'idle' }
      default:
        return state;
    }
}

export default navConfigReducer;
import { useSelector } from "react-redux";
import { selectPartsAndAccessoriesListState } from "../../store/parts-and-accessories/selectors";
import { partsAndAccessoriesFetcher } from "../../store/parts-and-accessories/actions";

import { useQuery } from "@tanstack/react-query";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PartsAndAccessoriesItem from "./PartsAndAccessoriesItem";
import FetchingLoadingIndicator from "../../components/common/FetchingLoadingIndicator";

const PartsAndAccessoriesList = () => {
  const { filter } = useSelector(selectPartsAndAccessoriesListState);

  const { isLoading, isFetching, data } = useQuery(
    [filter],
    async () => await partsAndAccessoriesFetcher(filter),
    { keepPreviousData: true }
  );

  if (isLoading || isFetching)
    return (
      <Stack minHeight="45vh">
        <FetchingLoadingIndicator />
      </Stack>
    );
  
  const partsAndAccessoriesData = data?.partsAndAccessories;
  const partsAndAccessoriesCount = data?.partsAndAccessories.length;

  if (partsAndAccessoriesCount === 0) {
    return (
      <Typography textAlign="center" mt={5} mb={20} fontWeight={600}>
        No available data as of the moment.
      </Typography>
    )
  }

  return (
    <Stack gap={1.7} mt={2} mb={10}>
      { partsAndAccessoriesData?.map(item => (
          <PartsAndAccessoriesItem key={item.id} partsAndAccessories={item} />
        ))
      }
    </Stack>
  )
}

export default PartsAndAccessoriesList;
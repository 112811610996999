import { useEffect, useState } from 'react';
import { Button, Collapse, Container, Stack } from '@mui/material';
import { appColors } from '../../../../theme';
import { ButtonStyle, ContainerStyle } from './ModelNavStyle';
import useCurrentModel from '../../utils/useCurrentModel';
import ModelNavItem from './ModelNavItem';
import ExpandIcon from '@mui/icons-material/ExpandMore';
import CollapseIcon from '@mui/icons-material/ExpandLess';

const modelNavs = [
  {
    sectionId: 'model-design',
    navLabel: 'Design',
  },
  {
    sectionId: 'model-color',
    navLabel: 'Model Color',
  },
  {
    sectionId: 'model-performance',
    navLabel: 'Performance',
  },
  {
    sectionId: 'model-safety',
    navLabel: 'Safety',
  },
  {
    sectionId: 'model-accessories',
    navLabel: 'Accessories',
  },
  {
    sectionId: 'model-variants',
    navLabel: 'Variants',
  },
  {
    sectionId: 'model-specs',
    navLabel: 'Specification',
  },
];

const ModelNav = () => {
  const [activeNav, setActiveNav] = useState<string | null>(null);
  const [mobileNavExpanded, setMobileNavExpanded] = useState(false);
  const model = useCurrentModel();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const scrollEventCallback = () => {
      const sections = document.querySelectorAll('.model-section') as NodeListOf<HTMLElement>;
      const navListItems = document.querySelectorAll('.model-nav');

      let currentNavId: string | null = null;
      sections.forEach((section) => {
        const boundingRect = section.getBoundingClientRect();
        const { top, bottom, height } = boundingRect;
        if (top < 150 && bottom <= 1.5 * height) {
          const sectionId = section.getAttribute('id');
          currentNavId = sectionId;
        }
      });

      setActiveNav(currentNavId);

      navListItems.forEach((item) => {
        item.classList.remove('active');
        const navItemId = item.getAttribute('id')?.split('-').slice(1, 3).join('-');
        if (navItemId === activeNav) {
          item.classList.add('active');
        }
      });
    };

    window.addEventListener('scroll', scrollEventCallback);

    scrollEventCallback();

    return () => {
      window.removeEventListener('scroll', scrollEventCallback);
    };
  }, [activeNav]);

  const toggleMobileNav = () => setMobileNavExpanded((expanded) => !expanded);

  return (
    <Stack
      bgcolor={appColors.black}
      color='white'
      position='sticky'
      top={62}
      height={64}
      boxShadow='0px 6px 15px rgba(0,0,0,0.2)'
      zIndex={3}
    >
      <Container maxWidth='lg' sx={ContainerStyle}>
        {model && (
          <Stack
            onClick={scrollToTop}
            role='button'
            width={115}
            height={55}
            sx={{ cursor: 'pointer' }}
          >
            <img src={model.unitLogoUrl} alt={model.name} />
          </Stack>
        )}
        <Stack
          id='model-nav'
          component='ul'
          direction='row'
          alignItems='center'
          ml='auto'
          my={0}
          gap={2}
          display={{ xs: 'none', md: 'flex' }}
        >
          {modelNavs.map((item) => (
            <ModelNavItem
              key={item.sectionId}
              sectionId={item.sectionId}
              label={item.navLabel}
              onClick={setActiveNav}
              isActive={item.sectionId === activeNav}
            />
          ))}
        </Stack>
        <Button
          disableElevation
          disableRipple
          disableFocusRipple
          onClick={toggleMobileNav}
          sx={ButtonStyle}
          endIcon={mobileNavExpanded ? <CollapseIcon /> : <ExpandIcon />}
        >
          {mobileNavExpanded ? 'Hide' : 'Explore'}
        </Button>
      </Container>
      <Stack position='absolute' top='100%' width='100%'>
        <Collapse in={mobileNavExpanded} timeout='auto' unmountOnExit>
          <Stack bgcolor='black' px={2} pb={4}>
            {modelNavs.map((item) => (
              <ModelNavItem
                key={item.sectionId}
                sectionId={item.sectionId}
                label={item.navLabel}
                onClick={(nav) => {
                  setActiveNav(nav);
                  setMobileNavExpanded(false);
                }}
                isActive={item.sectionId === activeNav}
              />
            ))}
          </Stack>
        </Collapse>
      </Stack>
    </Stack>
  );
};

export default ModelNav;

import { AppThunk } from "../index";

import {
  SET_SYSTEM_STATE,
  SystemInputInterface,
  SystemInputType,
  SystemAction,
  Snackbar,
  SET_SNACKBAR,
} from './types';

export const setSystemState = (newState: SystemInputInterface<SystemInputType>): SystemAction => ({
  type: SET_SYSTEM_STATE,
  payload: newState
})

export const systemRedirect = (to: string): SystemAction => ({
  type: SET_SYSTEM_STATE,
  payload: { redirectTo: to }
})

export function setSnackbar(snackbar: Snackbar | null): SystemAction {
  return {
    type: SET_SNACKBAR,
    payload: snackbar,
  };
}

export const setSnackbarAsync =
  (snackbar: Snackbar | null): AppThunk =>
  async (dispatch) => {
    dispatch(setSnackbar(snackbar));
    setTimeout(() => {
      dispatch(setSnackbar(null));
    }, 3000);
  };

export const showSuccessSnackbar =
  (message: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      setSnackbar({
        open: true,
        type: "success",
        message,
      })
    );
    setTimeout(() => {
      dispatch(setSnackbar(null));
    }, 3000);
  };

export const showErrorSnackbar =
  (errorMessage: string): AppThunk =>
  async (dispatch) => {
    dispatch(
      setSnackbar({
        open: true,
        type: "error",
        message: errorMessage,
      })
    );
    setTimeout(() => {
      dispatch(setSnackbar(null));
    }, 3000);
  };
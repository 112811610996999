import React, { Fragment, useState } from "react";
import { useSelector } from 'react-redux';
import { Link, useLocation } from "react-router-dom";

import { filterModels } from '../../store/models/selectors';
import { NavConfigItem } from "../../store/nav-config/types";
import { selectNavConfigData } from "../../store/nav-config/selectors";

import { Box, Container, Stack } from "@mui/material";

import NavLinkItem, { VirtualShowroomNavLink } from "./NavLinkItem";
import SearchButton from "../common/SearchButton";

import { appColors } from "../../theme";
import { navItemsv3 as navItems} from "../../constants";
import logo from "../../assets/images/login-logo.png";
import MenuButton from "../common/MenuButton";
import SearchBar from "../common/SearchBar";
import MobileNav from "./MobileNav";

import Typography from '@mui/material/Typography';
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import { uniqueId } from "lodash";

const NavBar = () => {
  const data = useSelector(filterModels('All'));
  const navConfigData = useSelector(selectNavConfigData);
  const location = useLocation();
  const [searchBarShown, setSearchBarShown] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hoveredNav, setHoveredNav] = useState("");
  const openCounterDefault = Object.assign({}, ...navConfigData.map(({name}) => ({[`${name}`]: false})));
  const [anchorEls, setAnchorEls] = useState<{[key: string]: HTMLElement | null}>({});
  const [isOpenCounter, setIsOpenCounter] = useState<{[key: string]: boolean}>({...openCounterDefault})
  const dropdownConfigs: {  [key: string]: any} = {};
  const dropdownNavs = navConfigData.filter(item => item.isDropDown === true).map(item=> {
    dropdownConfigs[item.name]= item;
    return item.name;
  });

  const showSearchBar = () => setSearchBarShown(true);
  const hideSearchBar = () => setSearchBarShown(false);

  const openMobileMenu = () => setMobileMenuOpen(true);
  const closeMobileMenu = () => setMobileMenuOpen(false);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setHoveredNav(event.currentTarget.id);
    setAnchorEls({ ...anchorEls, [event.currentTarget.id]: (event.currentTarget) });
  } 
  const handlePopoverClose = (id: string) => {
    if(isOpenCounter[id] === false || hoveredNav !== id){
      setHoveredNav("");
      setAnchorEls({ ...anchorEls, [id]: null });
    }
  };

  const handleMenuClose = (e: React.MouseEvent<HTMLElement>, navLabel:string) => {

    const rect = anchorEls[navLabel];

    if(rect !== null){
      const eleBounds = rect.getBoundingClientRect();
      if(!(e.clientX >= eleBounds.left && e.clientX <= eleBounds.right)){

        setAnchorEls({ ...anchorEls, [navLabel]: null });
        setIsOpenCounter({ ...isOpenCounter, [navLabel]: false });
      }
    }
  }
  
  const isMouseInBound = (e: React.MouseEvent<HTMLElement>, navLabel: string) => {
    
    const eleBounds = e.currentTarget.getBoundingClientRect();

    if(e.clientX >= eleBounds.left && e.clientX <= eleBounds.right){
      setIsOpenCounter({ ...isOpenCounter, [navLabel]: true });
    }
    else {
      setIsOpenCounter({ ...isOpenCounter, [navLabel]: false });
    }
  };
  
  if (location.pathname === "/hcbt-elite") return null;

  return (
    <Box
      component='header'
      bgcolor={appColors.blueHeader}
      color='white'
      overflow='hidden'
      position='sticky'
      top={0}
      zIndex={1000}
      boxShadow={3}
    >
      <Container
        maxWidth='lg'
        component='nav'
        sx={{
          height: 64,
          display: "flex",
          alignItems: "center",
          position: "relative",
          gap: 4,
        }}
      >
        <Stack display={{ xs: "flex", md: "none" }}>
          <img src={logo} alt='Honda Cars Batangas' style={{ height: 24, width: "auto" }} />
        </Stack>
        <Stack
          display={{ xs: "none", md: "flex" }}  
          flex={1}
          direction='row'
          height='100%'
          alignItems='center'
          gap={3.5}
        >
          {navItems.map((nav) => (
            <Stack key={nav.label} sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              position: "relative",
              transition: "color 0.2s",
            }}>
              {
                dropdownNavs.indexOf(nav.label) <= -1?
                  <NavLinkItem 
                    to={nav.path}
                    onMouseEnter={()=>setHoveredNav(nav.label)}
                    onMouseLeave={undefined}

                  >
                    {nav.label}
                  </NavLinkItem>
                  :''
              }
              {/* for Models */}

              {
                dropdownNavs.indexOf(nav.label) > -1 ?(
                  dropdownConfigs[nav.label].dropdownType === "text-media"?(
                    <Fragment>
                      <NavLinkItem 
                        to={nav.path}
                        aria-owns={Boolean(anchorEls[nav.label]) ? 'mouse-over-popover' : undefined}
                        aria-controls={"hover-menu-"+nav.label.replaceAll(" ", "").replaceAll("&", "And")}
                        aria-haspopup="true"
                        id={nav.label}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={(e)=>{
                          const targetClientRect = e.currentTarget.getBoundingClientRect();
                          if(e.clientY <= targetClientRect.top || e.clientX <= targetClientRect.left || e.clientX >= targetClientRect.right){
                            handlePopoverClose(nav.label)
                          }
                        }}
                      >
                        {nav.label}
                      </NavLinkItem>
                      <Menu
                        id={"hover-menu-"+nav.label.replaceAll(" ", "").replaceAll("&", "And")}
                        anchorEl={anchorEls[nav.label]}
                        open={Boolean(anchorEls[nav.label]) && hoveredNav === nav.label}  
                        onMouseEnter={(e)=>isMouseInBound(e, nav.label)}
                        key={nav.label}
                        onMouseLeave={(e)=> handleMenuClose(e, nav.label)}
                        anchorOrigin={{
                          vertical: 65,
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        elevation={1}
                        sx={{ pointerEvents: 'none' }}
                        PaperProps={{
                          sx: { 
                            width: 240, 
                            bgcolor: "white", 
                            paddingX: 2, 
                            paddingY: 2,
                            pointerEvents: 'auto'
                          },
                        }}
                        disableRestoreFocus
                      >
                        {
                          dropdownConfigs[nav.label].items.length > 0 && (
                            dropdownConfigs[nav.label].items.map((nav: NavConfigItem, index: Number) => (
                              <MenuItem onClick={()=> handlePopoverClose(nav.label)} disableRipple sx={{ paddingLeft: 0, paddingRight: 0, borderRadius: '5px', pointerEvents: 'auto'}} key={index.toString()}>
                                <Link to={`${nav.linkHref}`} style={{ textDecoration: 'none' }}>
                                  <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1.5} style={{ textDecoration: 'none' }}>
                                    <Box width="35%" sx={{ borderRadius: '5px' }}>
                                      <img
                                        src={nav.mediaUrl}
                                        alt='Nav Media'
                                        style={{
                                          objectFit: "contain",
                                          backfaceVisibility: "hidden",
                                          transform: "translateZ(0)",
                                          imageRendering: "-webkit-optimize-contrast",
                                          verticalAlign: "middle",
                                          width: "100%",
                                        }}
                                      />
                                    </Box>
                                    <Typography style={{ wordBreak: 'break-word', fontSize: 13, color: appColors.black }} width="65%">
                                      { nav.label }
                                    </Typography>
                                  </Stack>
                                </Link>
                              </MenuItem>
                            ))
                          )
                        }
                    </Menu> 
                  </Fragment>
                  )
                  // for text only dropdown
                  :(
                    <Fragment>
                      <NavLinkItem 
                        to={nav.path}
                        aria-owns={ Boolean(anchorEls[nav.label]) ? 'mouse-over-popover' : undefined}
                        aria-controls={"hover-menu-"+nav.label.replaceAll(" ", "").replaceAll("&", "And")}
                        id={nav.label}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={Boolean(anchorEls[nav.label]) === false ? (()=> handlePopoverClose(nav.label)): undefined}
                      >
                        {nav.label}
                      </NavLinkItem>
                      <Menu
                        id={"hover-menu-"+nav.label.replaceAll(" ", "").replaceAll("&", "And")}
                        anchorEl={anchorEls[nav.label]}
                        open={Boolean(anchorEls[nav.label]) && hoveredNav === nav.label}
                        onMouseEnter={(e)=>isMouseInBound(e, nav.label)}
                        onMouseLeave={(e)=> handleMenuClose(e, nav.label)}
                        anchorOrigin={{
                          vertical: 65,
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        elevation={1}
                        sx={{ pointerEvents: 'none' }}
                        PaperProps={{
                          sx: { 
                            width: 240, 
                            bgcolor: "white", 
                            paddingX: 2, 
                            paddingY: 2,
                            pointerEvents: 'auto'
                          },
                        }}
                        disableRestoreFocus
                      >
                        {
                          dropdownConfigs[nav.label].items.map((item: {linkHref: string, label: string})=> (
                            <MenuItem key={item.label} onClick={()=>handlePopoverClose(nav.label)} disableRipple sx={{ paddingLeft: 0, paddingRight: 0, borderRadius: '5px', pointerEvents: 'auto', width: '100%'}}>
                              <Link to={`${item.linkHref+ '/' + uniqueId()}`} style={{ textDecoration: 'none', width:'100%', paddingLeft:'5px'}}>
                                <Typography style={{ wordBreak: 'break-word', fontSize: 13, color: appColors.black }} width="100%">
                                  { item.label  }
                                </Typography>
                                </Link>
                            </MenuItem>
                          ))
                        }
                      </Menu> 
                    </Fragment>
                  )
                ):''
              }
            </Stack>
          ))}
          <SearchButton onClick={showSearchBar} />
          <Box ml='auto'>
            <VirtualShowroomNavLink to='/virtual-showroom'>Virtual Showroom</VirtualShowroomNavLink>
          </Box>
        </Stack>
        <Stack
          ml='auto'
          direction='row'
          alignItems='center'
          gap={1}
          display={{ xs: "flex", md: "none" }}
        >
          <SearchButton onClick={showSearchBar} />
          <MenuButton onClick={openMobileMenu} />
        </Stack>
        <SearchBar show={searchBarShown} onClose={hideSearchBar} />
        <MobileNav open={mobileMenuOpen} onClose={closeMobileMenu} />
      </Container>
    </Box>
  );
};

export default NavBar;
import { IModels } from "../../../../store/models/types";
import { ChevronRight } from "@mui/icons-material";
import { Avatar, Stack } from "@mui/material";
import { CompareButton, CompareItemStyle } from "./CompareModelComponents";

interface CompareItemProps {
  model: IModels;
  onChoose: (model: IModels) => void;
}

const CompareItem = ({ model, onChoose }: CompareItemProps) => {
  return (
    <Stack component='section' onClick={() => onChoose(model)} sx={CompareItemStyle}>
      <Avatar src={model.imagePreviewUrl} variant='square' sx={{ width: 110, height: 110, 'img': { objectFit: 'contain' } }}/>
      <CompareButton 
        variant="outlined" 
        endIcon={<ChevronRight key={Math.random()} fontSize='small' />}
        sx={{ pointerEvents: 'none', '&:hover': { background: 'none' } }}
        disableRipple
        onClick={() => onChoose(model)}
      >
        Choose Model
      </CompareButton>
    </Stack>
  );
};

export default CompareItem;

import { ReactNode } from "react";
import { Container, Stack } from "@mui/material";

const VSContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Stack component='section' minHeight='100%' overflow='hidden' sx={{ px: { xs: 0, lg: 6 } }}>
      <Container maxWidth='lg' sx={{ position: "relative", px: { xs: 0, md: "24px" }}}>
        {children}
      </Container>
    </Stack>
  );
};

export default VSContainer;

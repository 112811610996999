import {
  SET_PARTS_AND_ACCESSORIES_STATUS,
  LOAD_PARTS_AND_ACCESSORIES_DATA,
  SET_PARTS_AND_ACCESSORIES_FILTER,
  PartsAndAccessoriesState,
  PartsAndAccessoriesListAction
} from './types';

const initialState: PartsAndAccessoriesState = {
  status: "idle",
  error: undefined,
  data: [],
  allData: [],
  filter: "All"
};

const partsAndAccessoriesReducer = (state = initialState, action: PartsAndAccessoriesListAction): PartsAndAccessoriesState => {
  switch (action.type) {
    case  SET_PARTS_AND_ACCESSORIES_STATUS:
      return { ...state, status: action.payload.status };
    case LOAD_PARTS_AND_ACCESSORIES_DATA:
      return { ...state, data: action.payload.data, status: "idle" };
    case SET_PARTS_AND_ACCESSORIES_FILTER:
      return { ...state, filter: action.payload.filter };
    default:
      return state;
  }
}

export default partsAndAccessoriesReducer;
import { Box, Stack, Typography } from "@mui/material";
import NewsAndEventItem from "./NewsAndEventItem";
import LoadMoreButton from "../../components/common/LoadMoreButton";
import { useState } from "react";
import { newsAndEventsFetcher } from "../../store/news-and-events/actions";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { selectNewsAndEventsState } from "../../store/news-and-events/selectors";
import { orderBy } from "lodash";
import FetchingLoadingIndicator from "../../components/common/FetchingLoadingIndicator";

interface Props {
  type: "All" | "News" | "Event";
}

const NewsAndEventsListingPage = ({ type }: Props) => {
  const [page, setPage] = useState(1);
  const { sortBy } = useSelector(selectNewsAndEventsState);
  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const param = query.get("type");

  const filter = !param ? "All" : param;

  const { isLoading, isFetching, isPreviousData, data } = useQuery(
    [type, page],
    async () => await newsAndEventsFetcher(page, type),
    { keepPreviousData: true }
  );

  if (filter !== type) return null;

  if (isLoading)
    return (
      <Stack minHeight="45vh">
        <FetchingLoadingIndicator />
      </Stack>
    );

  const [property, order] = sortBy.split(":");

  const sortedData = orderBy(data?.newsAndEvents, [property], [order as "asc" | "desc"]);
  const newsCount = data?.totalNewsAndEvents;
  const totalPages = newsCount && Math.ceil(newsCount / 9);

  if (newsCount === 0) {
    return (
      <Typography textAlign="center" mt={3} fontWeight={600}>
          No available data as of the moment.
      </Typography>
    )
  }

  return (
    <>
      <Box
        display="grid"
        gap={3}
        sx={{
          gridTemplateColumns: {
            xs: "1fr",
            sm: "repeat(2, 1fr)",
            md: "repeat(3, 1fr)",
          },
        }}
      >
        {sortedData.map((item) => (
          <Link key={item.id} to={`/news-and-events/${item.id}`}>
            <NewsAndEventItem item={item} />
          </Link>
        ))}
      </Box>
      { newsCount && 
        newsCount > 9 && totalPages !== page &&
        !isFetching && 
        <LoadMoreButton
          loading={isFetching}
          onClick={() => {
            if (!isPreviousData) {
              setPage((old) => old + 1);
            }
          }}
        />
      }
      { isFetching && <FetchingLoadingIndicator /> }
    </>
  );
};

export default NewsAndEventsListingPage;

import type { AppError, IFeatureState, Status } from "../system/types";

export interface ICareersBanner {
  id: string;
  tagline: string;
  media: string;
  dateUpdated: string;
}

export type CareersBannerState = IFeatureState<ICareersBanner>;
export const SET_CAREERS_BANNER_STATUS = "careers/banner/status/set";
export const SET_CAREERS_BANNER_ERROR = "careers/banner/error/set";
export const LOAD_CAREERS_BANNER_DATA = "careers/banner/data/load";

interface SetCareersBannerStatusAction {
  type: typeof SET_CAREERS_BANNER_STATUS;
  payload: { status: Status };
}

interface SetCareersBannerErrorAction {
  type: typeof SET_CAREERS_BANNER_ERROR;
  payload: { error: AppError };
}

interface SetCareersBannerDataAction {
  type: typeof LOAD_CAREERS_BANNER_DATA;
  payload: { data: CareersBannerState["data"] };
}

export type CareersBannerAction = 
  | SetCareersBannerStatusAction
  | SetCareersBannerErrorAction
  | SetCareersBannerDataAction

import { IPartsAndAccessories } from "../../store/parts-and-accessories/types";

import { appColors } from "../../theme";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {
  itemContainerStyle,
  imageContainerStyle,
  textContainerStyle,
  nameStyle,
  textStyle
} from "./PartsAndAccessoriesStyle";

interface PartsAndAccessoriesProps {
  partsAndAccessories: IPartsAndAccessories;
}

const PartsAndAccessoriesItem = ({ partsAndAccessories }: PartsAndAccessoriesProps) => {
  return (
    <Stack sx={itemContainerStyle}>
      <Box sx={imageContainerStyle} >
        <img 
          src={partsAndAccessories.mediaUrl} 
          alt={partsAndAccessories.name} 
          style={{ width: "100%" }}
        />
      </Box>
      <Stack sx={textContainerStyle}>
        <Typography sx={nameStyle}>
          {partsAndAccessories.name}
        </Typography>
        <Stack direction="row" gap={1}>
          <Typography sx={{ ...textStyle, color: partsAndAccessories.type === "Part" ? appColors.yellowGreen : appColors.primary }}>
            {partsAndAccessories.type}
          </Typography>
          <Typography sx={textStyle}>
            |
          </Typography>
          <Typography sx={textStyle}>
           {partsAndAccessories.chassisNumber}
          </Typography>
        </Stack>
        <Typography mt={1} sx={{ fontSize: 15 }}>
          {partsAndAccessories.content}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default PartsAndAccessoriesItem;
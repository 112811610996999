import { CircularProgress, Stack } from "@mui/material";

function FetchingLoadingIndicator() {
  return (
    <Stack
      width="100%"
      height="300px"
      flex={1}
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress sx={{ color: "primary.main" }} />
    </Stack>
  );
}

export default FetchingLoadingIndicator;

import { useDispatch, useSelector } from "react-redux";
import { setPromoSortBy } from "../../../store/promos/actions";
import { selectPromoData, selectPromoState } from "../../../store/promos/selectors";

export default function usePromoState() {
  const dispatch = useDispatch();
  const { status, error, sortBy } = useSelector(selectPromoState);

  const data = useSelector(selectPromoData);

  const onSortByChange = (sort: typeof sortBy) => dispatch(setPromoSortBy(sort));

  return { data, status, error, sortBy, onSortByChange };
}
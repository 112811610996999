import type { AppError, IFeatureState, Status } from "../system/types";

export interface INewsAndEventsBanner {
  id: string;
  tagline: string;
  media: string;
  dateUpdated: string;
}

export type NewsAndEventsBannerState = IFeatureState<INewsAndEventsBanner>;
export const SET_NEWS_AND_EVENTS_BANNER_STATUS = "newsAndEvents/banner/status/set";
export const SET_NEWS_AND_EVENTS_BANNER_ERROR = "newsAndEvents/banner/error/set";
export const LOAD_NEWS_AND_EVENTS_BANNER_DATA = "newsAndEvents/banner/data/load";

interface SetNewsAndEventsBannerStatusAction {
  type: typeof SET_NEWS_AND_EVENTS_BANNER_STATUS;
  payload: { status: Status };
}

interface SetNewsAndEventsBannerErrorAction {
  type: typeof SET_NEWS_AND_EVENTS_BANNER_ERROR;
  payload: { error: AppError };
}

interface SetNewsAndEventsBannerDataAction {
  type: typeof LOAD_NEWS_AND_EVENTS_BANNER_DATA;
  payload: { data: NewsAndEventsBannerState["data"] };
}

export type NewsAndEventsBannerAction = 
  | SetNewsAndEventsBannerStatusAction
  | SetNewsAndEventsBannerErrorAction
  | SetNewsAndEventsBannerDataAction

import {
    LOAD_CAREERS_DATA,
    SET_CAREERS_STATUS,
    CareerListAction,
    CareerListState
} from './types';

const initialState: CareerListState = {
    status: "idle",
    error: undefined,
    data: [],
  };

const careersReducer = (state = initialState, action: CareerListAction): CareerListState => {
    switch (action.type) {
        case  SET_CAREERS_STATUS:
            return { ...state, status: action.payload.status };
          case LOAD_CAREERS_DATA:
            return { ...state, data: action.payload.data, status: "idle" };
        default:
            return state;
    }
};

export default careersReducer;

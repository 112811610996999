import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectPartsAndAccessoriesListState } from "../../store/parts-and-accessories/selectors";
import { fetchPartsAndAccessoriesList } from "../../store/parts-and-accessories/actions";

import Meta from '../../components/common/Meta';
import Stack from "@mui/material/Stack";
import SectionContainer from "../../components/layout/SectionContainer";
import PartsAndAccessories from "./PartsAndAccessories";
import PartsAndAccessoriesList from "./PartsAndAccessoriesList";
import LoadingIndicator from "../../components/common/LoadingIndicator";
import SectionTitle from "../../components/common/SectionTitle";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Switch, Route } from "react-router-dom";
import PartsAndAccessoriesFilter from "./PartsAndAccessoriesFilter";
import PartsAndAccessoriesBanner from "./PartsAndAccessoriesBanner";

const queryClient = new QueryClient();

const PartsAndAccessoriesPage = () => {
  const dispatch = useDispatch();
  const { status } = useSelector(selectPartsAndAccessoriesListState);

  useEffect(() => {
    dispatch(fetchPartsAndAccessoriesList());
  }, [dispatch]);

  if (status === "loading")
    return (
      <Stack minHeight='85vh'>
        <LoadingIndicator show />
      </Stack>
    );

  return (
    <>
    <PartsAndAccessoriesBanner />
    <Switch>
      <Route path='/parts-and-accessories/:category?/:reloadToken?'>
        <QueryClientProvider client={queryClient}>
          <SectionContainer>
            <Meta title='Parts and Accessories' />
            <SectionTitle title='Parts & Accessories' />
            <Stack minHeight='85vh'>
              <Stack direction='row' mt={5} mb={8}>
                <PartsAndAccessoriesFilter />
              </Stack>
              <PartsAndAccessoriesList />
              <PartsAndAccessories />
            </Stack>
          </SectionContainer>
        </QueryClientProvider>
      </Route>
    </Switch>
    </>
  );
};

export default PartsAndAccessoriesPage;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stack, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import CareerItem from "./CareerItem";
import {
  selectCareerData,
  selectCareerListState,
} from "../../store/careers/selectors";
import { fetchCareerList } from "../../store/careers/actions";
import { ICareers } from "../../store/careers/types";
import SectionTitle from "../../components/common/SectionTitle";
import { appColors } from "../../theme";
import FetchingLoadingIndicator from "../../components/common/FetchingLoadingIndicator";
import RedLink from "../../components/lib/RedLink";

const CareerList = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectCareerData);
  const { status } = useSelector(selectCareerListState);

  useEffect(() => {
    dispatch(fetchCareerList());
  }, [dispatch]);

  return (
    <>
      <SectionTitle title="Careers" />
      <Typography fontSize={14} ml={0.2} mb={5} color={appColors.grey}>
        See something that interests you? Apply here!
      </Typography>
      <Stack direction="row" justifyContent="right">
        <RedLink to={`/careers/general-job`} style={{ fontWeight: 700 }}>
          Go to General Job Application
          <ChevronRight />
        </RedLink>
      </Stack>
      {status === "loading" ? (
        <FetchingLoadingIndicator />
      ) : (
        <Stack gap={1.7} mt={2}>
          {data.map((career: ICareers) => (
            <CareerItem key={career.id} career={career} />
          ))}
        </Stack>
      )}
    </>
  );
};

export default CareerList;

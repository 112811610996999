import { useState, useEffect } from "react";

import { Stack, Button } from "@mui/material";
import { PageNumber } from "./SearchStyle";
import useIsTabletOrMobile from "../../hooks/useIsTabletOrMobile";

interface PaginationProps {
  currentPage: number,
  dataPerPage: number,
  totalData: number
  paginate: (page: number) => void
}

const Pagination = ({ currentPage, dataPerPage, totalData, paginate }: PaginationProps) => {
  const [pageNumberLimit, setPageNumberLimit] = useState(10);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(10);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const isTabletOrMobile = useIsTabletOrMobile();

  useEffect(() => {
    if (isTabletOrMobile) {
      setPageNumberLimit(5);
      setMaxPageNumberLimit(5)
    } else {
      setPageNumberLimit(10);
      setMaxPageNumberLimit(10)
    }
  }, [isTabletOrMobile])
  
  const pages = [];

  for (let i=1; i<= Math.ceil(totalData / dataPerPage); i++) pages.push(i);

  const renderPageNumbers = pages.map(number => {
    return(
      <Stack key={number}>
        {
          (number < maxPageNumberLimit+1 && number > minPageNumberLimit) 
          ? <PageNumber 
              key={number} 
              number={number}
              current={currentPage}
              onClick={() => paginate(number)}
              istabletormobile={isTabletOrMobile.toString()}
            >
              {number}
            </PageNumber>
          : ""
        }
      </Stack>
    )
  })
  
  const handleNextButton = () => {
    paginate(currentPage + 1);

    if (currentPage+1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const handlePreviousButton = () => {
    paginate(currentPage - 1);

    if ((currentPage-1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }

  let pageIncrementBtn = null;

  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <Stack onClick={handleNextButton}> &hellip; </Stack>
  }

  let pageDecrementBtn = null;

  if (pages.length >= maxPageNumberLimit && currentPage > pageNumberLimit) {
    pageDecrementBtn = <Stack onClick={handlePreviousButton}> &hellip; </Stack>
  }

  if (pages.length <= 1) return null;
  
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" marginY={3}>
      <Button onClick={handlePreviousButton} disabled={currentPage === 1}>
        Previous
      </Button>
      {pageDecrementBtn}
      {renderPageNumbers}
      {pageIncrementBtn}
      <Button onClick={handleNextButton} disabled={currentPage === pages.length}>
        Next
      </Button>
    </Stack>
  )
}

export default Pagination;
import React, { forwardRef } from 'react';
import { styled } from '@mui/system';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import UploadIcon from '@mui/icons-material/UploadFileSharp';

const FileLabel = styled('span')({
  fontSize: '13px',
});

interface AppUploadButtonProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  fileName?: string;
  error?: boolean;
  errorMessage?: string;
  hideLabel?: boolean;
  stacking?: 'row' | 'column';
  buttonLabel?: string;
}

const UploadButton = styled("span")(({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.palette.text.primary,
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  padding: '6px 12px',
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
  fontSize: '13px',
  fontWeight: 500,
  gap: 8,
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },

  '& > svg': {
    height: 18,
    width: 18,
  },
}));

const WebUploadButton = forwardRef<HTMLInputElement, AppUploadButtonProps>(
  (
    {
      name,
      fileName,
      error,
      errorMessage,
      hideLabel = false,
      stacking = 'row',
      buttonLabel = 'Media',
      ...rest
    },
    ref
  ) => {
    return (
      <Stack>
        <Stack
          direction={stacking}
          alignItems='center'
          flexWrap='wrap'
          gap={stacking === 'row' ? 2 : 1}
        >
          <label htmlFor={name} style={{ width: 'max-content' }}>
            <input ref={ref} accept='image/*' type='file' hidden name={name} id={name} {...rest} />
            <UploadButton>
              <UploadIcon style={{ fontSize: 14 }} />
              Upload {buttonLabel}
            </UploadButton>
          </label>
          {/* {!hideLabel && <FileLabel>{fileName ? fileName : 'Upload file'}</FileLabel>} */}
        </Stack>
        {error && errorMessage && (
          <FormHelperText sx={{ marginX: '14px', mt: '4px', color: 'error.main' }}>
            {errorMessage}
          </FormHelperText>
        )}
      </Stack>
    );
  }
);

export default WebUploadButton;

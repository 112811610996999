import {
  LOAD_ELITE_DATA,
  SET_ELITE_STATUS,
  EliteListAction,
  EliteState
} from './types';

const initialState: EliteState = {
  status: "idle",
  error: undefined,
  data: [],
};

const eliteReducer = (state = initialState, action: EliteListAction): EliteState => {
  switch (action.type) {
    case  SET_ELITE_STATUS:
        return { ...state, status: action.payload.status };
      case LOAD_ELITE_DATA:
        return { ...state, data: action.payload.data, status: "idle" };
    default:
        return state;
  }
}

export default eliteReducer;
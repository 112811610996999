import type { ModelFilterType } from "./app-types";

export const ModelFilters: ModelFilterType[] = [
  {
    id: "1",
    type: "All"
  },
  {
    id: "2",
    type: "Sedan/Hatchback"
  },
  {
    id: "3",
    type: "SUV"
  }
];
import SectionContainer from "../../../../components/layout/SectionContainer";
import SectionTitle from "../../../../components/common/SectionTitle";
import useCurrentModel from "../../utils/useCurrentModel";
import { appColors } from "../../../../theme";
import Variants from "./Variants";

const ModelVariantSection = () => {
  const model = useCurrentModel();
  if (!model) return null;
  const { variant } = model;

  return (
    <SectionContainer className='model-section' id='model-variants' bg={appColors.lightestGray}>
      <SectionTitle title='Variants' />
      <Variants variantData={variant} />
    </SectionContainer>
  );
};

export default ModelVariantSection;

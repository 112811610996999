import { InputBase, Stack, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchRounded";
import CloseIcon from "@mui/icons-material/Close";

import { appColors } from "../../theme";
import { forwardRef, useRef } from "react";
import { useHistory } from "react-router-dom";
import useSearchKeyword from "../../modules/search/utils/useSearchKeyword";

interface SearchBarProps {
  show: boolean;
  onClose: () => void;
}

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>((props, ref) => {
  const { show, onClose } = props;

  // For Search
  const history = useHistory();
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const searchKeyword = useSearchKeyword();

  const redirectToSearch = () => {
    if (!searchInputRef.current?.value) return;

    history.push(`/search?query=${searchInputRef.current.value}`);
    searchInputRef.current.value = "";

    onClose();
  };

  const clearTextField = () => {
    if (searchInputRef.current) searchInputRef.current.value = "";
    onClose();
  };

  if (!show) return null;

  return (
    <Stack
      position='absolute'
      top={0}
      left={0}
      px={3}
      width='100%'
      height='100%'
      bgcolor={appColors.blueHeader}
    >
      <InputBase
        defaultValue={searchKeyword}
        autoFocus
        sx={{ color: appColors.lightGray, height: "100%" }}
        placeholder='Search...'
        inputRef={searchInputRef}
      />
      <IconButton
        onClick={clearTextField}
        aria-label='close'
        sx={{
          position: "absolute",
          top: "50%",
          right: { xs: 12, md: 0 },
          transform: "translateY(-50%)",
          color: "white",
          width: 24,
          height: 24,
          "&:hover": {
            bgcolor: "primary.main",
          },
        }}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
      <IconButton
        onClick={redirectToSearch}
        aria-label='search'
        sx={{
          position: "absolute",
          top: "50%",
          right: 40,
          transform: "translateY(-50%)",
          color: "white",
          bgcolor: "primary.main",

          "&:hover": {
            bgcolor: "primary.main",
          },
        }}
      >
        <SearchIcon fontSize='small' />
      </IconButton>
    </Stack>
  );
});

export default SearchBar;

import { ArrowForward } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { QuickLink } from "./utils";

const Container = styled("div")({
  minHeight: 0,
  position: "relative",
  overflow: "hidden",
  cursor: "pointer",
  "&:hover img": { transform: "scale(1.1)" },
  "&:hover .cover-bg": { opacity: 0.5 },
  "&:hover .cover": { transform: "scaleX(1)" },
  "&:hover .cover-text": { opacity: 1 },
});

const QuickLinkImage = styled("img")(({ theme }) => ({
  objectFit: "fill",
  aspectRatio: "1 / .85",
  backfaceVisibility: "hidden",
  transform: "translateZ(0)",
  imageRendering: "-webkit-optimize-contrast",
  verticalAlign: "middle",
  transition: "transform 0.3s ease-out",
}));

interface QuickLinkItemProps {
  quickLink: QuickLink;
  onClick: (name: string | null) => void;
}

const NewQuickLinkItem = ({ quickLink, onClick }: QuickLinkItemProps) => {
  return (
    <Container role='button' onClick={() => onClick(quickLink.title)}>
      <QuickLinkImage src={quickLink.image} alt={quickLink.title} loading='lazy' width='100%' />
      <Stack
        className='cover-bg'
        position='absolute'
        top={0}
        bottom={0}
        left={0}
        width='100%'
        bgcolor='primary.main'
        sx={{ opacity: 0, transition: "opacity 0.2s" }}
      />
      <Stack position='absolute' bottom={0} left={0} width='100%'>
        <Stack
          className='cover'
          justifyContent='center'
          height={{ xs: 60, md: 48 }}
          width='100%'
          bgcolor='primary.main'
          position='absolute'
          bottom={0}
          sx={{
            transform: "scaleX(0)",
            transformOrigin: "left",
            transition: "transform 0.3s",
          }}
        />
        <Typography
          className='cover-text'
          color='white'
          fontWeight='bold'
          fontSize={14}
          sx={{
            zIndex: 2,
            ml: 2,
            mb: 1.7,
            pb: { xs: 0.4, md: 0 },
            opacity: 0,
            transition: "opacity 0.2s",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {quickLink.title}
          <ArrowForward sx={{ mr: 2 }} />
        </Typography>
      </Stack>
    </Container>
  );
};

export default NewQuickLinkItem;

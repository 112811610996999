import type { AppError, IFeatureState, Status } from "../system/types";

export interface VSMedia {
  filename: string;
  image: string;
}

export interface VirtualShowroom {
  id: string;
  dateCreated: Date;
  name: string;
  model: string; // ID of the model
  color: string;
  exterior: VSMedia[];
  // for interior
  interior: string; // URL
  infospots: Infospot[];
}

export interface Infospot {
  position: { x: number; y: number; z: number };
  title: string;
  content: string;
  banner: string; // url
}

export interface VirtualShowroomFields {
  name: string;
  model: string; // ID of the model
  color: string;
  exterior: VSMedia[];
  interior: File | string | null;
  infospots: Infospot[];
}

export type VirtualShowroomState = IFeatureState<VirtualShowroom>;

export const SET_VIRTUAL_SHOWROOM_STATUS = "virtual-showroom/status/set";
export const SET_VIRTUAL_SHOWROOM_ERROR = "virtual-showroom/error/set";
export const LOAD_VIRTUAL_SHOWROOM_DATA = "virtual-showroom/data/load";

interface SetVirtualShowroomStatusAction {
  type: typeof SET_VIRTUAL_SHOWROOM_STATUS;
  payload: { status: Status };
}

interface SetVirtualShowroomErrorAction {
  type: typeof SET_VIRTUAL_SHOWROOM_ERROR;
  payload: { error: AppError };
}

interface SetVirtualShowroomDataAction {
  type: typeof LOAD_VIRTUAL_SHOWROOM_DATA;
  payload: { data: VirtualShowroomState["data"] };
}

export type VirtualShowroomAction =
  | SetVirtualShowroomStatusAction
  | SetVirtualShowroomErrorAction
  | SetVirtualShowroomDataAction;

import { useEffect, useRef } from 'react';
import { ModalNavItemStyled } from './ModelNavStyle';

interface ModelNavItemProps {
  sectionId: string;
  label: string;
  onClick: (nav: string) => void;
  isActive: boolean;
}

const ModelNavItem = ({ sectionId, label, onClick, isActive }: ModelNavItemProps) => {
  const sectionRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (sectionRef.current) return;

    sectionRef.current = document.getElementById(sectionId);
  }, [sectionId]);

  const handleClick = () => {
    if (!sectionRef.current) return;
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    onClick(sectionId);
  };

  return (
    <ModalNavItemStyled
      id={`nav-${sectionId}`}
      aria-roledescription='button nav for model sections'
      role='button'
      onClick={handleClick}
      className={`model-nav ${isActive ? 'active' : ''}`}
    >
      {label}
    </ModalNavItemStyled>
  );
};

export default ModelNavItem;

import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { AppError, Status } from "../system/types";
import {
    LOAD_ALL_PROMO_DATA,
    LOAD_PROMO_DATA,
    SET_PROMO_STATUS,
    IPromo,
    PromoAction,
    PromoState,
    SET_PROMO_ERROR,
    LOAD_MORE_PROMO_DATA,
    SET_PROMO_SORTBY,
} from "./types";

const setPromoStatus = (status: Status): PromoAction => ({
    type: SET_PROMO_STATUS,
    payload: { status }
});

const setPromoError = (error: AppError): PromoAction => ({
  type: SET_PROMO_ERROR,
  payload: { error },
});

const loadAllPromoData = (allData: IPromo[]): PromoAction => ({
  type: LOAD_ALL_PROMO_DATA,
  payload: { allData },
});

const loadPromoData = (data: IPromo[]): PromoAction => ({
  type: LOAD_PROMO_DATA,
  payload: { data },
});

const loadMorePromoData = (data: IPromo[]): PromoAction => ({
    type: LOAD_MORE_PROMO_DATA,
    payload: { data },
  });

  export const setPromoSortBy = (
    sortBy: PromoState["sortBy"]
  ): PromoAction => ({
    type: SET_PROMO_SORTBY,
    payload: { sortBy },
  });

  export interface IFetchOptions {
    page: number;
    loadingState: "fetching" | "loading";
  }

export const fetchAllPromo = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setPromoStatus("fetching"));

    const response = await apiClient.request<{ promos: IPromo[] }>({
      url: `${apiEndpoints.promos}?enabled=true&active=true`,
    });

    dispatch(loadAllPromoData(response.data.promos)); 
  } catch (err: any) {
    dispatch(setPromoStatus("error"));
    dispatch(setPromoError(err));
    errorHandler(err, dispatch);
  }
};

export const fetchPromo = (): AppThunk => async (dispatch, getState) => {
    try {
      dispatch(setPromoStatus("fetching"));

      const limit = 6;
      const offset = 0;

      const response = await apiClient.request<{ promos: IPromo[] }>({
        url: `${apiEndpoints.promos}?offset=${offset}&limit=${limit}&enabled=true&active=true`,
      });

      const validPromos = response.data.promos;

      dispatch(loadPromoData(validPromos)); 
    } catch (err: any) {
      dispatch(setPromoStatus("error"));
      dispatch(setPromoError(err));
      errorHandler(err, dispatch);
    }
};

export const loadMorePromo =
  (page: number): AppThunk =>
  async (dispatch) => {
    try {
      if (page === 1) return;

      dispatch(setPromoStatus("loading"));

      const limit = 6;
      const offset = limit * (page - 1);

      const response = await apiClient.request<{ promos: IPromo[] }>({
        url: `${apiEndpoints.promos}?offset=${offset}&limit=${limit}&enabled=true&active=true`,
      });

      const validPromos = response.data.promos;

      dispatch(loadMorePromoData(validPromos));
    } catch (err: any) {
      dispatch(setPromoStatus("error"));
      dispatch(setPromoError(err));
      errorHandler(err, dispatch);
    }
  };

export const promoFetcher = async (page: number) => {
  const limit = 6 * page;
  const offset = 0;

  const query = `${apiEndpoints.promos}?offset=${offset}&limit=${limit}&enabled=true&active=true`;

  const response = await apiClient.request<{ promos: IPromo[], count: number }>({
    url: query,
  });

  const validPromos = response.data.promos;
  const totalPromos = response.data.count;

  return { validPromos, totalPromos };
};

export const promosFetchById = async (id: string) => {
  const response = await apiClient.request<IPromo>({
    url: `${apiEndpoints.promos}/${id}`,
  });

  return response.data;
};
import { useLocation, useHistory } from "react-router-dom";
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import useCurrentModel from "../utils/useCurrentModel";
import ModelDesignBareDialog from "./ModelDesignBareDialog";

const ModelDesignImg = styled("img")(({ theme }) => ({
  objectFit: "cover",
  backfaceVisibility: "hidden",
  transform: "translateZ(0)",
  imageRendering: "-webkit-optimize-contrast",
  verticalAlign: "middle",

  [theme.breakpoints.between("xs", "md")]: {
    aspectRatio: "16 / 9",
    height: "100%",
  },
}));

const ModelDesignModal = () => {
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const model = useCurrentModel();

  const paramValue = query.get("design");

  if (!model || !paramValue || !["interior", "exterior"].includes(paramValue)) return null;

  const dataToDisplay = paramValue === "interior" ? model.interior : model.exterior;

  const handleClose = () => history.replace(`/models/${model.id}`);

  return (
    <ModelDesignBareDialog title={paramValue} onClose={handleClose}>
      <Box
        maxWidth='100%'
        display='grid'
        gap={3}
        sx={{ gridTemplateColumns: { xs: "1fr", sm: "repeat(2, 1fr)", md: "repeat(3, 1fr)" } }}
      >
        {dataToDisplay.map((item, idx) => (
          <Stack key={item.header + idx} sx={{ mb: 1 }}>
            <ModelDesignImg
              src={item.imagePreviewUrl}
              alt={item.header}
              loading='lazy'
              width='100%'
              height={200}
            />
            <Stack textAlign='center' mt={1}>
              <Typography
                sx={{
                  color: "#222",
                  fontWeight: 600,
                  fontSize: { xs: 16, md: 20 },
                  mt: 1,
                }}
              >
                {item.header}
              </Typography>
              <Typography
                sx={{
                  color: "#aaa",
                  fontSize: { xs: 14, md: 16 },
                }}
              >
                {item.content}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Box>
    </ModelDesignBareDialog>
  );
};

export default ModelDesignModal;

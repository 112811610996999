import { AlertColor } from '@mui/material/Alert'
import { AnyAction } from 'redux'

export type SystemInputType = string | number | boolean
export type Status = "idle" | "loading" | "submitting" | "fetching" | "success" | "error";
export type AppError = Error | string | undefined;

export interface IFeatureState<TData> {
  status: Status;
  error: AppError;
  data: TData[];
};

export interface IFeatureSingleDataState<TData> {
  status: Status;
  error: AppError;
  data: TData;
};

export interface SystemInputInterface<T> {
  [key: string]: T    
}

export interface Snackbar {
  open: boolean;
  type: "success" | "error" | "warning";
  message?: string | null;
}

export interface SystemState {
  snackbar: Snackbar | null | undefined;
  snackbarOpen: boolean;
  snackbarMessage: string;
  snackbarType: AlertColor;
  redirectTo: string;
  interceptors: {
      requestId: number;
      responseId: number;
  }
  openDrawer: boolean;
  activeRoute: string;
}

export const SET_SNACKBAR = "set_snackbar";
export const SET_SYSTEM_STATE = 'set_system_state';
export const SET_SYSTEM_LOADING = "set_system_loading";


export interface SetSnackbarAction {
  type: typeof SET_SNACKBAR;
  payload: Snackbar | null;
}

export interface SetSystemStateAction {
  type: typeof SET_SYSTEM_STATE
  payload: SystemInputInterface<SystemInputType>
}

export interface SetSystemLoadingAction {
  type: typeof SET_SYSTEM_LOADING;
  payload: boolean;
}

export type SystemAction = SetSnackbarAction |SetSystemStateAction | SetSystemLoadingAction | AnyAction;

import { AppError, IFeatureState, Status } from "../system/types";

export type IElite = {
  id: string;
  email: string;
  redeemablePoints: string;
  cardNumber: number;
}

export type EliteState = IFeatureState<IElite>;

export const SET_ELITE_STATUS = "elite/status/set";
export const SET_ELITE_ERROR = "elite/error/set";
export const LOAD_ELITE_DATA = "elite/data/load";

interface SetEliteListStatusAction {
  type: typeof SET_ELITE_STATUS;
  payload: { status: Status };
}

interface SetEliteListErrorAction {
  type: typeof SET_ELITE_ERROR;
  payload: { error: AppError };
}

interface SetEliteDataAction {
  type: typeof LOAD_ELITE_DATA;
  payload: { data: EliteState["data"] }
}

export type EliteListAction = 
  | SetEliteListStatusAction
  | SetEliteListErrorAction
  | SetEliteDataAction
import { styled } from "@mui/system";

const NewsAndEventsImage = styled("img")(({ theme }) => ({
  objectFit: "cover",
  aspectRatio: "16 / 9",
  backfaceVisibility: "hidden",
  transform: "translateZ(0)",
  imageRendering: "-webkit-optimize-contrast",
  verticalAlign: "middle",
  transition: "transform 0.3s ease-out",

  [theme.breakpoints.between("xs", "md")]: {
    aspectRation: "16 / 9",
    height: "100%",
  },
}));

export default NewsAndEventsImage;

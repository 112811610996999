import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const RedLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  gap: 4,
  fontSize: 14,
  width: "max-content",

  "& > svg": {
    marginTop: "2px",
  },
}));

export default RedLink;

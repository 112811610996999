import type { Status } from "../system/types";

export interface TestimonialState<TData> {
  status: Status;
  error: Error | undefined;
  data?: TData[];
}

export interface TestimonialFields {
  id?:string;
  fName: string;
  lName: string;
  address: string;
  content: string;
  mediaFile?: File | null;
  rating: number;
  anonymous: boolean;
}


export interface ITestimonialData extends TestimonialFields{
  id?:string;
  fName: string;
  lName: string;
  address: string;
  content: string;
  media: string;
  rating: number;
  anonymous: boolean;
}

export type TestimonialListState = TestimonialState<ITestimonialData>;

export const SET_TESTIMONIAL_STATUS = "testimonial/status/set";
export const SET_TESTIMONIAL_ERROR = "testimonial/error/set";
export const LOAD_TESTIMONIAL_DATA = "testimonials/data/load";
export const FETCH_TESTIMONIAL_DATA = "testimonials/data/fetch";

interface SetTestimonialStatusAction {
  type: typeof SET_TESTIMONIAL_STATUS;
  payload: { status: Status };
}

interface SetTestimonialErrorAction {
  type: typeof SET_TESTIMONIAL_ERROR;
  payload: { error: Error | undefined };
}

  interface SetTestimonialListDataAction {
    type: typeof LOAD_TESTIMONIAL_DATA;
    payload: { data: TestimonialListState["data"] };
  };

  interface FetchTestimonialAction {
    type: typeof FETCH_TESTIMONIAL_DATA;
    payload: { id: string; data: ITestimonialData };
  }

export type TestimonialAction =
  | SetTestimonialStatusAction
  | SetTestimonialErrorAction
  | SetTestimonialListDataAction
  | FetchTestimonialAction;
import {
  SET_ABOUT_US_STATUS,
  LOAD_ABOUT_US_DATA,
  AboutUsAction,
  AboutUsState
} from "./types";

const initialState: AboutUsState = {
  status: "idle",
  error: undefined,
  data: [],
};

const aboutUsReducer = (state = initialState, action: AboutUsAction): AboutUsState => {
  switch (action.type) {
    case SET_ABOUT_US_STATUS:
      return { ...state, status: action.payload.status };
    case LOAD_ABOUT_US_DATA:
      return { ...state, data: action.payload.data, status: "idle" };
    default:
      return state;
  }
};

export default aboutUsReducer;
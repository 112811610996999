import { Box } from "@mui/material";
import Container, { ContainerProps } from "@mui/material/Container";


const SectionContainer = ({ bg, id, className, ...props }: ContainerProps & { bg?: string }) => {
  return (
    <Box component='section' className={className} bgcolor={bg} id={id}>
      <Container maxWidth='lg' sx={{ py: { xs: 4, lg: 8 }, ...props.sx }} {...props} />
    </Box>
  );
};

export default SectionContainer;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAboutUsData, selectAboutUsState } from "../../store/about-us/selectors";
import { fetchAboutUs } from "../../store/about-us/actions";

import CoreValues from './CoreValues';
import HCBT from './HCBT';
import OurMission from './OurMission';
import OurVision from './OurVision';
import { TopImage } from './ui';
import OfficeWorkImage from '../../assets/images/about-us/office-work.png';
import Meta from '../../components/common/Meta';
import useIsMobile from "../../hooks/useIsMobile";

import LoadingIndicator from "../../components/common/LoadingIndicator";

function AboutUsPage() {
  const dispatch = useDispatch();

  const aboutUs = useSelector(selectAboutUsData);
  const { status } = useSelector(selectAboutUsState);
  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch(fetchAboutUs());
  }, [dispatch]);

  if (status === "fetching") return <LoadingIndicator show />;

  return (
    <>
      <Meta title='About Us' />
      <TopImage isMobile={isMobile} src={aboutUs[0]?.media} alt='Honda Cars Batangas' />
      <HCBT header={aboutUs[0]?.header} content={aboutUs[0]?.content}  />
      <OurMission media={aboutUs[0]?.missionMedia} title={aboutUs[0]?.mission} content={aboutUs[0]?.missionContent} />
      <OurVision media={aboutUs[0]?.visionMedia} title={aboutUs[0]?.vision} content={aboutUs[0]?.visionContent} />
      <CoreValues title={aboutUs[0]?.coreValueTitle} values={aboutUs[0]?.coreValue} />
    </>
  );
}

export default AboutUsPage;

import { AppError, IFeatureState, Status } from '../system/types';

export type INavConfig = {
    id: string;
    name: string;
    docType: string;
    dropdownType: string;
    isDropDown: boolean;
    isFilter: boolean;
    items : NavConfigItem[];
}

export interface NavConfigItem {
    label: string;
    linkHref: string;
    mediaUrl?: string
}

export type NavConfigListState = IFeatureState<INavConfig>;

export const SET_NAV_CONFIG_STATUS = 'nav-config/status/set'
export const SET_NAV_CONFIG_ERROR = 'nav-config/error/set'
export const LOAD_NAV_CONFIG_DATA = 'nav-config/data/load';

export interface setNavConfigStatusAction {
    type: typeof SET_NAV_CONFIG_STATUS;
    payload: { status: Status }
}

export interface setNavConfigErrorAction {
    type: typeof SET_NAV_CONFIG_ERROR;
    payload: { error: AppError }
}

export interface setNavConfigDataAction {
    type: typeof LOAD_NAV_CONFIG_DATA;
    payload: { data: NavConfigListState['data'] }
}

export type NavConfigListAction =  
  | setNavConfigStatusAction
  | setNavConfigErrorAction
  | setNavConfigDataAction;
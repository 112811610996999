import React from "react";

import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import { forwardRef } from "react";
import { appColors } from "../../theme";
import AddIcon from "@mui/icons-material/Add";

const UploadButton = styled("span")(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: "6px",
  borderRadius: 5,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  transition: "transform 0.3s ease-in"
}));

interface AppUploadButtonProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  hideLabel?: boolean;
  stacking?: "row" | "column";
}

const AppUploadButton = forwardRef<HTMLInputElement, AppUploadButtonProps>(
  ({ name, hideLabel = false, stacking = "row", ...rest }, ref) => {
    return (
      <Stack>
        <Stack
          direction={stacking}
          alignItems='center'
          flexWrap='wrap'
          gap={stacking === "row" ? 2 : 1}
        >
          <label htmlFor={name} style={{ width: "max-content" }}>
            <input
              ref={ref}
              accept='image/*'
              type='file'
              hidden
              name={name}
              id={name}
              {...rest}
            />
            <UploadButton>
              <AddIcon
                sx={{
                  border: "1px solid white",
                  borderRadius: "2px",
                  color: appColors.white,
                  fontSize: 13,
                }}
              />
            </UploadButton>
          </label>
        </Stack>
      </Stack>
    );
  }
);

export default AppUploadButton;

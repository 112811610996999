import React from "react";
import { DialogButtons, DialogContentStyle, LinkColor, DialogActionsStyle, HighlightedText } from "./DataPrivacyStyle";
import { Link } from "react-router-dom";
import {
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";

const DataPrivacy = () => {
  const [open, setOpen] = React.useState(true);
  const [visited, setVisited] = React.useState<string | null>('')

  React.useEffect(()=>{
    const user = localStorage.getItem("visited");
    setVisited(user)
    if(visited) setOpen(false)
  },[visited])

  const save = () => {
    localStorage.setItem("visited", "true");
    setOpen(false);
  };

  return (
    <Stack component="div">
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx:{position:"fixed",bottom:0}
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogContent sx={DialogContentStyle}>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1">
            The website you just visited provides you with access to our
            products, offers and services. We collect and use this data to serve
            you with interest-based advertising and for analytics purposes. In
            order to comply with data protection laws, we ask you to review the
            key points of our Privacy Policy. You can read more about our
            privacy policy by clicking the <Typography to="/data-privacy" sx={LinkColor} component={Link}>link.</Typography> <Typography component="span" sx={HighlightedText}> By clicking "Accept", you are
            providing consent to the use of advertising and analytics
            technologies(including cookies) on this site that work with us. </Typography>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={DialogActionsStyle}  >
          <DialogButtons variant="contained" onClick={save} >
            Accept
          </DialogButtons>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default DataPrivacy;
import type { AppError, IFeatureState, Status } from "../system/types";

export interface INewsAndEvent {
  id: string;
  name: string;
  mediaUrl: string;
  content: string;
  isEnabled: boolean;
  mediaName: string;
  type: "News" | "Event";
  dateCreated: Date;
  dateUpdated: Date;
}

export interface NewsAndEventsState extends IFeatureState<INewsAndEvent> {
  filter: INewsAndEvent["type"] | null;
  sortBy: "dateCreated:desc" | "dateCreated:asc";
  allData: INewsAndEvent[];
}

export const SET_NEWS_AND_EVENTS_STATUS = "news_and_events/status/set";
export const SET_NEWS_AND_EVENTS_ERROR = "news_and_events/error/set";
export const SET_NEWS_AND_EVENTS_FILTER = "news_and_events/filter/set";
export const SET_NEWS_AND_EVENTS_SORTBY = "news_and_events/sortby/set";

export const LOAD_ALL_NEWS_AND_EVENTS_DATA = "news_and_events/data/load_all";
export const LOAD_NEWS_AND_EVENTS_DATA = "news_and_events/data/load";
export const LOAD_MORE_NEWS_AND_EVENTS_DATA = "news_and_events/data/load_more";

interface SetNewsAndEventsStatusAction {
  type: typeof SET_NEWS_AND_EVENTS_STATUS;
  payload: { status: Status };
}

interface SetNewsAndEventsErrorAction {
  type: typeof SET_NEWS_AND_EVENTS_ERROR;
  payload: { error: AppError };
}

interface SetAllNewsAndEventsDataAction {
  type: typeof LOAD_ALL_NEWS_AND_EVENTS_DATA;
  payload: { allData: NewsAndEventsState["allData"] };
}

interface SetNewsAndEventsDataAction {
  type: typeof LOAD_NEWS_AND_EVENTS_DATA;
  payload: { data: NewsAndEventsState["data"] };
}

interface SetNewsAndEventsFilterAction {
  type: typeof SET_NEWS_AND_EVENTS_FILTER;
  payload: { filter: NewsAndEventsState["filter"] };
}

interface SetNewsAndEventsSortByAction {
  type: typeof SET_NEWS_AND_EVENTS_SORTBY;
  payload: { sortBy: NewsAndEventsState["sortBy"] };
}

interface LoadMoreNewsAndEventsDataAction {
  type: typeof LOAD_MORE_NEWS_AND_EVENTS_DATA;
  payload: { data: NewsAndEventsState["data"] };
}

export type NewsAndEventsAction =
  | SetNewsAndEventsStatusAction
  | SetNewsAndEventsErrorAction
  | SetAllNewsAndEventsDataAction
  | SetNewsAndEventsDataAction
  | SetNewsAndEventsFilterAction
  | LoadMoreNewsAndEventsDataAction
  | SetNewsAndEventsSortByAction;

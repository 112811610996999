import { forwardRef } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectProps } from "@mui/material/Select";

import ArrowDownIcon from "@mui/icons-material/ExpandMoreOutlined";

import { appColors } from "../../theme";
import { SxProps } from "@mui/system";

interface SelectOptionType {
  id: string;
  label: string;
  value: string;
}

interface OwnSelectProps {
  label?: string;
  options?: SelectOptionType[];
  placeholderColor?: string;
  helperText?: string;
  containerStyles?: SxProps;
}

type WebSelectProps = OwnSelectProps & Omit<SelectProps, "label | containerStyles">;

const WebSelect = forwardRef<HTMLInputElement, WebSelectProps>(
  ({ label, options, placeholderColor, helperText, containerStyles, ...rest }, ref) => {
    return (
      <FormControl 
        sx={{ 
          "& .MuiOutlinedInput-root": {
            borderRadius: 0,
            "& > .MuiSelect-outlined ": {
              padding: "12px",
            },
          }, 
          ...containerStyles 
        }}
      >
        <Select
          ref={ref}
          {...rest}
          size='small'
          displayEmpty
          IconComponent={ArrowDownIcon}
          sx={{
            fontSize: 14,
            "& .MuiSvgIcon-root": { color: appColors.primary },
            ...rest.sx,
          }}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <Typography
                  component='small'
                  fontSize={14}
                  fontWeight={400}
                  color={appColors.lightGray}
                >
                  {rest.placeholder}
                </Typography>
              );
            }
            return options?.find((i) => i.value === (selected as string))?.label;
          }}
        >
          <MenuItem disabled value=''>
            <Typography
              component='small'
              fontSize={14}
              fontWeight={400}
              color={placeholderColor ? placeholderColor : appColors.mediumGray}
            >
              {rest.placeholder}
            </Typography>
          </MenuItem>
          {options &&
            options.map((item) => (
              <MenuItem key={item.id} value={item.value} sx={{ width: "100%", fontSize: 14 }}>
                {item.label}
              </MenuItem>
            ))}
        </Select>
        {rest.error && (
          <FormHelperText sx={{ color: (theme) => theme.palette.error.main }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
);

export default WebSelect;
import React from "react";
import Typography from "@mui/material/Typography";
import { Add, Remove } from "@mui/icons-material";
import {
  SpecificationAccordion,
  SpecificationAccordionDetails,
  SpecificationAccordionSummary,
} from "./SpecificationAccordionComponents";
import { appColors } from "../../../../theme";
import { Button, Grid, Stack } from "@mui/material";

const iconStyle = {
  color: appColors.primary,
  mx: { xs: 0, md: 3 },
  marginTop: "2px",
};

export const SpecificationsDetails = ({ specs }: { specs: any }) => {
  const [open, setOpen] = React.useState(true);
  const [categories, setExpandedCategories] = React.useState<Array<string>>([]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (categories.includes(panel))
      setExpandedCategories(categories.filter((category) => category !== panel));
    else setExpandedCategories((categories) => [...categories, panel]);
  };

  const isExpandedIcon = (category: string) => {
    if (categories.includes(category)) return <Remove sx={iconStyle} />;
    return <Add sx={iconStyle} />;
  };

  const handleExpandAll = () => {
    setExpandedCategories(specs.map((c: any) => c.category) as Array<string>);
    setOpen(false);
  };

  const handleCollapseAll = () => {
    setExpandedCategories([]);
    setOpen(true);
  };

  return (
    <>
      <Stack alignItems='end'>
        <Button
          onClick={open ? handleExpandAll : handleCollapseAll}
          endIcon={open ? <Add /> : <Remove />}
          sx={{ fontWeight: "bold", my: 3 }}
        >
          {open ? "EXPAND ALL" : "COLLAPSE ALL"}
        </Button>
      </Stack>
      {specs.map((spec: any) => (
        <SpecificationAccordion
          key={spec.category}
          expanded={categories.includes(spec.category)}
          onChange={handleChange(spec.category)}
          TransitionProps={{
            unmountOnExit: true,
          }}
          disableGutters
        >
          <SpecificationAccordionSummary expandIcon={isExpandedIcon(spec.category)}>
            <Stack direction='row' flex={1} alignItems='center' pl={{ xs: 0, md: 3 }}>
              <Typography fontSize={14} fontWeight={600}>
                {spec.category}
              </Typography>
              <Typography
                fontSize={14}
                lineHeight={0.9}
                sx={{
                  display: "inline-block",
                  ml: "auto",
                  mr: { md: -2 },
                  color: "primary.main",
                }}
              >
                {categories.includes(spec.category) ? "Hide Details" : "Show Details"}
              </Typography>
            </Stack>
          </SpecificationAccordionSummary>
          <SpecificationAccordionDetails>
            <Grid container gap={2} flexDirection='column'>
              {spec.items.map((item: any, index: number) => (
                <Grid
                  key={index}
                  container
                  padding={0}
                  borderBottom={`1px solid ${appColors.lightestGray}`}
                >
                  <Grid item xs={3} paddingBottom={3}>
                    <Typography
                      mx={{ xs: 0, md: 3 }}
                      fontWeight={500}
                      sx={{
                        fontSize: { xs: "14px", sm: "17px" },
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} paddingBottom={3} textAlign='center'>
                    <Typography
                      mx={{ xs: 0, md: 3 }}
                      fontWeight={500}
                      sx={{
                        fontSize: { xs: "14px", sm: "17px" },
                      }}
                    >
                      {item.value === "" ? "-" : item.value}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </SpecificationAccordionDetails>
        </SpecificationAccordion>
      ))}
    </>
  );
};

export default SpecificationsDetails;

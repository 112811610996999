import { AxiosResponse } from "axios";
import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type {AppThunk} from "../index";
import type { Status } from "../system/types"; 
import {LOAD_PAS_DATA,
    SET_PAS_STATUS,
    IPAS,
    PASListAction} 
from "./types";


const setPASStatus = (status: Status): PASListAction => ({
    type: SET_PAS_STATUS,
    payload: {status}
});

const loadPASData = (data: IPAS[]): PASListAction => ({
    type: LOAD_PAS_DATA,
    payload: { data },
});

export const fetchPASList = (): AppThunk => async (dispatch) => {
    try {
        
        dispatch(setPASStatus("loading"));
        const response:AxiosResponse<any> = await apiClient.request({
            url: `${apiEndpoints.partsServices}?active=true`,
        });
        
        dispatch(loadPASData(response.data.partsServices)); 
    } catch (err: any) {
        dispatch(setPASStatus("error"));
        errorHandler(err, dispatch);
    }
}

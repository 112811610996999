import { styled } from "@mui/system";
import { Link } from "react-router-dom";

const WebFilterLink = styled(Link)(({ theme }) => ({
  fontSize: 15,
  position: "relative",
  cursor: "pointer",
  border: "none",
  backgroundColor: "white",
  color: "#aaa",
  textDecoration: "none",

  fontFamily: "inherit",

  "&::after": {
    position: "absolute",
    bottom: -5,
    left: "50%",
    content: '""',
    width: 30,
    borderRadius: 2,
    height: 3,
    transform: "translateX(-50%)",
  },

  "&.active": {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },

  "&.active::after": {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default WebFilterLink;

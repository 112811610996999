import { AxiosResponse } from "axios";
import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type {AppThunk} from "../index";
import type { Status } from "../system/types";
import {
    LOAD_CAREERS_DATA,
    SET_CAREERS_STATUS,
    ICareers,
    CareerListAction
} from "./types";

const setCareerStatus = (status: Status): CareerListAction => ({
    type: SET_CAREERS_STATUS,
    payload: { status }
});

const loadCareerData = (data: ICareers[]): CareerListAction => ({
    type: LOAD_CAREERS_DATA,
    payload: { data },
});

export const fetchCareerList = (): AppThunk => async (dispatch) => {
    try {
        
        dispatch(setCareerStatus("loading"));
        const response:AxiosResponse<any> = await apiClient.request({
            url: `${apiEndpoints.careers}?active=true`,
        });
        dispatch(loadCareerData(response.data.careers)); 
    } catch (err: any) {
        dispatch(setCareerStatus("error"));
        errorHandler(err, dispatch);
    }
};
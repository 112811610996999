import { useSelector } from "react-redux";
import { selectCareersForSearch, selectCareerListState } from "../../../store/careers/selectors";
import {
  selectNewsAndEventsForSearch,
  selectNewsAndEventsState,
} from "../../../store/news-and-events/selectors";
import { selectPromosForSearch, selectPromoState } from "../../../store/promos/selectors";
import {
  selectServicesForSearch,
  selectPartsAndServicesListState,
} from "../../../store/parts-and-service/selectors";
import {
  selectModelsForSearch,
  selectVariantsForSearch,
  selectModelListState,
} from "../../../store/models/selectors";
import {
  selectTestimoniesForSearch,
  selectTestimonialState,
} from "../../../store/testimonial/selectors";
import { 
  selectPartsAndAccessoriesForSearch, 
  selectPartsAndAccessoriesListState 
} from "../../../store/parts-and-accessories/selectors";

export default function useAllModule() {
  const careers = useSelector(selectCareersForSearch);
  const news = useSelector(selectNewsAndEventsForSearch);
  const promo = useSelector(selectPromosForSearch);
  const services = useSelector(selectServicesForSearch);
  const models = useSelector(selectModelsForSearch);
  const variants = useSelector(selectVariantsForSearch);
  const testimonies = useSelector(selectTestimoniesForSearch);
  const partsAndAccessories = useSelector(selectPartsAndAccessoriesForSearch);

  const { status: careerStatus } = useSelector(selectCareerListState);
  const { status: newAndEventStatus } = useSelector(selectNewsAndEventsState);
  const { status: promoStatus } = useSelector(selectPromoState);
  const { status: servicesStatus } = useSelector(selectPartsAndServicesListState);
  const { status: modelStatus } = useSelector(selectModelListState);
  const { status: testimonyStatus } = useSelector(selectTestimonialState);
  const { status: partsAndAccessoriesStatus } = useSelector(selectPartsAndAccessoriesListState);

  const isLoading =
    careerStatus === "loading" ||
    newAndEventStatus === "loading" ||
    promoStatus === "loading" ||
    servicesStatus === "loading" ||
    modelStatus === "loading" ||
    testimonyStatus === "loading" ||
    partsAndAccessoriesStatus === "loading"

  return {
    isLoading,
    careers,
    news,
    promo,
    services,
    models,
    variants,
    testimonies,
    partsAndAccessories
  }
}

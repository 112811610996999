import {
  LOAD_MODELS_DATA,
  SET_MODEL_STATUS,
  SET_SELECTED_MODEL,
  ModelListAction,
  ModelListState,
  SelectedModelListState
} from './types';
import modelSchema from '../../modules/model/compare-models/fragments/modelSchema.json'

const initialState: ModelListState = {
  status: "idle",
  error: undefined,
  data: [],
};

const initalVariantState: SelectedModelListState = {
  selectedVariants: [{
    name: '',
    performance: [],
    summary: { content: '', price: '' },
    specs: [{ category: '', items: [] }]
  }],
  selectedModels: [modelSchema, modelSchema, modelSchema],
  selectedModelIndex: -1
};

export const modelReducer = (state = initialState, action: ModelListAction): ModelListState => {
  switch (action.type) {
    case SET_MODEL_STATUS:
      return { ...state, status: action.payload.status };
    case LOAD_MODELS_DATA:
      return { ...state, data: action.payload.data, status: "idle" };
    default:
      return state;
  }
}

export const variantReducer = (state = initalVariantState, action: ModelListAction): SelectedModelListState => {
  switch (action.type) {
    case SET_SELECTED_MODEL:
      return { ...state, selectedVariants: action.payload.selectedVariants };
    default:
      return state;
  }
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { selectModelById } from "../../../../store/models/selectors";

import { selectVirtualShowroomByModelId } from "../../../../store/virtual-showroom/selectors";
import { VirtualShowroom } from "../../../../store/virtual-showroom/types";

export default function useShowroomModelColor() {
  const { id: modelId } = useParams<{ id: string }>();

  const { search } = useLocation();
  const history = useHistory();

  const vsQuery = useMemo(() => new URLSearchParams(search), [search]);

  const currentModel = useSelector(selectModelById(modelId));

  const showroomsByModel = useSelector(selectVirtualShowroomByModelId(modelId));
  const [currentColorIndex, setCurrentColorIndex] = useState(0);

  const showroomColors = showroomsByModel.map((vs) => vs.color);

  const modelColors = useMemo(
    () =>
      currentModel
        ? currentModel.colorSelection.filter((color) => showroomColors.includes(color.colorName))
        : [],
    [currentModel, showroomColors]
  );

  useEffect(() => {
    const colorQuery = vsQuery.get("color");
    if (colorQuery) {
      const colorIndex = modelColors.findIndex((c) => c.colorName === colorQuery);
      setCurrentColorIndex(colorIndex);
    }
  }, [modelColors, vsQuery]);

  const onColorIndexChange = useCallback(
    (colorIndex: number) => {
      if (vsQuery.get("color")) {
        history.push(`/virtual-showroom/car/${modelId}`);
      }

      setCurrentColorIndex(colorIndex);
    },
    [history, modelId, vsQuery]
  );

  const showroomLookupByColor = showroomsByModel.reduce<Record<string, VirtualShowroom>>(
    (acc, curr) => ({
      ...acc,
      [curr.color]: curr,
    }),
    {}
  );

  const currentShowroom = currentModel
    ? showroomLookupByColor[modelColors[currentColorIndex]?.colorName]
    : null;

  return {
    modelColors,
    currentShowroom,
    currentColorIndex,
    onColorIndexChange,
  };
}

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectPromoBannerData } from "../../store/promos-banner/selectors";
import { fetchPromoBanner } from "../../store/promos-banner/actions";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import {
  BannerContainer,
  taglineContainerStyle,
  taglineStyle
} from "./PromoBannerStyle";

const PromoBanner = () => {
  const dispatch = useDispatch();

  const banner = useSelector(selectPromoBannerData);

  useEffect(() => {
    dispatch(fetchPromoBanner());
  }, [dispatch]);

  if (banner.length === 0) return null;

  return (
    <Stack component='section'>
      <BannerContainer 
        height="50vh" 
        image={banner[0]?.media}
      >
        <Box sx={taglineContainerStyle}>
          <Typography sx={taglineStyle}>
            {banner[0]?.tagline}
          </Typography>
        </Box>
      </BannerContainer>
    </Stack>
  )
}

export default PromoBanner;
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

const MenuButton = (props: IconButtonProps) => {
  return (
    <IconButton
      aria-label='search'
      size='small'
      sx={{
        display: { xs: "flex", md: "none" },
        ml: "auto",
        color: "white",
        width: 36,
        height: 36,
        "&:hover": { bgcolor: "primary.main" },
      }}
      {...props}
    >
      <MenuIcon />
    </IconButton>
  );
};

export default MenuButton;

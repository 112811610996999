import type { AppError, IFeatureState, Status } from "../system/types";

export interface IPartsAndAccessoryBanner {
  id: string;
  tagline: string;
  media: string;
  dateUpdated: string;
}

export type PartsAndAccessoryBannerState = IFeatureState<IPartsAndAccessoryBanner>;
export const SET_PARTS_AND_ACCESSORY_BANNER_STATUS = "partsandaccessory/banner/status/set";
export const SET_PARTS_AND_ACCESSORY_BANNER_ERROR = "partsandaccessory/banner/error/set";
export const LOAD_PARTS_AND_ACCESSORY_BANNER_DATA = "partsandaccessory/banner/data/load";

interface SetPartsAndAccessoryBannerStatusAction {
  type: typeof SET_PARTS_AND_ACCESSORY_BANNER_STATUS;
  payload: { status: Status };
}

interface SetPartsAndAccessoryBannerErrorAction {
  type: typeof SET_PARTS_AND_ACCESSORY_BANNER_ERROR;
  payload: { error: AppError };
}

interface SetPartsAndAccessoryBannerDataAction {
  type: typeof LOAD_PARTS_AND_ACCESSORY_BANNER_DATA;
  payload: { data: PartsAndAccessoryBannerState["data"] };
}

export type PartsAndAccessoryBannerAction = 
  | SetPartsAndAccessoryBannerStatusAction
  | SetPartsAndAccessoryBannerErrorAction
  | SetPartsAndAccessoryBannerDataAction

import { AppThunk } from "..";
import { apiClient, apiEndpoints } from "../utils/api";
import { uploadMedia } from "../../utils/upload";
import {
  InquiryAction,
  InquiryFields,
  ICareerInquiry,
  IPartsAndAccessoryInquiry,
  InquiryState,
  SET_INQUIRY_ERROR,
  SET_INQUIRY_STATUS,
} from "./types";

// Sync actions
export const setInquiryStatus = (status: InquiryState["status"]): InquiryAction => ({
  type: SET_INQUIRY_STATUS,
  payload: { status },
});

const setInquiryError = (error: InquiryState["error"]): InquiryAction => ({
  type: SET_INQUIRY_ERROR,
  payload: { error },
});

// Async actions
export const submitInquiry =
  (formData: InquiryFields, moduleName: string, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setInquiryStatus("submitting"));

      // call api here
      await apiClient.post(apiEndpoints.sendInquiry, {
        ...formData,
        module: moduleName,
      });

      dispatch(setInquiryStatus("success"));

      if (callback) callback();
    } catch (err: any) {
      let error = new Error("Server Error");
      if (err?.response?.data) {
        if (Array.isArray(err.response.data)) {
          error.message = err.response.data[0].message;
        } else {
          error.message = err.response.data.message;
        }
      }

      dispatch(setInquiryError(error));
    }
  };

export const submitCareerInquiry =
  (formData: InquiryFields, moduleName: string, careerName: string, callback?: () => void): AppThunk => 
  async (dispatch) => {
    try {
      dispatch(setInquiryStatus("submitting"));

      const { mediaFile, firstName, lastName, email, phoneNumber, comment } = formData;

      let attachmentURL = "", attachmentName = "";

      if (mediaFile) {
        const uploadRes = await uploadMedia(mediaFile, "careers");
        attachmentURL = uploadRes[0].path;
        attachmentName = mediaFile.name;
      }

      const newFormData: Partial<ICareerInquiry> = {
        firstName,
        lastName,
        email,
        phoneNumber,
        comment,
        attachmentURL,
        attachmentName,
        careerName
      };

      // call api here
      await apiClient.post(`${apiEndpoints.sendInquiry}/careers`, {
        ...newFormData,
        module: moduleName,
      });

      dispatch(setInquiryStatus("success"));

      if (callback) callback();
    } catch (err: any) {
      let error = new Error("Server Error");
      if (err?.response?.data) {
        if (Array.isArray(err.response.data)) {
          error.message = err.response.data[0].message;
        } else {
          error.message = err.response.data.message;
        }
      }

      dispatch(setInquiryError(error));
    }
  };

export const submitPartsAndAccessoryInquiry =
  (formData: IPartsAndAccessoryInquiry, moduleName: string, callback?: () => void): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setInquiryStatus("submitting"));

      const { 
        mediaFile, 
        firstName, 
        lastName, 
        email, 
        phoneNumber, 
        model, 
        chassisNumber, 
        comment 
      } = formData;

      let attachmentURL = "", attachmentName = "";

      if (mediaFile) {
        const uploadRes = await uploadMedia(mediaFile, "parts-and-accessories");
        attachmentURL = uploadRes[0].path;
        attachmentName = mediaFile.name;
      }

      const newFormData: Partial<IPartsAndAccessoryInquiry> = {
        firstName,
        lastName,
        email,
        phoneNumber,
        model, 
        chassisNumber, 
        comment,
        attachmentURL,
        attachmentName
      };

      // call api here
      await apiClient.post(apiEndpoints.sendInquiry, {
        ...newFormData,
        module: moduleName,
      });

      dispatch(setInquiryStatus("success"));

      if (callback) callback();
    } catch (err: any) {
      let error = new Error("Server Error");
      if (err?.response?.data) {
        if (Array.isArray(err.response.data)) {
          error.message = err.response.data[0].message;
        } else {
          error.message = err.response.data.message;
        }
      }

      dispatch(setInquiryError(error));
    }
  };
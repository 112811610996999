import {
  SET_PARTS_AND_ACCESSORY_BANNER_STATUS,
  LOAD_PARTS_AND_ACCESSORY_BANNER_DATA,
  PartsAndAccessoryBannerAction,
  PartsAndAccessoryBannerState
} from "./types";

const initialState: PartsAndAccessoryBannerState = {
  status: "idle",
  error: undefined,
  data: [],
};

const partsandaccessoryBannerReducer = (state = initialState, action: PartsAndAccessoryBannerAction): PartsAndAccessoryBannerState => {
  switch (action.type) {
    case SET_PARTS_AND_ACCESSORY_BANNER_STATUS:
      return { ...state, status: action.payload.status };
    case LOAD_PARTS_AND_ACCESSORY_BANNER_DATA:
      return { ...state, data: action.payload.data, status: "idle" };
    default:
      return state;
  }
};

export default partsandaccessoryBannerReducer;
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectModelById } from "../../../store/models/selectors";

export default function useCurrentModel() {
  const { id } = useParams<{ id: string }>();
  const model = useSelector(selectModelById(id));

  return model;
}

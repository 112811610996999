import { apiClient, apiEndpoints } from "../utils/api";
import errorHandler from "../utils/errorHandler";
import type { AppThunk } from "../index";
import type { Status } from "../system/types";
import {
  IPartsAndAccessoryBanner,
  LOAD_PARTS_AND_ACCESSORY_BANNER_DATA,
  SET_PARTS_AND_ACCESSORY_BANNER_STATUS,
  PartsAndAccessoryBannerAction
} from "./types";

// Sync Actions
const setPartsAndAccessoryBannerStatus = (status: Status): PartsAndAccessoryBannerAction => ({
  type: SET_PARTS_AND_ACCESSORY_BANNER_STATUS,
  payload: { status }
});

const loadPartsAndAccessoryBannerData = (data: IPartsAndAccessoryBanner[]): PartsAndAccessoryBannerAction => ({
  type: LOAD_PARTS_AND_ACCESSORY_BANNER_DATA,
  payload: { data }
});

export const fetchPartsAndAccessoryBanner = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setPartsAndAccessoryBannerStatus("fetching"));

    const response = await apiClient.request<{ banner: IPartsAndAccessoryBanner }>({
      url: `${apiEndpoints.careers}/banner/PARTS::AND::ACCESORIES::BANNER::1`,
    });

    dispatch(loadPartsAndAccessoryBannerData([response.data.banner]));
  } catch (err: any) {
    dispatch(setPartsAndAccessoryBannerStatus("error"));
    errorHandler(err, dispatch);
  }
};
import type { AppError, IFeatureState, Status } from "../system/types";

export interface IPromoBanner {
  id: string;
  tagline: string;
  media: string;
  dateUpdated: string;
}

export type PromoBannerState = IFeatureState<IPromoBanner>;
export const SET_PROMO_BANNER_STATUS = "promo/banner/status/set";
export const SET_PROMO_BANNER_ERROR = "promo/banner/error/set";
export const LOAD_PROMO_BANNER_DATA = "promo/banner/data/load";

interface SetPromoBannerStatusAction {
  type: typeof SET_PROMO_BANNER_STATUS;
  payload: { status: Status };
}

interface SetPromoBannerErrorAction {
  type: typeof SET_PROMO_BANNER_ERROR;
  payload: { error: AppError };
}

interface SetPromoBannerDataAction {
  type: typeof LOAD_PROMO_BANNER_DATA;
  payload: { data: PromoBannerState["data"] };
}

export type PromoBannerAction = 
  | SetPromoBannerStatusAction
  | SetPromoBannerErrorAction
  | SetPromoBannerDataAction

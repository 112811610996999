import Alert from "@mui/material/Alert";
import Snackbar, { SnackbarProps } from "@mui/material/Snackbar";
import { appColors } from "../../theme";

type WebAlertProps = {
  message?: string;
  type?: "success" | "error" | "warning";
};

const colorMap = {
  success: appColors.success,
  error: appColors.error,
  warning: appColors.warning,
};

const WebAlert = ({
  message,
  type = "success",
  ...snackProps
}: WebAlertProps & SnackbarProps): JSX.Element => {
  const bgcolor = colorMap[type];
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      key='topright'
      {...snackProps}
    >
      <Alert variant='filled' sx={{ bgcolor, paddingY: "10px" }} severity={type} elevation={6}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default WebAlert;